<template>
  <div class="box-up">
    <div class="bk-jb"></div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <h1>My Niftyz Badge</h1>
        </div>
        <div class="col-12 col-md-6">
          <p>
            Niftyz badges provide users with a personalised QR code. Each badge is colour-coded to easily identify your line of business, company name, industry and location.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
