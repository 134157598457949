<template>
  <div>
    <Verify v-if="!this.userStore.isConnected" />
    <div v-if="this.userStore.isConnected" class="my-niftyz mt-mob-10">
      <Header />
      <div
        :class="isMobile ? 'pt-5' : null"
        class="container-fluid top-singleNft"
      >
        <div v-if="this.userStore.isMember" class="row">
          <div class="col-12">
            <a href="/#/gallery"
              ><i class="fa-solid fa-arrow-left-long"></i> Back to gallery
            </a>
          </div>
        </div>

        <div v-if="singleNft.metadata" class="row">
          <div class="col-12 col-md-12 box-single-nft">
            <div class="text-center mb-4">
              <img
                class="img-singleNft"
                :src="
                  'https://ipfs.niftyz.io/ipfs/' +
                  singleNft.metadata.image.substring(7)
                "
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div
        :class="isMobile ? 'mt-0' : 'mt-5'"
        class="container bottom-singleNft"
      >
        <div class="row">
          <div class="col-12 col-md-6 border-grey">
            <div v-if="singleNft.metadata" class="mb-5">
              <h5 class="mb-1">Description:</h5>
              <p class="mt-0 ms-2 description-nft">
                {{ singleNft.metadata.description }}
              </p>
            </div>
            <div class="" v-if="singleNft.owners">
              <div v-if="singleNft.owners.length > 0">
                <div class="d-flex justify-content-between align-items-center">
                  <h5>Pass Token stats</h5>
                  <h6>Sales</h6>
                </div>
                <ul class="list-account">
                  <li v-for="(owner, index) in owners" :key="index">
                    {{ owner }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="box-collection" style="background: transparent">
              <a
                v-if="this.creator"
                class="text-start"
                :href="'#/author/' + this.creator._id"
              >
                <h3 class="mb-4 pb-2" v-if="this.creator.twitter">
                  {{ this.creator?.twitter }}
                </h3>
                <h3
                  class="mb-4 pb-2"
                  v-if="this.creator.linkedin && !this.creator.twitter"
                >
                  {{ this.creator?.linkedin }}
                </h3>
              </a>
              <div v-if="duration && duration != ''">
                <h6 v-if="duration != 'infinite'" class="mb-4 pb-2 deadline">
                  <i class="fa-regular fa-clock me-2 text-white"></i>
                  Sale ends
                  {{ duration }}
                </h6>
                <h6 v-if="duration == 'infinite'" class="mb-4 pb-2">
                  <i class="fa-regular fa-clock me-1"></i>
                  Infinite sale
                </h6>
              </div>
              <div
                v-if="dur * 1000 > now"
                class="d-flex justify-content-start mb-3"
              >
                <div class="me-5">
                  <h4 class="mb-0">{{ leftDays }}</h4>
                  <p class="mt-0">Days</p>
                </div>
                <div class="me-5">
                  <h4 class="mb-0">{{ leftHours }}</h4>
                  <p class="mt-0">Hours</p>
                </div>
                <div class="me-5">
                  <h4 class="mb-0">{{ leftMinutes }}</h4>
                  <p class="mt-0">Minutes</p>
                </div>
                <div class="me-5">
                  <h4 class="mb-0">{{ leftSeconds }}</h4>
                  <p class="mt-0">Seconds</p>
                </div>
              </div>
              <h4 v-if="singleNft.metadata" class="mb-4 pb-2">
                {{ singleNft.metadata.name }}
              </h4>
              <div
                class="mb-4 pb-2"
                v-if="
                  singleNft.creator == userStore.address ||
                  (singleNft.owners &&
                    singleNft.owners.length > 0 &&
                    singleNft.owners.findIndex(
                      (el) => el.address === userStore.address
                    ) != -1)
                "
              >
                <h6 class="mb-3">Files:</h6>
                <a
                  v-for="(file, index) in media"
                  :key="index"
                  :href="
                    file.ipfs
                      ? 'https://ipfs.niftyz.io/ipfs/' + file.ipfs.substring(7)
                      : file.fileUrl
                  "
                  target="_blank"
                  class="link-preview"
                >
                  {{ file.name }}
                </a>
              </div>
              <div class="box-more border-grey">
                <h5 class="mb-3">ETH {{ priceNft }}</h5>
                <div class="d-flex">
                  <div
                    v-if="
                      !singleNft.minted &&
                      singleNft.creator == userStore.address &&
                      mintStatus == 'not_sent'
                    "
                    class="d-flex align-items-center"
                  >
                    <button @click="mintNFT" class="btn-connect">
                      Mint Now
                    </button>
                  </div>
                  <div class="mt-0 mb-2" v-if="mintStatus == 'pending'">
                    <div class="lds-default">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <button
                    id="delete"
                    @click="showModalDelete"
                    v-if="
                      !singleNft.minted &&
                      mintStatus != 'pending' &&
                      fetchStatus == 'success'
                    "
                    class="btn-delete"
                  >
                    Delete
                  </button>
                  <!-- MODAL DELETE -->
                  <modal
                    name="confirm-delete"
                    class="description-nft text-black"
                  >
                    <div class="d-flex justify-content-end">
                      <i
                        @click="closeModalDelete()"
                        class="fa-solid fa-circle-xmark p-3"
                      ></i>
                    </div>

                    <div
                      class="
                        d-flex
                        flex-column
                        align-items-center
                        justify-content-center
                        mt-1
                      "
                    >
                      <h3 class="text-buy">
                        Are you sure you want to delete this Nft?
                      </h3>
                      <div class="d-flex mt-2 box-confirmDelete">
                        <button @click="closeModalDelete" class="btn-exit">
                          Close
                        </button>
                        <button @click="deleteNFT" class="btn-delete">
                          Delete
                        </button>
                      </div>
                    </div>
                  </modal>
                </div>
              </div>
              <div class="mt-4">
                <div class="mb-4">
                  <h6 class="mb-1">Personal Tags:</h6>
                  <p
                    v-for="(tag, index) in personalTags"
                    :key="index"
                    class="me-2 ms-2 multi-tag"
                  >
                    #{{ tag }}
                  </p>
                </div>
                <div class="mb-4">
                  <h6 class="mb-1">Target Audience:</h6>
                  <p
                    v-for="(target, index) in targets"
                    :key="index"
                    class="me-2 ms-2 d-inline"
                  >
                    {{ target }}
                  </p>
                </div>
                <!-- <div class="mb-4">
                  <h6 class="mb-0">Category Tag:</h6>
                  <p class="ms-2">
                    {{ tagCategory }}
                  </p>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapStores } from "pinia";
import Web3 from "web3";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import Verify from "../components/Verify.vue";
import mintingAbi from "../libs/minting_abi.json";
import tokenAbi from "../libs/token_abi.json";
import { useUserStore } from "../stores/userStore";
import { useWeb3Store } from "../stores/web3Store";

export default {
  name: "draftNft",
  data() {
    return {
      mintingABI: mintingAbi,
      tokenABI: tokenAbi,
      isMobile: false,
      api: process.env.VUE_APP_API_ENDPOINT,
      idNft: this.$route.params.id,
      mintingContract: process.env.VUE_APP_CONTRACT_ADDRESS_MINTER,
      tokenContract: process.env.VUE_APP_CONTRACT_ADDRESS_PREMIUM_PT,
      tokenContractInstance: null,
      network: process.env.VUE_APP_NETWORK,
      infuraId: process.env.VUE_APP_INFURA_ID,
      currentDomain: process.env.VUE_APP_PLATFORM_DOMAIN,
      // web3: null,
      nftSynced: false,
      singleNft: [],
      creator: [],
      media: [],
      personalTags: "",
      targets: "",
      supply: null,
      duration: "",
      dur: "",
      tagCategory: "",
      priceNft: "",
      owners: [],
      buyStatus: "not_sent",
      mintStatus: "not_sent",
      fetchStatus: "not_sent",
      leftDays: "",
      leftHours: "",
      leftMinutes: "",
      leftSeconds: "",
      now: Date.now(),
      bugConsole: true,
      frameOpen: false,
      iframeLoadingStatus: "not_sent",
    };
  },
  components: {
    Header,
    Verify,
    Footer,
  },
  async mounted() {
    const app = this;
    if (this.bugConsole) {
      console.log("orario", this.now);
    }

    await app.getSingleNft();

    if (app.userStore.address != null) {
      if (app.singleNft && !app.singleNft?.minted) {
        if (!app.nftSynced) {
          app.nftSynced = true;
          console.log("calling sync on mounted");
          await app.checkSyncNFT();
        }
      }
    } else if (!app.nftSynced) {
      const unsubscribe = app.userStore.$onAction(({ name, after }) => {
        after(async () => {
          if (name === "connect") {
            unsubscribe();

            if (app.singleNft && !app.singleNft?.minted) {
              if (!app.nftSynced) {
                app.nftSynced = true;
                console.log("calling sync after mounted");
                await app.checkSyncNFT();
              }
            }
          }
        });
      });
    }

    // await app.connect();
    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
  watch: {
    // whenever question changes, this function will run
    // async userStore(newState) {
    //   if (
    //     newState?.address &&
    //     this.singleNft &&
    //     this.singleNft?.tokenId !== null &&
    //     this.singleNft?.tokenId !== undefined &&
    //     !this.nftSynced
    //   ) {
    //     this.nftSynced = true;
    //     await this.checkSyncNFT();
    //   }
    // },
    async singleNft(newValue) {
      if (this.userStore?.address && !newValue?.minted && !this.nftSynced) {
        this.nftSynced = true;
        console.log("calling sync on watch nft");
        await this.checkSyncNFT();
      }
    },
  },
  methods: {
    // async connect() {
    //   const app = this;
    //   let providerOptions = {};
    //   if (app.infuraId !== undefined) {
    //     if (this.bugConsole) {
    //       console.log("Using infuraid:", app.infuraId);
    //     }
    //     providerOptions = {
    //       walletconnect: {
    //         package: WalletConnectProvider,
    //         options: {
    //           infuraId: app.infuraId,
    //         },
    //       },
    //     };
    //   } else if (!window.ethereum) {
    //     alert("Please install MetaMask to proceed");
    //     return;
    //   }
    //   // Instantiating Web3Modal
    //   const web3Modal = new Web3Modal({
    //     cacheProvider: true,
    //     providerOptions: providerOptions,
    //   });
    //   const provider = await web3Modal.connect();
    //   app.web3 = await new Web3(provider);
    // },

    async openAttachment(id, renderMethod) {
      /* address utente, ipfs.hash, numero attachements, signature */
      const app = this;
      const tokenContract = new app.web3Store.web3.eth.Contract(
        app.tokenABI,
        app.tokenContract
      );

      const hash = app.singleNft.ipfs.substring(7);
      const tokenId = app.singleNft.tokenId;
      const prefix = await tokenContract.methods.getPrefix(hash).call();
      console.log("hash", hash);
      console.log("prefix", prefix);
      const signature = await app.web3Store.web3.eth.personal.sign(
        prefix,
        this.userStore.address
      );

      if (renderMethod == "framed") {
        this.iframeLoadingStatus = "pending";
        this.frameOpen = true;
        const frame = document.getElementById("attachment-frame");
        // console.log("ciao", res.data);
        frame.src =
          app.api +
          "/nfts/content/" +
          app.userStore.address +
          "/" +
          tokenId +
          "/" +
          id +
          "/" +
          signature;
      } else {
        window.open(
          app.api +
            "/nfts/content/" +
            app.userStore.address +
            "/" +
            tokenId +
            "/" +
            id +
            "/" +
            signature,
          "_blank"
        );
      }
    },
    async mintNFT() {
      const app = this;
      app.mintStatus = "pending";
      try {
        const mintingContract = new app.web3Store.web3.eth.Contract(
          app.mintingABI,
          app.mintingContract
        );

        const supply = app.singleNft.metadata.privateAttributes.find(
          (el) => el.trait_type === "Number"
        ).value;
        let deadline = app.singleNft.metadata.privateAttributes.find(
          (el) => el.trait_type === "Duration"
        ).value;
        if (deadline == "infinite") deadline = 0;
        const price = app.singleNft.price.toString();
        let fee = "0";
        // const message = app.singleNft.ipfs;
        // const signature = await app.web3Store.web3.eth.personal.sign(
        //   message,
        //   app.userStore.address
        // );

        if (price == "0") {
          const tokenContract = new app.web3Store.web3.eth.Contract(
            app.tokenABI,
            app.tokenContract
          );
          const costPerFreeMinting = await tokenContract.methods
            .costPerFreeMinting()
            .call();
          if (this.bugConsole) {
            console.log(supply);
          }
          fee = (costPerFreeMinting * supply).toString();
        }

        const estimated = await mintingContract.methods
          .mintNFT(
            app.tokenContract,
            app.userStore.user.tokenId,
            app.singleNft.ipfs.substring(7),
            supply,
            price,
            deadline
          )
          .estimateGas({
            from: app.userStore.address,
            value: fee,
          });
        const gasLimit = parseInt(estimated * 1.4).toString();
        const mintRes = await mintingContract.methods
          .mintNFT(
            app.tokenContract,
            app.userStore.user.tokenId,
            app.singleNft.ipfs.substring(7),
            supply,
            price,
            deadline
          )
          .send({
            from: app.userStore.address,
            value: fee,
            gas: gasLimit,
          });
        if (this.bugConsole) {
          console.log(mintRes);
        }
        // console.log(mintRes.events);
        // console.log(mintRes.events.Transfer);
        // console.log(mintRes.events.Transfer.returnValues);
        // console.log(mintRes.events.Minted);
        // console.log(mintRes.events.Minted.returnValues.tokenId);

        // mintRes.events.Transfer.returnValues.tokenId
        const tokenId = mintRes.events.Minted.returnValues.tokenId;
        const mintServerRes = await axios.post(app.api + "/nfts/mint", {
          id: this.idNft,
          tokenId,
          mintTx: mintRes.transactionHash,
          // message,
          // signature,
          smartContract: app.tokenContract,
          mintPrice: price,
          address: app.userStore.address,
        });

        if (mintServerRes.data.error) {
          console.error(mintServerRes.data.message);
          app.mintStatus = "failure";
        } else {
          console.log("Succcessfully requested to the server");
          app.mintStatus = "success";
          window.location.href =
            "#/single-nft/" +
            app.singleNft.blockchain +
            "/" +
            app.tokenContract +
            "/" +
            mintServerRes.data.slug;
        }
      } catch (err) {
        app.mintStatus = "not_sent";
        console.log(err.message);
        alert(err.message);
      }
    },
    async getSingleNft() {
      const app = this;
      this.fetchStatus = "pending";
      try {
        const response = await axios.get(
          app.api + "/nfts/single-not-minted/" + app.idNft
        );
        app.singleNft = response.data.nft;

        app.priceNft = Web3.utils.fromWei(
          app.singleNft.price.toString(),
          "ether"
        );
        app.creator = app.singleNft.creatorFull[0];
        if (app.singleNft.owners && app.singleNft.owners.length > 0) {
          app.owners = [];
          app.singleNft.owners.forEach((owner) => {
            app.owners.push(owner.address);
          });
          if (this.bugConsole) {
            console.log("owners", app.owners);
          }
        }

        const supply = app.singleNft.metadata.privateAttributes.find(
          (el) => el.trait_type === "Number"
        ).value;
        app.supply = supply;

        this.dur = app.singleNft.metadata.privateAttributes.find(
          (el) => el.trait_type === "Duration"
        ).value;
        if (this.bugConsole) {
          console.log("dur", this.dur);
        }
        if (this.dur == "infinite") {
          app.duration = app.dur;
        } else {
          const tmpDuration = new Date(app.dur * 1000);
          const day = ("0" + tmpDuration.getDate()).slice(-2);
          const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          const month = ("0" + (tmpDuration.getMonth() + 1)).slice(-2);
          const monthName = monthNames[tmpDuration.getMonth()];
          app.duration =
            monthName +
            " " +
            day +
            ", " +
            tmpDuration.getFullYear() +
            " at " +
            ("0" + tmpDuration.getUTCHours()).slice(-2) +
            ":" +
            ("0" + tmpDuration.getUTCMinutes()).slice(-2) +
            " GMT";
        }

        const file = app.singleNft.metadata.media;
        app.media = file;
        if (this.bugConsole) {
          console.log("media", this.media);
          console.log("dddd", app.creator);
        }
        app.personalTags = app.singleNft.metadata.privateAttributes.find(
          (el) => el.trait_type === "PersonalTags"
        )?.value;
        app.targets = app.singleNft.metadata.privateAttributes.find(
          (el) => el.trait_type === "TargetGroups"
        )?.value;
        app.tagCategory = app.singleNft.metadata.attributes.find(
          (el) => el.trait_type === "Utility"
        )?.value;
        console.log("app.tagCategory", app.tagCategory);
        this.fetchStatus = "success";
      } catch (err) {
        this.fetchStatus = "failure";
        console.log(err);
      }
      if (this.bugConsole) {
        console.log("<<<<<<<========== eccolo", app.singleNft);
      }
      //COUNTDOWN TIMER START

      const eventTime = app.singleNft.metadata.privateAttributes.find(
        (el) => el.trait_type === "Duration"
      ).value;

      const currentTime = Math.floor(Date.now() / 1000);

      const diffTime = eventTime - currentTime;

      let durationCont = moment.duration(diffTime, "seconds");
      const interval = 1000;

      if (this.bugConsole) {
        console.log("eventTime", eventTime);
        console.log("currentTime", currentTime);
        console.log("diffTime", diffTime);
        console.log("duration", durationCont);
      }
      setInterval(() => {
        durationCont = moment.duration(
          durationCont.asMilliseconds() - interval
        );
        app.leftDays = Math.floor(durationCont.asDays());
        app.leftHours = durationCont.hours();
        app.leftMinutes = durationCont.minutes();
        app.leftSeconds = durationCont.seconds();
      }, interval);

      //COUNTDOWN TIMER END
    },

    showModalDelete() {
      this.$modal.show("confirm-delete");
      console.log("modaleeee");
    },
    closeModalDelete() {
      this.$modal.hide("confirm-delete");
    },
    async checkSyncNFT() {
      const app = this;
      const mintingContract = new app.web3Store.web3.eth.Contract(
        app.tokenABI,
        app.tokenContract
      );
      console.log("single nft", app.singleNft);
      const checkMinted = await mintingContract.methods
        .passTokens(app.singleNft.ipfs.replace("ipfs://", ""))
        .call();

      // TODO CHECK
      if (checkMinted.id !== "0" && !app.singleNft.minted) {
        try {
          console.log("TODO: MANUAL PARSE");
          await axios.post(app.api + "/nfts/parse", {
            nftId: app.idNft,
          });
          alert("NFT synced and already minted. Redirecting to My Niftyz...");
          window.location.href = "#/my-niftyz";
        } catch (e) {
          console.log("PARSE_ERROR", e.message);
        }
      } else {
        console.log("Nft is already synced");
      }
    },
    // async checkSyncNFT() {
    //   console.log("Syncing NFT");
    //   const app = this;
    //   try {
    //     const tokenContractInstance = new app.web3Store.web3.eth.Contract(
    //       app.tokenABI,
    //       app.tokenContract
    //     );

    //     console.log(app.singleNft.tokenId);
    //     console.log(app.userStore.address);
    //     const tokenBalance = await tokenContractInstance.methods
    //       .balanceOf(app.userStore.address, Number(app.singleNft.tokenId))
    //       .call();

    //     if (
    //       tokenBalance > 0 &&
    //       app.singleNft.owners?.findIndex(
    //         (el) =>
    //           el.address.toLowerCase() == app.userStore.address.toLowerCase()
    //       ) == -1
    //     ) {
    //       const syncRes = await axios.post(app.api + "/nfts/sync", {
    //         nftId: app.singleNft.tokenId,
    //         address: app.userStore.address,
    //       });

    //       if (syncRes.data.error) {
    //         console.error(syncRes.data.message);
    //       } else {
    //         console.log("successfully synced NFT");
    //         window.location.reload();
    //       }
    //     } else {
    //       console.log("NFT is already synced");
    //     }
    //   } catch (err) {
    //     app.mintStatus = "not_sent";
    //     console.log(err.message);
    //     alert(err.message);
    //   }
    // },
    async deleteNFT() {
      const app = this;

      try {
        const toSign = "delete request for NFT no. " + app.singleNft.creationId;
        const signature = await app.web3Store.web3.eth.personal.sign(
          toSign,
          app.userStore.address
        );
        console.log("signature", signature);
        const response = await axios.post(app.api + "/nfts/delete-not-minted", {
          nftId: app.singleNft._id,
          message: toSign,
          signature,
          address: app.userStore.address,
        });
        window.location.href = "#/my-niftyz";
      } catch (err) {
        console.log(err);
      }
    },
  },
  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useWeb3Store),
  },
};
</script>

<style lang="scss" scoped>
.prova {
  display: flex;
  flex-direction: column;
}
</style>
>
