<template>
  <button :disabled="web3Store.connectStatus ==='pendig'"
   class="btn-connect" @click="web3Store.connect()">
    Connect Wallet
  </button>
</template>

<script>
import { mapStores } from "pinia";
import { useWeb3Store } from "../stores/web3Store";

export default {
  name: "ConnectButton",
  data() {
    return {
      // ABI: abi,
      // api: process.env.VUE_APP_API_ENDPOINT,
      // contract: process.env.VUE_APP_CONTRACT_ADDRESS_MEMBERSHIP,
      // network: process.env.VUE_APP_NETWORK,
      // infuraId: process.env.VUE_APP_INFURA_ID,
      // landingUrl: process.env.VUE_APP_LANDING_DOMAIN,
      // account: "",
      // balance: 0,
      // web3: "",
      // networks: {
      //   ethereum: 1,
      //   rinkeby: 4,
      //   goerli: 5,
      //   polygon: 137,
      //   mumbai: 80001,
      //   ganache: 5777,
      // },
    };
  },
  methods: {
    // async connect() {
    //   const app = this;
    //   let providerOptions = {};
    //   if (app.infuraId !== undefined) {
    //     console.log("Using infuraid:", app.infuraId);
    //     providerOptions = {
    //       walletconnect: {
    //         package: WalletConnectProvider,
    //         options: {
    //           infuraId: app.infuraId,
    //         },
    //       },
    //     };
    //   } else if (!window.ethereum) {
    //     alert("Please install MetaMask to proceed");
    //     return;
    //   }
    //   // Instantiating Web3Modal
    //   const web3Modal = new Web3Modal({
    //     cacheProvider: true,
    //     providerOptions: providerOptions,
    //   });
    //   const provider = await web3Modal.connect();
    //   app.web3 = await new Web3(provider);
    //   // Checking if networkId matches
    //   const netId = await app.web3.eth.net.getId();
    //   if (parseInt(netId) !== app.networks[app.network]) {
    //     alert("Switch to " + app.network + " network!");
    //   } else {
    // //     const accounts = await app.web3.eth.getAccounts();
    // //     if (accounts.length > 0) {
    // // //       app.account = accounts[0];
    //       const nftContract = new app.web3.eth.Contract(app.ABI, app.contract);
    // //       app.balance = await nftContract.methods.balanceOf(app.account).call();
    //       console.log(app.balance);
    //       if (parseInt(app.balance) === 0) {
    // //         this.userStore.connect({ address: app.account, isMember: false });
    //         // if (this.$route.name !== 'Gallery' || this.$route.name !== 'SingleNft' || this.$route.name !== 'Author')
    //         // {
    //         //   alert("You don't have any NFTs. Redirecting...");
    //         //   window.location.hash = "#/gallery"
    //         // }
    //         //  window.location.href = app.landingUrl + "#/#mint-buy";
    //       } else {
    // //         this.userStore.connect({ address: app.account, isMember: true });
    //         //if there is a proof, it is validated elsewhere
    //         if (!this.$route.params?.["proof"]) {
    // //           const buf = Buffer.from(app.account);
    //           const b64 = buf.toString("base64");
    //           const res = await axios.get(app.api + "/user/" + b64);
    //           if (!res.data.error) {
    //             const userRecord = res.data.user;
    //             if (userRecord?.twitter)
    //               this.userStore.setTwitter(userRecord.twitter);
    //             if (userRecord?.linkedin)
    //               this.userStore.setLinkedIn(userRecord.linkedin);
    //           }
    //         }
    //       }
    //     }
    //   }
    // },
  },
  // mounted() {
  //   this.connect();
  // },
  computed: {
    // ...mapStores(useUserStore),
    ...mapStores(useWeb3Store),
  },
};
</script>