<template>
  <div id="gallery" class="my-niftyz">
    <Header />
    <BoxUp />
    <div class="container pt-3">
      <div class="row mt-5">
        <div class="col-12">
          <h2>Gallery</h2>
        </div>
      </div>
      <div class="row register mt-5">
        <div class="col-12 col-md-5">
          <div class="box-collection border-grey">
            <ul class="custom-select">
              <div v-for="filter in generatedFilters" :key="filter.key">
                <div v-if="filter.inputType == 'dropdown'">
                  <inputSelect
                    :entries="[
                      { key: 'none', value: '', label: 'Clear Filter' },
                      ...filter.values,
                    ]"
                    :label="filter.label"
                    @entryClick="(value) => onFilterSelect(filter, value)"
                  />
                </div>
                <div
                  class="custom-select calendario"
                  v-else-if="filter.inputType == 'date'"
                >
                  <h6>{{ filter.label }}</h6>
                  <input
                    class="focus-input w-100 text-white border-grey"
                    style="background: transparent"
                    type="date"
                    :id="filter.key"
                    :name="filter.key"
                    @change="(e) => onFilterSelect(filter, e.target.value)"
                  />
                </div>
                <div
                  class="custom-select pe-0 pb-0"
                  v-else-if="filter.inputType == 'bubble'"
                >
                  <h6>{{ filter.label }}</h6>
                  <li
                    class="list-buble"
                    v-for="value in filter.values"
                    :class="
                      checkIsFilterValueSelected(filter.key, value.value)
                        ? 'active'
                        : null
                    "
                    :key="value.key"
                    @click="onFilterSelect(filter, value.value)"
                  >
                    {{ value.label }}
                  </li>
                </div>
                <div v-else>
                  <li>{{ filter.label }}</li>
                  <ul>
                    <li
                      v-for="value in filter.values"
                      :key="value.key"
                      @click="onFilterSelect(filter, value.value)"
                    >
                      {{ value.label }}
                    </li>
                  </ul>
                </div>
              </div>
            </ul>
          </div>
          <div class="d-flex align-items-center border-grey placeholder-nft">
            <h3>Don’t forget to mint your NFT!</h3>
            <img src="../assets/placeholder.png" alt="" />
          </div>
        </div>
        <div
          style="border: none"
          class="
            col-12 col-md-6
            offset-0 offset-md-1
            box-collection
            container-nft
          "
        >
          <div class="row">
            <div
              :class="isMobile ? 'flex-column' : null"
              class="col-12 d-flex justify-content-between"
            >
              <div class="searchBar border-grey">
                <img class="searchIcon" src="../assets/search.svg" alt="" />
                <input
                  class="searchBox"
                  type="text"
                  name="search"
                  placeholder="Search by name..."
                  v-model="searchNft"
                />
              </div>
              <div
                :class="isMobile ? 'p-0 mt-0' : null"
                class="custom-select sort-input pt-0"
              >
                <div
                  @click="sortDropdownOpen = !sortDropdownOpen"
                  class="focus-input border-grey"
                >
                  <p>
                    {{ sortMethod == "" ? "Sort by" : sortLabels[sortMethod] }}
                  </p>
                  <i
                    class="fa-solid"
                    :class="
                      sortDropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'
                    "
                  ></i>
                </div>
                <ul class="border-grey" v-if="sortDropdownOpen">
                  <li @click="onSortClick('')">Clear Filter</li>
                  <li @click="onSortClick('alphabetically')">
                    {{ sortLabels.alphabetically }}
                  </li>
                  <li @click="onSortClick('sale')">{{ sortLabels.sale }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row all-nfts" v-if="searchedNfts.length > 0">
            <div
              v-for="(nft, index) in searchedNfts"
              :key="index"
              class="col-6 col-md-6 p-2"
            >
              <a
                v-if="nft"
                :href="
                  '/#/single-nft/' +
                  nft.blockchain +
                  '/' +
                  nft.smartContract +
                  '/' +
                  nft.encodedSlug
                "
              >
                <div class="single-nft">
                  <div
                    v-if="nft.owners && nft.owners.length >= nft.supply"
                    class="hover-nft sold-out"
                  >
                    <p>SOLD-OUT</p>
                  </div>
                  <div
                    v-if="nft.owners && nft.owners.length < nft.supply"
                    class="hover-nft buy-nft"
                  >
                    <p>BUY</p>
                  </div>
                  <img
                    class="w-100 mb-2"
                    :src="
                      'https://ipfs.niftyz.io/ipfs/' +
                      nft.metadata.image.substring(7)
                    "
                    alt=""
                  />
                  <!-- <h6>{{ nft.metadata.name }}</h6> -->
                </div>
              </a>
            </div>
          </div>
          <div v-if="searchedNfts.length === 0">No NFT!</div>
        </div>
      </div>
    </div>
    <BoxDown />
    <Footer />
  </div>
</template>

<script>
import BoxDown from "@/components/BoxDown.vue";
import BoxUp from "@/components/BoxUp.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import inputSelect from "@/components/inputSelect.vue";
import {
  applyFilters,
  generateFilters,
  isFilterValueSelected,
  selectFilter,
} from "@/libs/filters";
import { useUserStore } from "@/stores/userStore";
import axios from "axios";
import { mapStores } from "pinia";
import Web3 from "web3";
export default {
  name: "Gallery",
  data() {
    return {
      api: process.env.VUE_APP_API_ENDPOINT,
      isMobile: false,
      allNfts: [],
      supply: null,
      searchNft: "",
      sortDropdownOpen: false,
      sortMethod: "",
      sortLabels: { alphabetically: "Alphabetically", sale: "Recently Sold" },
      /* filters */
      generatedFilters: [],
      activeFilters: [],
      filteredData: [],
    };
  },
  components: {
    Header,
    inputSelect,
    Footer,
    BoxUp,
    BoxDown,
  },
  async mounted() {
    const app = this;
    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
  async beforeMount() {
    const app = this;
    await this.getNft();
    this.generatedFilters = generateFilters(this.allNfts, {
      include: [
        /* spec 1 */
        {
          key: "price",
          getValues: function (el) {
            const values = ["0", "0.2", "2", "5", "+"];
            return values;
          },
          applyFilter: function (nft, filterValue) {
            console.log("------>", filterValue);
            const values = ["0", "0.2", "2", "5"].map((el) =>
              Web3.utils.toWei(el, "ether")
            );
            if (filterValue == "+") {
              if (Number(nft.price) > Number(values[values.length - 1])) {
                return true;
              }
            } else {
              if (Number(filterValue) == 0) {
                return Number(nft.price) == 0;
              } else {
                const valueIndex = values.findIndex((el) => el == filterValue);
                return (
                  Number(nft.price) > Number(values[valueIndex - 1]) &&
                  Number(nft.price) <= Number(filterValue)
                );
              }
            }
            return false;
          },
          label: "price",
          inputType: "dropdown",
        },

        /* spec 2 */
        {
          key: "creator",
          getValue: function (el) {
            return el.creatorFull[0]?.twitter ?? el.creatorFull[0]?.linkedin;
          },
          applyFilter: function (nft, filterValue) {
            return (
              nft.creatorFull[0]?.twitter == filterValue ||
              nft.creatorFull[0]?.linkedin == filterValue
            );
          },
          label: "Creator",
          inputType: "dropdown",
        },

        /* spec 4 */
        {
          key: "personalTags",
          getValues: function (el) {
            return el.metadata.privateAttributes.find(
              (attr) => attr.trait_type == "PersonalTags"
            ).value;
          },
          applyFilter: function (nft, filterValue) {
            console.log(nft);
            console.log(filterValue);
            return nft.metadata.privateAttributes
              .find((attr) => attr.trait_type == "PersonalTags")
              .value.includes(filterValue);
          },
          label: "Personal Tags",
          inputType: "dropdown",
        },

        /* spec 5 */
        {
          key: "targetGroups",
          getValues: function (el) {
            return el.metadata.privateAttributes.find(
              (attr) => attr.trait_type == "TargetGroups"
            ).value;
          },
          applyFilter: function (nft, filterValue) {
            console.log(nft);
            console.log(filterValue);
            return nft.metadata.privateAttributes
              .find((attr) => attr.trait_type == "TargetGroups")
              .value.includes(filterValue);
          },
          label: "Target",
          inputType: "dropdown",
        },
        /* spec 6 */
        {
          key: "duration",
          applyFilter: function (nft, filterValue) {
            console.log(nft);
            const seconds = new Date(filterValue).getTime() / 1000;
            return (
              nft.metadata.privateAttributes.find(
                (attr) => attr.trait_type == "Duration"
              ).value <= seconds
            );
          },
          label: "End time",
          inputType: "date",
        },
        /* spec 3 */
        {
          key: "cta",
          getValue: function (el) {
            return el.metadata.privateAttributes.find(
              (attr) => attr.trait_type == "CTA"
            ).value;
          },
          applyFilter: function (nft, filterValue) {
            return (
              nft.metadata.privateAttributes.find(
                (attr) => attr.trait_type == "CTA"
              ).value == filterValue
            );
          },
          label: "CTA",
          inputType: "bubble",
          multiple: true,
        },
      ],
    }).map((filter) => {
      console.log("filter", filter);
      const transformed = {
        ...filter,
      };
      if (transformed.values) {
        if (transformed.key == "price") {
          transformed.values = transformed.values.map((el, index) => {
            const wei = el == "+" ? "+" : Web3.utils.toWei(el, "ether");
            return {
              key: el,
              value: wei,
              label:
                el == "0"
                  ? "Free"
                  : el == "+"
                  ? "Higher"
                  : transformed.values[index - 1] + " - " + el + " (ETH)",
            };
          });
        } else {
          transformed.values = transformed.values.map((el) => ({
            key: el,
            value: el,
            label: el,
          }));
        }
      }
      return transformed;
    });

    this.refreshFilteredData();
  },
  computed: {
    searchedNfts() {
      const searchFiltered = this.filterSearch();
      let nfts = this.sortNfts(searchFiltered);
      return nfts;
    },

    ...mapStores(useUserStore),
  },

  methods: {
    async getNft() {
      const app = this;
      try {
        const response = await axios.get(app.api + "/nfts/public-for-sale");
        const allNfts = response.data.nfts.map((nft) => {
          const supply = nft.metadata.privateAttributes.find(
            (el) => el.trait_type === "Number"
          ).value;
          nft.supply = supply;
          nft.encodedSlug = encodeURIComponent(nft.slug);
          return nft;
        });
        app.allNfts = allNfts;
      } catch (err) {
        console.log(err);
      }
    },
    onSortClick(newMethod) {
      this.sortDropdownOpen = false;
      this.sortMethod = newMethod;
    },
    sortNfts(nfts) {
      if (this.sortMethod == "alphabetically") {
        return nfts.sort((a, b) => {
          return a.metadata.name.localeCompare(b.metadata.name);
        });
      } else if (this.sortMethod == "sale") {
        return nfts.sort((a, b) => {
          let higherA = 0;
          if (a.minted && a?.owners?.length > 0) {
            const lastOwner = a.owners[a.owners.length - 1];
            if (lastOwner?.buyTimestamp) {
              higherA = lastOwner.buyTimestamp;
            }
          }
          let higherB = 0;
          if (b.minted && b?.owners?.length > 0) {
            const lastOwner = b.owners[b.owners.length - 1];
            if (lastOwner?.buyTimestamp) {
              higherB = lastOwner.buyTimestamp;
            }
          }
          return higherB - higherA;
        });
      } else {
        return nfts;
      }
    },
    filterSearch() {
      return this.filteredData.filter((member) =>
        member.metadata.name
          .toLowerCase()
          .includes(this.searchNft.toLowerCase())
      );
    },
    onFilterSelect(filter, value) {
      this.activeFilters = selectFilter(this.activeFilters, filter, value);
      this.refreshFilteredData();
      console.log("activeFilters", this.activeFilters);
    },
    refreshFilteredData() {
      this.filteredData = applyFilters(this.allNfts, this.activeFilters);
    },
    checkIsFilterValueSelected(key, value) {
      return isFilterValueSelected(this.activeFilters, key, value);
    },
  },
};
</script>

<style></style>
