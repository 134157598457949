<template>
  <div class="box-up">
    <div class="bk-jb"></div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-5">
          <h2>Discover our private collection of NFTs!</h2>
        </div>
        <div
          style="position: relative; z-index: 999"
          :class="isMobile ? 'text-center mt-4' : 'text-end'"
          class="col-12 col-md-6 offset-md-1 offset-0"
        >

          <button
            class="btn-connect"
            @click="redirectHref"
            >Go to Niftyz
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useUserStore } from "../stores/userStore";
export default {
  name: "BoxUp",
  data() {
    return {
      isMobile: false,
      routeLanding:process.env.VUE_APP_LANDING_DOMAIN
    };
  },
  methods: {
    redirectHref() {
      if (this.userStore.isConnected) {
        if (this.userStore.isMember) {
          if (
            this.userStore.user?.city != null &&
            this.userStore.user?.city != undefined
          ) {
            window.location.href = "#/my-niftyz";
          }else{
            window.location.href = "#/";
          }
        }else{
          window.location.href = this.routeLanding;
        }
      } 
    },
  },
  mounted() {
    const app = this;
    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
  computed: {
    ...mapStores(useUserStore),
  },
};
</script>

<style></style>
