var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!this.userStore.isConnected)?_c('Verify'):_vm._e(),(this.userStore.isConnected)?_c('div',{staticClass:"my-niftyz mt-mob-10"},[_c('Header'),_c('div',{staticClass:"container"},[_vm._m(0),(_vm.prevNft.metadata)?_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12 col-md-4 box-collection text-center mb-0"},[_c('img',{staticClass:"preview-image",attrs:{"src":'https://ipfs.niftyz.io/ipfs/' +
              _vm.prevNft.metadata.image.substring(7),"alt":""}})]),_c('div',{staticClass:"col-12 col-md-7 offset-0 offset-md-1 box-collection"},[_c('div',{staticClass:"d-flex",class:_vm.isMobile ? 'w100 flex-column' : 'w-75'},[_c('h6',[_vm._v("Description:")]),_c('p',{staticClass:"ms-3 mt-0 break-word"},[_vm._v(" "+_vm._s(_vm.prevNft.metadata.description)+" ")])]),_c('div',{staticClass:"d-flex",class:_vm.isMobile ? 'flex-column' : null},[_c('h6',[_vm._v("Deadline:")]),(_vm.duration && _vm.duration != '')?_c('p',{staticClass:"ms-3 mt-0 break-word"},[_vm._v(" "+_vm._s(_vm.duration)+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex",class:_vm.isMobile ? 'flex-column' : null},[_c('h6',[_vm._v("Price:")]),_c('p',{staticClass:"ms-3 mt-0 break-word"},[_vm._v(_vm._s(_vm.priceNft)+" ETH")])]),_c('div',{staticClass:"d-flex",class:_vm.isMobile ? 'flex-column' : null},[_c('h6',[_vm._v("NFT Supply:")]),_c('p',{staticClass:"ms-3 mt-0 break-word"},[_vm._v(" "+_vm._s(_vm.supply)+" ")])]),_c('div',{staticClass:"d-flex",class:_vm.isMobile ? 'flex-column' : null},[_c('h6',[_vm._v("Sale Type:")]),_c('p',{staticClass:"ms-3 mt-0 break-word"},[_vm._v(" "+_vm._s(_vm.saleType)+" ")])]),_c('div',{staticClass:"d-flex flex-column",class:_vm.isMobile ? 'flex-column' : 'align-items-start'},[_c('h6',{staticClass:"mb-3"},[_vm._v("Preview Files:")]),_c('div',{class:_vm.isMobile ? 'd-flex flex-wrap' : null},_vm._l((_vm.media),function(file,index){return _c('a',{key:index,staticClass:"link-preview",attrs:{"href":file.ipfs
                    ? 'https://ipfs.niftyz.io/ipfs/' + file.ipfs.substring(7)
                    : file.fileUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(file.name)+" ")])}),0)]),_c('div',{staticClass:"d-flex mt-4",class:_vm.isMobile ? 'justify-content-center' : 'justify-content-end'},[(
                !_vm.prevNft.minted &&
                _vm.prevNft.creator == _vm.userStore.address &&
                _vm.mintStatus == 'not_sent'
              )?_c('button',{staticClass:"btn-connect me-4",on:{"click":_vm.mintNFT}},[_vm._v(" Mint Now ")]):_vm._e(),(
                !_vm.prevNft.minted &&
                _vm.mintStatus != 'pending' &&
                _vm.mintStatus != 'success'
              )?_c('button',{staticClass:"btn-delete",on:{"click":_vm.showModalDelete}},[_vm._v(" Delete ")]):_vm._e(),_c('modal',{staticClass:"description-nft text-black",attrs:{"name":"confirm-delete"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('i',{staticClass:"fa-solid fa-circle-xmark p-3",on:{"click":function($event){return _vm.closeModalDelete()}}})]),_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center mt-1"},[_c('h3',{staticClass:"text-buy"},[_vm._v(" Are you sure you want to delete this NFT? ")]),_c('div',{staticClass:"d-flex mt-2 box-confirmDelete"},[_c('button',{staticClass:"btn-exit",on:{"click":_vm.closeModalDelete}},[_vm._v(" Close ")]),_c('button',{staticClass:"btn-delete",on:{"click":_vm.deleteNFT}},[_vm._v(" Delete ")])])])]),(_vm.mintStatus == 'pending')?_c('div',{staticClass:"me-4 mt-0 mb-2",staticStyle:{"color":"white","font-size":"1.5rem"}},[_vm._m(1)]):_vm._e(),(_vm.mintStatus == 'success')?_c('div',{staticClass:"me-4 mt-2 mb-2",staticStyle:{"color":"#0099ff","font-size":"1.5rem"}},[_vm._v(" You successfully minted your NFT! ")]):_vm._e()],1)])]):_vm._e(),_vm._m(2)]),_c('Footer')],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row pt-5"},[_c('div',{staticClass:"col-12 mb-4"},[_c('a',{attrs:{"href":"/#/create-nft"}},[_c('i',{staticClass:"fa-solid fa-arrow-left-long"}),_vm._v(" Restart NFT creation ")])]),_c('div',{staticClass:"col-12 col-md-8"},[_c('h2',[_vm._v("Pass Token Preview")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lds-default"},[_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12 col-md-4 box-collection text-center mb-0"},[_c('img',{staticClass:"preview-image",attrs:{"src":require("../assets/bk/bk-orange.png"),"alt":""}})]),_c('div',{staticClass:"col-12 col-md-7 offset-0 offset-md-1 box-collection summary-plane"},[_c('h5',[_vm._v("Summary of your Premium Plan")]),_c('ul',[_c('li',[_vm._v("Print and share your NFTs with your community")]),_c('li',[_vm._v("Your NFTs will be published on Niftyz.io and Opensea")]),_c('li',[_vm._v("Access to Niftyz Perks and rewards & Premium Discord")]),_c('li',[_vm._v(" NFT Academy Certification and earn with our Partners Program ")]),_c('li',[_vm._v("Airdrops (or NFTs giveaway) fees: 0.00005 ETH per NFT")]),_c('li',[_vm._v("Minting fees (Premium NFTs): 0%")])])])])
}]

export { render, staticRenderFns }