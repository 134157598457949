<template>
  <div class="creation mt-mob-10 register">
    <Verify v-if="!this.userStore.isConnected" />
    <div v-if="this.userStore.isConnected">
      <Header />
      <div v-if="this.userStore.isMember">
        <Jumbotron />
      </div>
      <div class="container mt-5">
        <a href="/#/my-niftyz"
          ><i class="fa-solid fa-arrow-left-long"></i> Back to My Pass Tokens</a
        >
        <h2 class="mt-5">Create Pass Token</h2>
        <!-- STEP 1 -->
        <!-- <div class="row single-step">
          <div class="col-12">
            <h4>
              <i
                class="fa-solid"
                :class="
                  this.utility.length > 0
                    ? 'fa-circle-check'
                    : 'fa-circle-xmark'
                "
              ></i>
              Step 1 - Select the utility of your Pass Token or create your own
            </h4>
          </div>
          <div class="col-6 col-md-4">
            <div @click="selectUtility('tickets')" class="account-type">
              <div
                class="box-account"
                :class="this.utility == 'tickets' ? 'active-box' : null"
                style="background: #1b8ee5"
              >
                <i
                  :class="
                    this.utility == 'tickets'
                      ? 'd-block check-account'
                      : 'd-none'
                  "
                  class="fa-solid fa-circle-check"
                ></i>
                <h6 class="mb-0">
                  Create smart memberships, tickets and awards
                </h6>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div @click="selectUtility('deal-flow')" class="account-type">
              <div
                class="box-account"
                :class="this.utility == 'deal-flow' ? 'active-box' : null"
                style="background: #33ba64"
              >
                <i
                  :class="
                    this.utility == 'deal-flow'
                      ? 'd-block check-account'
                      : 'd-none'
                  "
                  class="fa-solid fa-circle-check"
                ></i>
                <h6 class="mb-0">Earn from your deal flow</h6>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div @click="selectUtility('know-how')" class="account-type">
              <div
                class="box-account"
                :class="this.utility == 'know-how' ? 'active-box' : null"
                style="background: #651bcc"
              >
                <i
                  :class="
                    this.utility == 'know-how'
                      ? 'd-block check-account'
                      : 'd-none'
                  "
                  class="fa-solid fa-circle-check"
                ></i>
                <h6 class="mb-0">Monetize your know-how</h6>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div @click="selectUtility('fundraising')" class="account-type">
              <div
                class="box-account"
                :class="this.utility == 'fundraising' ? 'active-box' : null"
                style="background: #f18819"
              >
                <i
                  :class="
                    this.utility == 'fundraising'
                      ? 'd-block check-account'
                      : 'd-none'
                  "
                  class="fa-solid fa-circle-check"
                ></i>
                <h6 class="mb-0">Signal fundraising</h6>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div @click="selectUtility('documents')" class="account-type">
              <div
                class="box-account"
                :class="this.utility == 'documents' ? 'active-box' : null"
                style="background: #ee2737"
              >
                <i
                  :class="
                    this.utility == 'documents'
                      ? 'd-block check-account'
                      : 'd-none'
                  "
                  class="fa-solid fa-circle-check"
                ></i>
                <h6 class="mb-0">Monetize pitch documents</h6>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div @click="selectUtility('trainings')" class="account-type">
              <div
                class="box-account"
                :class="this.utility == 'trainings' ? 'active-box' : null"
                style="background: #f18819"
              >
                <i
                  :class="
                    this.utility == 'trainings'
                      ? 'd-block check-account'
                      : 'd-none'
                  "
                  class="fa-solid fa-circle-check"
                ></i>
                <h6 class="mb-0">Create online courses / Trainings and earn</h6>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div @click="selectUtility('promotions')" class="account-type">
              <div
                class="box-account"
                :class="this.utility == 'promotions' ? 'active-box' : null"
                style="background: #651bcc"
              >
                <i
                  :class="
                    this.utility == 'promotions'
                      ? 'd-block check-account'
                      : 'd-none'
                  "
                  class="fa-solid fa-circle-check"
                ></i>
                <h6 class="mb-0">Drop promotions and freebies</h6>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div @click="selectUtility('partners')" class="account-type">
              <div
                class="box-account"
                :class="this.utility == 'partners' ? 'active-box' : null"
                style="background: #33ba64"
              >
                <i
                  :class="
                    this.utility == 'partners'
                      ? 'd-block check-account'
                      : 'd-none'
                  "
                  class="fa-solid fa-circle-check"
                ></i>
                <h6 class="mb-0">Reward sales partners</h6>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div @click="selectUtility('your-own')" class="account-type">
              <div
                class="box-account"
                :class="this.utility == 'your-own' ? 'active-box' : null"
                style="background: #1b8ee5"
              >
                <i
                  :class="
                    this.utility == 'your-own'
                      ? 'd-block check-account'
                      : 'd-none'
                  "
                  class="fa-solid fa-circle-check"
                ></i>
                <h6 class="mb-0">Create your own</h6>
              </div>
            </div>
          </div>
        </div> -->
        <!-- STEP 2 -->

        <div class="single-step">
          <div class="row">
            <div class="col-12">
              <h4>
                <i
                  class="fa-solid"
                  :class="
                    selectedCTA != '' &&
                    selectedTag.length != 0 &&
                    name != '' &&
                    description != '' &&
                    coverUrl != null
                      ? 'fa-circle-check'
                      : 'fa-circle-xmark'
                  "
                ></i>
                Step 1 - Name your Pass Token
              </h4>
            </div>
          </div>
          <div class="test-form row">
            <div class="col-12 col-md-6">
              <div class="custom-select">
                <h5>
                  Pass Token Name <i class="fa-solid fa-circle-info ms-1"> </i>
                  <span class="ms-3 number-characters">Max 56 characters</span>
                </h5>
                <div id="title" class="focus-input">
                  <input
                    v-model="name"
                    maxlength="56"
                    type="text"
                    placeholder="Mint Pass Collection 1"
                  />
                </div>
              </div>
              <div class="custom-select">
                <h5>
                  Description <i class="fa-solid fa-circle-info ms-1"> </i>
                  <span class="ms-3 number-characters">Max 500 characters</span>
                </h5>

                <div id="description" class="">
                  <textarea
                    class="w-100"
                    rows="5"
                    cols="50"
                    name="comment"
                    maxlength="500"
                    form="usrform"
                    placeholder=" Enter text here..."
                    v-model="description"
                  >
                  </textarea>
                </div>
              </div>
              <div class="custom-select">
                <h5>
                  Personal Tags <i class="fa-solid fa-circle-info ms-1"> </i>
                  <span class="ms-3 number-characters">Select up to 3</span>
                </h5>
                <div
                  id="tag"
                  @click="selectTag = !selectTag"
                  class="focus-input"
                >
                  <p
                    id="single-tag"
                    :class="
                      selectedTag.length == 0 ? 'opacity-75' : 'text-white'
                    "
                  >
                    {{
                      selectedTag.length == 0
                        ? "Game, DeFi, Play to Earn"
                        : selectedTag
                    }}
                  </p>
                  <i
                    class="fa-solid"
                    :class="selectTag ? 'fa-chevron-up' : 'fa-chevron-down'"
                  ></i>
                </div>
                <ul class="ps-2 pe-2" v-if="selectTag">
                  <li
                    v-for="(tag, index) in tags"
                    :key="index"
                    @click="toggleTag(tag)"
                    id="test"
                    :class="selectSingleTag(tag) ? 'bk' : null"
                  >
                    {{ tag }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="custom-select">
                <h5>Select CTA</h5>
                <div
                  id="cta"
                  @click="selectCTA = !selectCTA"
                  class="focus-input"
                >
                  <p
                    id="single-tag"
                    :class="selectedCTA == '' ? 'opacity-75' : 'text-white'"
                  >
                    {{ selectedCTA == "" ? "Buy, Download, etc" : selectedCTA }}
                  </p>
                  <i
                    class="fa-solid"
                    :class="selectCTA ? 'fa-chevron-up' : 'fa-chevron-down'"
                  ></i>
                </div>
                <ul v-if="selectCTA">
                  <li
                    v-for="(cta, index) in listCta"
                    :key="index"
                    @click="onCtaChange(cta)"
                  >
                    {{ cta }}
                  </li>
                </ul>
              </div>
              <div class="custom-select position-relative">
                <h5 class="info-point">
                  Upload Cover
                  <i class="fa-solid fa-circle-info ms-1"> </i>
                  <span class="ms-0 number-characters"
                    >Size limit: 1MB. <br />
                    Ideal dimension 800x800
                  </span>
                </h5>
                <div id="cover-nft" class="upload-image cover-nft">
                  <input
                    @change="onNftCover"
                    type="file"
                    name="img"
                    accept=".png,.jpg"
                  />
                  <img
                    v-if="!coverUrl"
                    class="img-upload"
                    src="../assets/upload.svg"
                    alt="submit"
                  />

                  <img
                    class="img-up"
                    v-if="coverUrl && selectedNftCover.size <= 1100000"
                    :src="coverUrl"
                  />

                  <div
                    class="
                      img-up
                      text-center
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                    v-if="coverUrl && selectedNftCover.size > 1100000"
                  >
                    <p class="text-white">
                      Error. <br />
                      Size limit: 1MB
                    </p>
                  </div>
                  <!-- Il CTO vuole il v-else -->
                  <!--  <p>{{selectedNftCover.size}}</p> -->
                </div>
              </div>

              <div class="submit-form custom-select">
                <button
                  v-if="!showStepThree"
                  @click="onNextStepTwo()"
                  class="mt-0 text-center"
                  style="width: 200px"
                  :class="
                    selectedCTA != '' &&
                    selectedTag.length != 0 &&
                    name != '' &&
                    description != '' &&
                    selectedNftCover &&
                    selectedNftCover.size <= 1100000 &&
                    coverUrl != null
                      ? null
                      : 'btn-disabled'
                  "
                  type="submit"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- STEP 3 -->
        <div v-if="showStepThree" class="single-step">
          <div class="row">
            <div class="col-12">
              <h4>
                <i
                  class="fa-solid"
                  :class="
                    (selectedAttachments[selectedAttachments.length - 1].file ||
                      selectedAttachments[selectedAttachments.length - 1]
                        .fileUrl) &&
                    selectedAttachments[selectedAttachments.length - 1]
                      .category &&
                    selectedAttachments[selectedAttachments.length - 1].name
                      ? 'fa-circle-check'
                      : 'fa-circle-xmark'
                  "
                ></i>
                Step 2 - Add attachments to your Pass Token
              </h4>
            </div>
          </div>
          <div class="test-form">
            <div
              v-for="(attachment, index) in selectedAttachments"
              :key="attachment.id"
              class="row m-auto mb-3 mt-3"
            >
              <div class="col-12 col-md-6">
                <div class="custom-select">
                  <h5>Item Name</h5>
                  <div id="itemName" class="focus-input">
                    <input
                      type="text"
                      placeholder="Whitepaper Name"
                      @change="
                        (e) => selectAttachmentName(index, e.target.value)
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="custom-select">
                  <h5>Select Category</h5>
                  <div
                    id="category"
                    @click="toggleAttachmentCategoryDropdown(index)"
                    class="focus-input"
                  >
                    <p
                      id="single-category"
                      :class="
                        selectedAttachments[index].category == ''
                          ? 'opacity-75'
                          : 'text-white'
                      "
                    >
                      {{
                        selectedAttachments[index].category == ""
                          ? "Pith deck, Video, Demo etc"
                          : selectedAttachments[index].category
                      }}
                    </p>
                    <i
                      class="fa-solid"
                      :class="
                        selectedAttachments[index].dropDownOpen
                          ? 'fa-chevron-up'
                          : 'fa-chevron-down'
                      "
                    ></i>
                  </div>
                  <ul v-if="selectedAttachments[index].dropDownOpen">
                    <li
                      v-for="(category, categoryIndex) in categories"
                      :key="categoryIndex"
                      @click="selectAttachmentCategory(index, category)"
                    >
                      {{ category }}
                    </li>
                  </ul>
                </div>
              </div>

              <div class="row mt-4 m-auto">
                <div class="custom-select col-12 col-md-5 position-relative">
                  <h5 class="info-point">
                    Upload File <i class="fa-solid fa-circle-info ms-1"> </i>
                    <span class="compatible size-limit"
                      >Size limit: 20MB; <br />
                      Types compatible: PNG, JPG, SVG, WebP</span
                    >
                  </h5>
                  <div id="upload-image" class="upload-image">
                    <input
                      @change="
                        (e) => selectAttachmentFile(index, e.target.files[0])
                      "
                      type="file"
                      :disabled="
                        selectedAttachments[index].fileUrl != null &&
                        selectedAttachments[index].fileUrl != undefined &&
                        selectedAttachments[index].fileUrl != ''
                      "
                      name="upload"
                    />
                    <img
                      v-if="
                        !selectedAttachments[index].url &&
                        !selectedAttachments[index].uploadStatus
                      "
                      class="img-upload"
                      src="../assets/upload.svg"
                      alt="submit"
                    />
                    <img
                      class="img-up"
                      v-if="
                        selectedAttachments[index].uploadStatus == 'success' &&
                        selectedAttachments[index].url &&
                        selectedAttachments[index].mimeType.startsWith('image')
                      "
                      :src="selectedAttachments[index].url"
                    />
                    <div
                      class="
                        img-up
                        text-center
                        d-flex
                        justify-content-center
                        align-items-center
                      "
                      v-else-if="
                        selectedAttachments[index] &&
                        selectedAttachments[index].uploadStatus == 'failure'
                      "
                    >
                      <p class="text-white">
                        Error. <br />
                        Size limit: 20MB
                      </p>
                    </div>
                    <embed
                      class="img-up"
                      v-else-if="
                        selectedAttachments[index].uploadStatus == 'success' &&
                        selectedAttachments[index].url &&
                        selectedAttachments[index].mimeType == 'application/pdf'
                      "
                      :src="selectedAttachments[index].url"
                    />
                    <embed
                      class="img-up"
                      v-else-if="
                        (selectedAttachments[index].uploadStatus == 'success' &&
                          selectedAttachments[index].url &&
                          selectedAttachments[index].mimeType == 'video/mp4') ||
                        selectedAttachments[index].mimeType == 'audio/ogg' ||
                        selectedAttachments[index].mimeType == 'video/webm'
                      "
                      :src="selectedAttachments[index].url"
                    />
                    <p
                      class="
                        img-up
                        d-flex
                        align-items-center
                        justify-content-center
                        text-white
                      "
                      style="word-wrap: break-word; overflow: hidden"
                      v-else-if="
                        selectedAttachments[index].url &&
                        selectedAttachments[index].uploadStatus == 'success'
                      "
                    >
                      Uploaded File: <br />
                      {{ selectedAttachments[index].file.name }}
                    </p>
                    <div
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        img-up
                      "
                      v-if="
                        selectedAttachments[index].uploadStatus == 'pending'
                      "
                    >
                      <div class="spinner-border custom-spinner" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="custom-select col-12 col-md-2 text-center">
                  <h5 class="text-uppercase">or</h5>
                </div>
                <div class="custom-select col-12 col-md-5">
                  <h5>
                    Upload Link
                    <!-- <i class="fa-solid fa-circle-info ms-1"> </i>
                    <span class="ms-0 number-characters">Should start with https:// or http://</span> -->
                  </h5>
                  <div
                    id=""
                    :class="`focus-input mt-4 ${
                      selectedAttachments[index].fileUrlValid != undefined &&
                      !selectedAttachments[index].fileUrlValid
                        ? 'alert'
                        : null
                    }`"
                  >
                    <input
                      @change="
                        (e) => selectAttachmentFileUrl(index, e.target.value)
                      "
                      type="text"
                      :disabled="selectedAttachments[index].url"
                      placeholder="https://www.linkname.com"
                    />
                  </div>
                  <div
                    class="
                      img-up
                      text-center
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                    v-if="
                      selectedAttachments[index] &&
                      selectedAttachments[index].fileUrlValid != undefined &&
                      !selectedAttachments[index].fileUrlValid
                    "
                  >
                    <p class="text-white">
                      Should start with https:// or http://
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <p
                  class="mt-3 ms-4 d-inline-block"
                  v-if="
                    (selectedAttachments.length < 10 &&
                      (selectedAttachments[selectedAttachments.length - 1]
                        .file ||
                        (selectedAttachments[selectedAttachments.length - 1]
                          .fileUrl &&
                          selectedAttachments[selectedAttachments.length - 1]
                            .fileUrlValid)) &&
                      selectedAttachments[index].uploadStatus == 'success') ||
                    (!selectedAttachments[index].size &&
                      index === selectedAttachments.length - 1 &&
                      selectedAttachments[selectedAttachments.length - 1]
                        .category &&
                      selectedAttachments[selectedAttachments.length - 1].name)
                  "
                  style="cursor: pointer"
                  @click="addAttachment()"
                >
                  Upload another item
                  <i
                    :class="anotherFile ? 'fa-circle-minus' : 'fa-circle-plus'"
                    class="fa-solid ms-2"
                  ></i>
                </p>
              </div>
              <div>
                <p
                  class="mt-3 ms-4 d-inline-block"
                  v-if="selectedAttachments.length > 1"
                  style="cursor: pointer"
                  @click="removeAttachment(index)"
                >
                  Remove item
                  <i class="fa-solid fa-circle-minus ms-2"></i>
                </p>
              </div>
            </div>

            <div class="row mt-4 m-auto">
              <div class="br-t"></div>
              <div class="col-12">
                <div
                  class="
                    submit-form
                    custom-select
                    h-100
                    d-flex
                    justify-content-end
                    align-items-end
                  "
                >
                  <button
                    v-if="!showStepFour"
                    @click="onNextStepThree()"
                    class="mt-0 text-center"
                    style="width: 200px"
                    :class="
                      (selectedAttachments[selectedAttachments.length - 1]
                        .file ||
                        (selectedAttachments[selectedAttachments.length - 1]
                          .fileUrl &&
                          selectedAttachments[selectedAttachments.length - 1]
                            .fileUrlValid)) &&
                      selectedAttachments[selectedAttachments.length - 1]
                        .category &&
                      selectedAttachments[selectedAttachments.length - 1].name
                        ? null
                        : 'btn-disabled'
                    "
                    type="submit"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- STEP 4 -->
        <div v-if="showStepFour" class="single-step">
          <div class="row">
            <div class="col-12">
              <h4>
                <i
                  class="fa-solid"
                  :class="
                    /* selectedChain != '' && */
                    selectedNumberPassToken != '' && price != '' && price != ''
                      ? 'fa-circle-check'
                      : 'fa-circle-xmark'
                  "
                ></i>
                Step 3 - Select minting options
              </h4>
            </div>
          </div>
          <div class="test-form row">
            <div class="col-12 col-md-6">
              <div class="custom-select">
                <h5>
                  Number of Pass Tokens
                  <i class="fa-solid fa-circle-info ms-1"> </i>
                  <span class="ms-3 number-characters"
                    >Max 1000 NFTs per campaign</span
                  >
                </h5>
                <div id="pass-token" class="focus-input">
                  <input
                    @change="numberTokenChange"
                    type="number"
                    max="1000"
                    min="1"
                    onkeyup="if(this.value > 1000) this.value = null;"
                    placeholder="Enter number"
                  />
                </div>
              </div>
              <div class="custom-select">
                <h5>Duration</h5>
                <div id="date" class="focus-input position-relative">
                  <input
                    :disabled="!enableDuration"
                    type="date"
                    :min="dateInput"
                    id="duration"
                    name="duration"
                    v-model="duration"
                  />
                  <i id="icon-cal" class="fa-solid fa-calendar"></i>
                  <!-- <input type="submit" /> -->
                </div>
                <input
                  @click="disableDuration"
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                  class="mt-3 me-1"
                />
                <label for="vehicle1"> Infinite Time</label>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="custom-select">
                <h5>Blockchain</h5>
                <div id="chain" class="focus-input">
                  <p id="single-tag">
                    {{ this.selectedChain.name }}
                  </p>
                </div>
                <!-- Da scommentare quando ci sarà anche matic -->
                <!-- <div
                  id="chain"
                  @click="selectChain = !selectChain"
                  class="focus-input"
                >
                  <p
                    id="single-tag"
                    :class="selectedChain == '' ? 'opacity-75' : 'text-white'"
                  >
                    {{
                      selectedChain == ""
                        ? "Buy, Download, etc"
                        : selectedChain.name
                    }}
                  </p>
                  <i
                    class="fa-solid"
                    :class="selectChain ? 'fa-chevron-up' : 'fa-chevron-down'"
                  ></i>
                </div> -->
                <!-- <ul v-if="selectChain">
                  <li
                    v-for="(chain, index) in blockchain"
                    :key="index"
                    @click="onChainChange(chain)"
                  >
                    {{ chain.name }}
                  </li>
                </ul> -->
              </div>
              <div class="custom-select">
                <h5>Select Currency</h5>
                <div class="focus-input">
                  <p id="single-tag">
                    {{ this.selectedChain.acr }}
                  </p>
                  <!-- Da scommentare quando ci sarà anche matic -->
                  <!-- <p
                    id="single-tag"
                    :class="selectedChain == '' ? 'opacity-75' : 'text-white'"
                  >
                    {{ selectedChain == "" ? "ETH, MATIC" : selectedChain.acr }}
                  </p> -->
                </div>
              </div>
              <div class="custom-select">
                <h5>Enter a price (or enter 0 for Free items)</h5>
                <div id="price" class="focus-input">
                  <input
                    type="number"
                    placeholder="0.062"
                    @change="setTwoNumberDecimal(price)"
                    v-model="price"
                    @keypress="isNumber($event)"
                  />
                  <p class="text-white">ETH</p>
                </div>
              </div>
              <div class="submit-form custom-select">
                <button
                  v-if="!showStepFive"
                  @click="onNextStepFour()"
                  :class="
                    /* selectedChain != '' && */
                    selectedNumberPassToken != '' && price != ''
                      ? null
                      : 'btn-disabled'
                  "
                  class="mt-4 text-center"
                  style="width: 200px"
                  type="submit"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- STEP 5 -->
        <div v-if="showStepFive" class="single-step">
          <div class="row">
            <div class="col-12">
              <h4>
                <i
                  class="fa-solid"
                  :class="
                    passTokenPrivacy != '' && groupsSelect.length != 0
                      ? 'fa-circle-check'
                      : 'fa-circle-xmark'
                  "
                ></i>
                Step 4 - Add targeting
              </h4>
            </div>
          </div>
          <div class="test-form">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="custom-select">
                  <div class="d-flex position-relative">
                    <h5 style="white-space: nowrap" class="mb-0">
                      Pass Token Privacy
                    </h5>
                    <i style="z-index: 99" class="fa-solid fa-circle-info ms-1">
                    </i>
                    <span
                      class="
                        ms-3
                        d-inline-block
                        number-characters
                        privacy-token
                      "
                      >By selecting ‘Private’, your NFT can only be unlocked via
                      the link displayed when you mint your NFT. You can share
                      it with users or customers of your choice. The minted NFT
                      will not appear either on the Niftyz Gallery or on
                      Opensea.
                    </span>
                  </div>
                  <div class="d-flex">
                    <div @click="selectPassTokenPrivacy('public')" class="">
                      <div
                        class="box-pass"
                        :class="
                          this.passTokenPrivacy == 'public'
                            ? 'active-box'
                            : null
                        "
                      >
                        <i
                          :class="
                            this.passTokenPrivacy == 'public'
                              ? 'd-block check-account'
                              : 'd-none'
                          "
                          class="fa-solid fa-circle-check"
                        ></i>

                        Public
                      </div>
                    </div>
                    <div @click="selectPassTokenPrivacy('private')" class="">
                      <div
                        class="box-pass"
                        :class="
                          this.passTokenPrivacy == 'private'
                            ? 'active-box'
                            : null
                        "
                      >
                        <i
                          :class="
                            this.passTokenPrivacy == 'private'
                              ? 'd-block check-account'
                              : 'd-none'
                          "
                          class="fa-solid fa-circle-check"
                        ></i>
                        Private
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="custom-select">
                  <div class="d-flex position-relative">
                    <h5 style="white-space: nowrap" class="mb-0">
                      Target Group
                    </h5>
                    <i class="fa-solid fa-circle-info ms-1"> </i>
                    <span
                      class="ms-3 d-inline-block number-characters target-token"
                      >Attract the right target audience by selecting one or
                      more target groups below. The chosen tags will be visible
                      on the NFT page.
                    </span>
                  </div>
                  <div class="d-flex flex-wrap">
                    <div v-for="(group, index) in groups" :key="index">
                      <div
                        class="box-pass mt-2"
                        :class="
                          groupsSelect.includes(group) ? 'active-box' : null
                        "
                        @click="selectGroup(group)"
                      >
                        <i
                          :class="
                            groupsSelect.includes(group)
                              ? 'd-block check-account'
                              : 'd-none'
                          "
                          class="fa-solid fa-circle-check"
                        ></i>
                        {{ group }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12">
                <div class="submit-form custom-select">
                  <button
                    v-if="!sending"
                    @click="submitRegister()"
                    :class="
                      passTokenPrivacy != '' && groupsSelect.length != 0
                        ? null
                        : 'btn-disabled'
                    "
                    type="submit"
                  >
                    Preview Pass Token
                  </button>

                  <div
                    v-if="sending"
                    @click="submitRegister()"
                    style="color: #4a80ff; cursor: wait; font-size: 1.2rem"
                    type="submit"
                    disabled
                  >
                    Sending application, <br />
                    please wait
                    <div class="lds-ellipsis mobile me-5">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapStores } from "pinia";
import Web3 from "web3";
import Header from "../components/Header.vue";
import Jumbotron from "../components/Jumbotron.vue";
import Verify from "../components/Verify.vue";
import { useUserStore } from "../stores/userStore";

export default {
  name: "createNft",
  data() {
    return {
      infuraId: process.env.VUE_APP_INFURA_ID,
      api: process.env.VUE_APP_API_ENDPOINT,
      idNft: this.$route.params.id,
      utility: "placeholder",
      name: "",
      passTokenPrivacy: "",
      description: "",
      selectedCTA: "",
      duration: "",
      price: "",
      selectedNftLogo: null,
      selectedNftCover: null,
      selectedFileLogo: null,
      selectedMediaLogo: null,
      selectCTA: false,
      selectedCategory: "",
      selectCategory: false,
      selectedCategorySecondFile: "",
      selectedCategoryThird: "",
      selectedAttachments: [{ name: "", category: "", dropDownOpen: false }],
      selectedChain: {
        name: "Ethereum",
        acr: "ETH",
      },
      selectChain: false,
      selectedNumberPassToken: "",
      url: null,
      coverUrl: null,
      media: null,
      file: null,
      anotherFile: false,
      anotherMedia: false,
      showStepThree: false,
      showStepFour: false,
      showStepFive: false,
      dateInput: "",

      sending: false,
      groups: ["Investor", "Partner", "Accelerator", "Advisor", "Start-up"],
      groupsSelect: [],
      blockchain: [
        {
          name: "Ethereum",
          acr: "ETH",
        },
        {
          name: "Polygon",
          acr: "MATIC",
        },
      ],
      categories: [
        "Award",
        "Call",
        "Certification",
        "Creative assets",
        "Code",
        "Course",
        "CVs",
        "Data Room",
        "Deal Flow",
        "Demo",
        "Marketing Sheet",
        "Meeting",
        "Membership",
        "Offline Consultation",
        "Offline Discounts",
        "Offline Merchandise",
        "One-off Training",
        "Online Discounts",
        "Online Merchandise",
        "Other",
        "Pitch Deck",
        "Prize",
        "Project",
        "Project Deliverable",
        "Promotional assets",
        "Reports",
        "Sales Material",
        "Surprise Box",
        "Tech Specs",
        "Tickets",
        "Video",
      ],
      selectedTag: [],
      attachmentCounter: 0,
      selectTag: false,
      enableDuration: true,
      coverSize: false,
      tags: [
        "VC",
        "Investment",
        "Start-up",
        "Fundraising",
        "Pre-seed round",
        "Seed round",
        "Deal flow",
        "Metaverse",
        "Play and Earn",
        "Fashion",
        "Fintech",
        "Data Room",
        "Media Agency",
        "Pitch, Consultant",
        "Lawyer",
        "Architect",
        "Dev",
        "Accounting",
        "Journalist",
        "Press release",
        "Event",
        "Event Manager",
        "Merch",
        "Member Association",
        "Membership",
        "Club",
        "Entry ticket",
        "Training",
        "Course",
        "Creative assets",
        "Video consultation",
        "Discount",
        "Candidates",
        "Partners",
        "Offer",
        "Newsletter",
        "Airdrop",
        "Report",
        "Research",
        "Subscription box",
        "Winner",
        "White List",
        "Other",
      ],
      listCta: [
        "Apply",
        "Book",
        "Unlock",
        "Earn",
        "Partner with us",
        "Discover",
        "Learn",
        "Join",
        "Subscribe",
        "Buy",
      ],
    };
  },
  components: {
    Header,
    Verify,
    Jumbotron,
  },
  methods: {
    // async connect() {
    //   const app = this;
    //   const provider = new WalletConnectProvider({
    //     infuraId: app.infuraId,
    //   });
    //   app.web3 = await new Web3(provider);
    // },
    onNextStepTwo() {
      const app = this;
      if (
        app.selectedCTA != "" &&
        app.selectedTag.length != 0 &&
        app.name != "" &&
        app.description != "" &&
        app.coverUrl != null &&
        !app.coverSize
      ) {
        app.showStepThree = !app.showStepThree;
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 200);
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onNextStepThree() {
      const app = this;
      if (
        (app.selectedAttachments[app.selectedAttachments.length - 1].file ||
          app.selectedAttachments[app.selectedAttachments.length - 1]
            .fileUrl) &&
        app.selectedAttachments[app.selectedAttachments.length - 1].category &&
        app.selectedAttachments[app.selectedAttachments.length - 1].name
      ) {
        app.showStepFour = !app.showStepFour;
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 200);
      }
    },
    onNextStepFour() {
      const app = this;
      if (
        app.selectedChain != "" &&
        app.selectedNumberPassToken != "" &&
        app.price != ""
      ) {
        app.showStepFive = !app.showStepFive;
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 200);
      }
    },
    disableDuration() {
      this.enableDuration = !this.enableDuration;
      if (this.enableDuration == false) {
        document.getElementById("date").classList.add("opacity-50");
        document.getElementById("duration").classList.add("cursor-disable");
        document.getElementById("icon-cal").classList.add("d-none");
      } else {
        document
          .getElementById("date")
          .classList.remove("opacity-50", "cursor-disable");
        document.getElementById("duration").classList.remove("cursor-disable");
        document.getElementById("icon-cal").classList.remove("d-none");
      }
      console.log("click");
    },
    async submitRegister() {
      const app = this;
      if (!app.sending) {
        app.sending = true;

        try {
          // const message = 'Request creating an NFT'
          // const signature = await app.web3.eth.personal.sign(
          //   message,
          //   app.userStore.address
          // );

          const bodyFormData = new FormData();
          const attachmentsData = app.selectedAttachments.map((el) => {
            const transformed = {
              name: el.name,
              category: el.category,
            };
            if (el.file) {
              transformed["ipfs"] = el.ipfs;
              transformed["renderMethod"] = "static";
            } else {
              transformed["fileUrl"] = el.fileUrl;
              transformed["renderMethod"] = "framed";
            }
            return transformed;
          });
          const metadataBody = {
            passTokenUtility: app.utility,
            passTokenName: app.name,
            passTokenDescription: app.description,
            passTokenPersonalTags: app.selectedTag,
            passTokenAttachmentsData: attachmentsData,
            passTokenCTA: app.selectedCTA,
            passTokenNumber: Number(app.selectedNumberPassToken),
            passTokenDuration: app.enableDuration
              ? Math.ceil(new Date(this.duration).getTime() / 1000)
              : "infinite",
            passTokenBlockchain: app.selectedChain.name,
            passTokenPrice: Web3.utils.toWei(app.price.toString(), "ether"),
            passTokenPrivacy: app.passTokenPrivacy,
            passTokenTargetGroups: app.groupsSelect,
          };
          bodyFormData.append("metadata", JSON.stringify(metadataBody));
          // bodyFormData.append("message", message);
          // bodyFormData.append("signature", signature);
          bodyFormData.append("creatorAddress", app.userStore.address);
          bodyFormData.append("passTokenCover", app.selectedNftCover);
          const response = await axios.post(
            app.api + "/nfts/create",
            bodyFormData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          console.log(response);
          window.location.href =
            "#/nft-preview/" +
            response.data.blockchain +
            "/" +
            response.data.creationId;
        } catch (err) {
          console.log("err", err);
          alert("Error:", err);
        }
      } else {
        alert("Error");
      }
    },
    selectAttachmentCategory(attachmentIndex, category) {
      const app = this;
      app.selectedAttachments[attachmentIndex].category = category;
      app.selectedAttachments[attachmentIndex].dropDownOpen = false;
    },
    selectAttachmentName(attachmentIndex, name) {
      const app = this;
      app.selectedAttachments[attachmentIndex].name = name;
    },
    async selectAttachmentFile(attachmentIndex, file) {
      const app = this;

      if (file.size < 21000000) {
        let tmp = [...app.selectedAttachments];
        tmp[attachmentIndex] = {
          ...app.selectedAttachments[attachmentIndex],
          uploadStatus: "pending",
        };
        app.selectedAttachments = tmp;
        try {
          const bodyFormData = new FormData();
          bodyFormData.append("upload", file);
          const response = await axios.post(
            app.api + "/nfts/upload-attachment",
            bodyFormData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          console.log(response.data);

          console.log("ecco il file", file);
          tmp = [...app.selectedAttachments];
          tmp[attachmentIndex] = {
            ...app.selectedAttachments[attachmentIndex],
            file,
            url: URL.createObjectURL(file),
            mimeType: file.type,
            size: file.size,
            ipfs: response.data,
            uploadStatus: "success",
          };
          app.selectedAttachments = tmp;
        } catch (err) {
          tmp = [...app.selectedAttachments];
          tmp[attachmentIndex] = {
            ...app.selectedAttachments[attachmentIndex],
            uploadStatus: "failure",
          };
          app.selectedAttachments = tmp;
          console.log(err);
        }
      } else {
        const tmp = [...app.selectedAttachments];
        tmp[attachmentIndex] = {
          ...app.selectedAttachments[attachmentIndex],
          uploadStatus: "failure",
        };
        app.selectedAttachments = tmp;
      }
    },
    selectAttachmentFileUrl(attachmentIndex, fileUrl) {
      const app = this;
      console.log("fileUrl", fileUrl);
      const tmp = [...app.selectedAttachments];
      tmp[attachmentIndex] = {
        ...app.selectedAttachments[attachmentIndex],
        fileUrl,
        fileUrlValid: this.isAttachmentFileUrlValid(fileUrl),
      };
      app.selectedAttachments = tmp;
    },
    isAttachmentFileUrlValid(fileUrl) {
      return fileUrl.startsWith("https://") || fileUrl.startsWith("http://");
    },
    attachmentHasFile(attachmentIndex) {
      const app = this;
      return (
        app.selectedAttachments[attachmentIndex].file != null &&
        app.selectedAttachments[attachmentIndex].file != undefined
      );
    },
    attachmentHasFileUrl(attachmentIndex) {
      const app = this;
      return (
        app.selectedAttachments[attachmentIndex].fileUrl != null &&
        app.selectedAttachments[attachmentIndex].fileUrl != undefined
      );
    },
    addAttachment() {
      const app = this;
      app.attachmentCounter++;
      app.selectedAttachments = [
        ...app.selectedAttachments,
        {
          name: "",
          category: "",
          dropDownOpen: false,
          id: app.attachmentCounter,
        },
      ];
      console.log(app.selectedAttachments);
    },
    removeAttachment(index) {
      const app = this;
      /* app.selectedAttachments.splice(index, 1)  */
      console.log("index", index);
      console.log(
        "selectedAttachments",
        app.selectedAttachments.splice(index, 1)
      );
    },
    toggleAttachmentCategoryDropdown(attachmentIndex) {
      const app = this;
      app.selectedAttachments[attachmentIndex].dropDownOpen =
        !app.selectedAttachments[attachmentIndex].dropDownOpen;
    },
    numberTokenChange(e) {
      document.getElementById("pass-token").classList.remove("alert");
      this.selectedNumberPassToken = e.target.value;
    },
    selectGroup(group) {
      if (!this.groupsSelect.includes(group)) {
        this.groupsSelect.push(group);
      } else {
        let indexGroup = this.groupsSelect.indexOf(group);
        if (indexGroup !== -1) {
          this.groupsSelect.splice(indexGroup, 1);
        }
        console.log("err");
      }
      console.log("eccoli", this.groupsSelect);
    },
    selectSingleTag(tag) {
      return this.selectedTag.includes(tag);
    },
    toggleTag(tag) {
      if (!this.selectedTag.includes(tag)) {
        if (this.selectedTag.length < 3) {
          this.selectedTag.push(tag);
        } else {
          console.log("err lung");
        }
      } else {
        let index = this.selectedTag.indexOf(tag);
        if (index !== -1) {
          this.selectedTag.splice(index, 1);
        }
        console.log("err");
      }
      var x = document.getElementById("single-tag");
      x.innerHTML = this.selectedTag;
      console.log("tag", this.selectedTag);
      this.selectTag = false;
    },
    /*  0) quando cicli i tag nel v-for se il tag è contenuto nei selezioni viene colorato in un modo altrimenti no */
    /* 1) quando clicco su un tag fa un controllo se dentro l'array ho lo stesso tag. */
    /* 2) se il tag è selezionato lo rimuovo dall'array con uno splice */
    /* 3) se il tag non è selezionato lo inserisce fino ad un massimo di tre tag */
    /* selectUtility(newUtility) {
      this.utility = newUtility;
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 200);
    }, */
    selectPassTokenPrivacy(newPrivacy) {
      this.passTokenPrivacy = newPrivacy;
      console.log("passTk", this.passTokenPrivacy);
    },
    onCtaChange(cta) {
      document.getElementById("cta").classList.remove("alert");
      this.selectedCTA = cta;
      this.selectCTA = false;
    },
    onNftCover(e) {
      document.getElementById("cover-nft").classList.remove("alert");
      this.selectedNftCover = e.target.files[0];
      console.log("selectedNftCover", this.selectedNftCover.size);
      this.coverUrl = URL.createObjectURL(this.selectedNftCover);

      if (this.selectedNftCover.size > 1100000) {
        this.coverSize = true;
      } else {
        this.coverSize = false;
      }
    },
    onNftLogoChange(e) {
      document.getElementById("upload-image").classList.remove("alert");
      this.selectedNftLogo = e.target.files[0];
      this.url = URL.createObjectURL(this.selectedNftLogo);
      console.log(e);
    },
    onNftMediaChange(e) {
      document.getElementById("upload-media").classList.remove("alert");
      this.selectedMediaLogo = e.target.files[0];
      this.media = URL.createObjectURL(this.selectedMediaLogo);
      console.log(e);
    },
    onNftFileChange(e) {
      document.getElementById("upload-file").classList.remove("alert");
      this.selectedFileLogo = e.target.files[0];
      this.file = URL.createObjectURL(this.selectedFileLogo);
      console.log(e);
    },
    onChainChange(chain) {
      document.getElementById("chain").classList.remove("alert");
      this.selectedChain = chain;
      this.selectChain = false;
    },
    setTwoNumberDecimal(value) {
      if (value != 0 && value != "") {
        if (value.toString().split(".")[1].length > 7) {
          this.price = Math.floor(value * 100000) / 100000;
        }
      }
    },
  },
  computed: {
    ...mapStores(useUserStore),
  },
  async mounted() {
    // await this.connect();
    const dateObj = new Date();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const currentDate = dateObj.getFullYear() + "-" + month + "-" + day;
    this.dateInput = currentDate.toString();
  },
};
</script>

<style>
.cursor-disable {
  cursor: not-allowed;
}
.bk {
  background: #0099ffbc !important;
  font-weight: 300;
  font-style: italic;
}
</style>
