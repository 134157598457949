<template>
  <div>
    <Verify v-if="!this.userStore.isConnected" />
    <div v-if="this.userStore.isConnected" class="my-niftyz mt-mob-10">
      <Header />
      <div class="container">
        <div class="row pt-5">
          <div class="col-12 mb-4">
            <a href="/#/create-nft"
              ><i class="fa-solid fa-arrow-left-long"></i> Restart NFT creation
            </a>
          </div>
          <div class="col-12 col-md-8">
            <h2>Pass Token Preview</h2>
          </div>
          <!-- <div
            :class="isMobile ? 'text-star mt-3 mb-3' : 'text-end pe-0'"
            class="col-12 col-md-4"
          >
            <a class="btn-connect" href="/#/my-niftyz/nft">View My Pass Token</a>
          </div> -->
        </div>

        <div v-if="prevNft.metadata" class="row mt-5">
          <div class="col-12 col-md-4 box-collection text-center mb-0">
            <img
              class="preview-image"
              :src="
                'https://ipfs.niftyz.io/ipfs/' +
                prevNft.metadata.image.substring(7)
              "
              alt=""
            />
          </div>
          <div class="col-12 col-md-7 offset-0 offset-md-1 box-collection">
            <!-- description -->
            <div class="d-flex" :class="isMobile ? 'w100 flex-column' : 'w-75'">
              <h6>Description:</h6>
              <p class="ms-3 mt-0 break-word">
                {{ prevNft.metadata.description }}
              </p>
            </div>
            <!-- deadline -->
            <div :class="isMobile ? 'flex-column' : null" class="d-flex">
              <h6>Deadline:</h6>
              <p v-if="duration && duration != ''" class="ms-3 mt-0 break-word">
                {{ duration }}
              </p>
            </div>
            <!-- price -->
            <div :class="isMobile ? 'flex-column' : null" class="d-flex">
              <h6>Price:</h6>
              <p class="ms-3 mt-0 break-word">{{ priceNft }} ETH</p>
            </div>
            <!-- Supply -->
            <div :class="isMobile ? 'flex-column' : null" class="d-flex">
              <h6>NFT Supply:</h6>
              <p class="ms-3 mt-0 break-word">
                {{ supply }}
              </p>
            </div>
            <!-- Sale Type -->
            <div :class="isMobile ? 'flex-column' : null" class="d-flex">
              <h6>Sale Type:</h6>
              <p class="ms-3 mt-0 break-word">
                {{ saleType }}
              </p>
            </div>
            <!-- Preview File -->
            <div
              :class="isMobile ? 'flex-column' : 'align-items-start'"
              class="d-flex flex-column"
            >
              <h6 class="mb-3">Preview Files:</h6>
              <div :class="isMobile ? 'd-flex flex-wrap' : null">
                <!-- <button
                  v-for="(file, index) in media"
                  :key="index"
                  @click="uploadFile(file.id)"
                  class="link-preview"
                >
                  {{ file.name }}
                </button> -->
                <a
                  v-for="(file, index) in media"
                  :key="index"
                  :href="
                    file.ipfs
                      ? 'https://ipfs.niftyz.io/ipfs/' + file.ipfs.substring(7)
                      : file.fileUrl
                  "
                  target="_blank"
                  class="link-preview"
                >
                  {{ file.name }}
                </a>
              </div>
            </div>
            <div
              :class="
                isMobile ? 'justify-content-center' : 'justify-content-end'
              "
              class="d-flex mt-4"
            >
              <button
                v-if="
                  !prevNft.minted &&
                  prevNft.creator == userStore.address &&
                  mintStatus == 'not_sent'
                "
                @click="mintNFT"
                class="btn-connect me-4"
              >
                Mint Now
              </button>
              <button
                @click="showModalDelete"
                v-if="
                  !prevNft.minted &&
                  mintStatus != 'pending' &&
                  mintStatus != 'success'
                "
                class="btn-delete"
              >
                Delete
              </button>
              <!-- MODAL DELETE -->
              <modal name="confirm-delete" class="description-nft text-black">
                <div class="d-flex justify-content-end">
                  <i
                    @click="closeModalDelete()"
                    class="fa-solid fa-circle-xmark p-3"
                  ></i>
                </div>

                <div
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    mt-1
                  "
                >
                  <h3 class="text-buy">
                    Are you sure you want to delete this NFT?
                  </h3>
                  <div class="d-flex mt-2 box-confirmDelete">
                    <button @click="closeModalDelete" class="btn-exit">
                      Close
                    </button>
                    <button @click="deleteNFT" class="btn-delete">
                      Delete
                    </button>
                  </div>
                </div>
              </modal>
              <div
                class="me-4 mt-0 mb-2"
                v-if="mintStatus == 'pending'"
                style="color: white; font-size: 1.5rem"
              >
                <div class="lds-default">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div
                class="me-4 mt-2 mb-2"
                v-if="mintStatus == 'success'"
                style="color: #0099ff; font-size: 1.5rem"
              >
                You successfully minted your NFT!
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 col-md-4 box-collection text-center mb-0">
            <img
              class="preview-image"
              src="../assets/bk/bk-orange.png"
              alt=""
            />
          </div>
          <div
            class="
              col-12 col-md-7
              offset-0 offset-md-1
              box-collection
              summary-plane
            "
          >
            <h5>Summary of your Premium Plan</h5>
            <ul>
              <li>Print and share your NFTs with your community</li>
              <li>Your NFTs will be published on Niftyz.io and Opensea</li>
              <li>Access to Niftyz Perks and rewards & Premium Discord</li>
              <li>
                NFT Academy Certification and earn with our Partners Program
              </li>
              <li>Airdrops (or NFTs giveaway) fees: 0.00005 ETH per NFT</li>
              <li>Minting fees (Premium NFTs): 0%</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapStores } from "pinia";
import Web3 from "web3";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import Verify from "../components/Verify.vue";
import mintingAbi from "../libs/minting_abi.json";
import tokenAbi from "../libs/token_abi.json";
import { useUserStore } from "../stores/userStore";
import { useWeb3Store } from "../stores/web3Store";

export default {
  name: "nftPreview",
  data() {
    return {
      mintingABI: mintingAbi,
      tokenABI: tokenAbi,
      idNft: this.$route.params.id,
      isMobile: false,
      api: process.env.VUE_APP_API_ENDPOINT,
      infuraId: process.env.VUE_APP_INFURA_ID,
      mintingContract: process.env.VUE_APP_CONTRACT_ADDRESS_MINTER,
      tokenContract: process.env.VUE_APP_CONTRACT_ADDRESS_PREMIUM_PT,
      // web3: null,
      prevNft: [],
      duration: "",
      supply: "",
      saleType: "",
      media: [],
      priceNft: "",
      mintStatus: "not_sent",
    };
  },
  components: {
    Header,
    Verify,
    Footer,
  },
  async mounted() {
    const app = this;
    // await app.connect();
    await app.getNft();

    if (app.userStore.address != null) {
      if (app.prevNft && !app.prevNft?.minted) {
        if (!app.nftSynced) {
          app.nftSynced = true;
          console.log("calling sync on mounted");
          await app.checkSyncNFT();
        }
      }
    } else if (!app.nftSynced) {
      const unsubscribe = app.userStore.$onAction(({ name, after }) => {
        after(async () => {
          if (name === "connect") {
            unsubscribe();

            if (app.prevNft && !app.prevNft?.minted) {
              if (!app.nftSynced) {
                app.nftSynced = true;
                console.log("calling sync after mounted");
                await app.checkSyncNFT();
              }
            }
          }
        });
      });
    }

    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
  methods: {
    // async connect() {
    //   const app = this;
    //   let providerOptions = {};
    //   if (app.infuraId !== undefined) {
    //     console.log("Using infuraid:", app.infuraId);
    //     providerOptions = {
    //       walletconnect: {
    //         package: WalletConnectProvider,
    //         options: {
    //           infuraId: app.infuraId,
    //         },
    //       },
    //     };
    //   } else if (!window.ethereum) {
    //     alert("Please install MetaMask to proceed");
    //     return;
    //   }
    //   // Instantiating Web3Modal
    //   const web3Modal = new Web3Modal({
    //     cacheProvider: true,
    //     providerOptions: providerOptions,
    //   });
    //   const provider = await web3Modal.connect();
    //   app.web3 = await new Web3(provider);
    // },

    async mintNFT() {
      const app = this;
      app.mintStatus = "pending";
      try {
        // console.log("token contract", app.tokenContract);
        // console.log("minting contract", app.mintingContract);
        // console.log("minting ABI", app.mintingABI);

        const mintingContract = new app.web3Store.web3.eth.Contract(
          app.mintingABI,
          app.mintingContract
        );

        const supply = app.prevNft.metadata.privateAttributes.find(
          (el) => el.trait_type === "Number"
        ).value;
        let deadline = app.prevNft.metadata.privateAttributes.find(
          (el) => el.trait_type === "Duration"
        ).value;
        if (deadline == "infinite") deadline = 0;
        const price = app.prevNft.price.toString();
        let fee = "0";

        console.log(price);
        // const message = app.prevNft.ipfs;
        // const signature = await app.web3Store.web3.eth.personal.sign(
        //   message,
        //   app.userStore.address
        // );

        if (price == "0") {
          const tokenContract = new app.web3Store.web3.eth.Contract(
            app.tokenABI,
            app.tokenContract
          );
          const costPerFreeMinting = await tokenContract.methods
            .costPerFreeMinting()
            .call();
          fee = (costPerFreeMinting * supply).toString();
        }

        const estimated = await mintingContract.methods
          .mintNFT(
            app.tokenContract,
            app.userStore.user.tokenId,
            app.prevNft.ipfs.substring(7),
            supply,
            price,
            deadline
          )
          .estimateGas({
            from: app.userStore.address,
            value: fee,
          });
        const gasLimit = parseInt(estimated * 1.4).toString();
        const mintRes = await mintingContract.methods
          .mintNFT(
            app.tokenContract,
            app.userStore.user.tokenId,
            app.prevNft.ipfs.substring(7),
            supply,
            price,
            deadline
          )
          .send({
            from: app.userStore.address,
            value: fee,
            gas: gasLimit,
          });

        // console.log(mintRes);

        // console.log('prevNFT:',app.prevNft)
        // console.log('id:',app.idNft)
        const mintServerRes = await axios.post(app.api + "/nfts/mint", {
          id: app.prevNft.creationId,
          tokenId: mintRes.events.Minted.returnValues.tokenId,
          mintTx: mintRes.transactionHash,
          // message,
          // signature,
          smartContract: app.tokenContract,
          mintPrice: price,
          address: this.userStore.address,
        });

        if (mintServerRes.data.error) {
          console.error(mintServerRes.message);
          app.mintStatus = "failure";
        } else {
          console.log("Succcessfully requested to the server");
          app.mintStatus = "success";
          setTimeout(() => {
            window.location.href = "/#/gallery";
          }, 3000);
        }
      } catch (err) {
        app.mintStatus = "not_sent";
        console.log(err);
        alert(err.message);
      }

      // formData.append("message", toSign);
      // formData.append("signature", signature);
    },
    async getNft() {
      const app = this;
      try {
        const response = await axios.get(
          app.api + "/nfts/single-not-minted/" + app.idNft
        );
        app.prevNft = response.data.nft;
        app.priceNft = Web3.utils.fromWei(
          app.prevNft.price.toString(),
          "ether"
        );
        console.log("=====", app.priceNft);
        const dur = app.prevNft.metadata.privateAttributes.find(
          (el) => el.trait_type === "Duration"
        ).value;
        console.log("dur", dur);
        if (dur == "infinite") {
          app.duration = dur;
        } else {
          const tmpDuration = new Date(dur * 1000);
          const month = ("0" + (tmpDuration.getMonth() + 1)).slice(-2);
          app.duration =
            tmpDuration.getDate() +
            "/" +
            month +
            "/" +
            tmpDuration.getFullYear();
        }
        app.supply = app.prevNft.metadata.privateAttributes.find(
          (el) => el.trait_type === "Number"
        )?.value;

        const file = app.prevNft.metadata.media;
        console.log("media", file);
        app.media = file;

        const sale = app.prevNft.metadata.privateAttributes.find(
          (el) => el.trait_type === "Privacy"
        )?.value;
        app.saleType = sale.charAt(0).toUpperCase() + sale.slice(1);
      } catch (err) {
        console.log(err);
      }
      console.log("eccolo", app.prevNft);
    },
    showModalDelete() {
      this.$modal.show("confirm-delete");
      console.log("modaleeee");
    },
    closeModalDelete() {
      this.$modal.hide("confirm-delete");
    },
    async checkSyncNFT() {
      const app = this;
      const mintingContract = new app.web3Store.web3.eth.Contract(
        app.tokenABI,
        app.tokenContract
      );
      console.log("single nft", app.prevNft);
      const checkMinted = await mintingContract.methods
        .passTokens(app.prevNft.ipfs.replace("ipfs://", ""))
        .call();

      // TODO CHECK
      if (checkMinted.id !== "0" && !app.prevNft.minted) {
        try {
          await axios.post(app.api + "/nfts/parse", {
            nftId: app.idNft,
          });
          alert("NFT synced and already minted. Redirecting to My Niftyz...");
          window.location.href = "#/my-niftyz";
        } catch (e) {
          console.log("PARSE_ERROR", e.message);
        }
      } else {
        console.log("Nft is already synced");
      }
    },
    async deleteNFT() {
      const app = this;

      try {
        const toSign = "delete request for NFT no. " + app.prevNft.creationId;
        const signature = await app.web3Store.web3.eth.personal.sign(
          toSign,
          app.userStore.address
        );
        console.log("signature", signature);
        const response = await axios.post(app.api + "/nfts/delete-not-minted", {
          nftId: app.prevNft._id,
          message: toSign,
          signature,
          address: app.userStore.address,
        });
        window.location.href = "#/my-niftyz";
      } catch (err) {
        console.log(err);
      }
    },
  },
  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useWeb3Store),
  },
};
</script>

<style></style>
