<template>
  <div class="verify-container">
    <div>
        <h1>Welcome to Niftyz Platform</h1>
        <h3>Connect your wallet to get started</h3>
        <connect-button ref="connectButton"/>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { mapStores } from 'pinia';
import { useUserStore } from '../stores/userStore';
import ConnectButton from './ConnectButton.vue';
export default {
    name:"Verify",
    components:{
        ConnectButton
    },
    data() {
      return {
        api: process.env.VUE_APP_API_ENDPOINT,
      }
    },
    /* async mounted() {
      if (this.$route.params?.['proof']){
        try {
          await this.$refs.connectButton.connect();
        } catch(err) {
          console.log(err);
        }
      }
    }, */
    computed: {
      ...mapStores(useUserStore),
  },

}
</script>

<style>


</style>