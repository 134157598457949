import axios from "axios";
import { defineStore } from "pinia";
export const useUserStore = defineStore("user", {
  state: () => ({
    isConnected: false,
    isMember: false,
    address: null,
    twitter: null,
    linkedin: null,
    discord: null,
    user: null,
  }),
  actions: {
    connect(payload) {
      this.isConnected = true;
      this.address = payload.address;
      this.isMember = payload.isMember;
      this.fetch();
    },
    login(payload) {
      this.user = payload;
    },
    async fetch() {
      try {
        const buf = Buffer.from(this.address);
        const b64 = buf.toString("base64");
        const user = await axios.get(
          process.env.VUE_APP_API_ENDPOINT + "/user/" + b64
        );
        this.login(user.data.user);
      } catch (err) {
        console.log(err);
      }
    },
    setTwitter(payload) {
      this.twitter = payload;
    },
    setLinkedIn(payload) {
      this.linkedin = payload;
    },
    setDiscord(payload) {
      this.discord = payload;
    },
  },
});
