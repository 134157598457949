<template>
  <div>
    <Header />
    <div class="container myUser">
      <div class="row mt-5">
        <div class="col-12 box">
          <h5>Author not active</h5>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapStores } from "pinia";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import { useUserStore } from "../stores/userStore";
export default {
  name: "authorNotActive",
  data() {
    return {

    };
  },
  components: {
    Header,
    Footer,
  },
  async mounted() {
    const app = this;
   
  },
  methods: {

  },
  computed: {
    ...mapStores(useUserStore),
  },
};
</script>

<style scoped>
.box{
    height: 65vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.box h5{
    font-size: 3rem;
    text-transform: uppercase;
}
</style>>


