<template>
  <div>
    <Header />
    <div class="container myUser">
      <div v-if="myUser" class="row mt-5">
        <div v-if="myUser.profileImage" class="col-12 text-center">
          <img
            class="img-profile"
            :src="
              'https://ipfs.niftyz.io/ipfs/' + myUser.profileImage.substring(7)
            "
            alt=""
          />
          <h3 class="mt-4 mb-1">{{ myUser.twitter }}</h3>
        </div>
      </div>
      <div v-if="myUser" class="row">
        <div class="col-10 col-md-8 offset-1 offset-md-2">
          <p class="text-center">{{ myUser.bio }}</p>
        </div>
      </div>
      <div class="row m-5">
        <div class="col-6 col-md-3">
          <div style="background: #33ba64" class="detail-user">
            <h4>Account Type</h4>
            <p>{{ myUser.accountType }}</p>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div style="background: #651bcc" class="detail-user">
            <h4>Country</h4>
            <p>{{ myUser.country }}</p>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div style="background: #f18819" class="detail-user">
            <h4>Industry</h4>
            <p>{{ myUser.industry }}</p>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div style="background: #ee2737" class="detail-user">
            <h4>Job Title</h4>
            <p>{{ myUser.jobTitle }}</p>
          </div>
        </div>
      </div>
      <div class="container-nft">
        <div class="row">
          <div class="col-12 pt-3">
            <h4>NFTs</h4>
          </div>
        </div>
        <div v-if="myNfts.length >= 1" class="row">
          <div
            v-for="(nft, index) in myNfts"
            :key="index"
            class="col-6 col-md-3"
          >
            <a
              v-if="nft"
              :href="
                '/#/single-nft/' +
                nft.blockchain +
                '/' +
                nft.smartContract +
                '/' +
                nft.encodedSlug
              "
              class="single-nft"
            >
              <div class="single-nft">
                <img
                  :src="
                    'https://ipfs.niftyz.io/ipfs/' +
                    nft.metadata.image.substring(7)
                  "
                  alt=""
                />
                <h6>{{ nft.metadata.name }}</h6>
              </div>
            </a>
          </div>
        </div>
        <div v-if="myNfts.length == 0">
          <div
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              mt-4
            "
          >
            <h3>No Tokens</h3>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import { mapStores } from "pinia";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import { useUserStore } from "../stores/userStore";
export default {
  name: "authorNew",
  data() {
    return {
      api: process.env.VUE_APP_API_ENDPOINT,
      myUser: [],
      slug: this.$route.params.slug,
      walletAddress: "",
      myNfts: [],
    };
  },
  components: {
    Header,
    Footer,
  },
  async mounted() {
    const app = this;
    await app.getAuthor();
    await app.getNft();
  },
  methods: {
    async getAuthor() {
      const app = this;
      try {
        const response = await axios.get(
          app.api + "/user-by-slug/" + encodeURIComponent(app.slug)
        );
        const myUsers = response.data.user;
        app.myUser = myUsers;
        app.walletAddress = myUsers.walletAddress;
        console.log("test", app.walletAddress);

        /* myUsers.forEach((nft) => {
        }); */
        /*  console.log("single", this.myUser); */
      } catch (err) {
        console.log(err);
      }
    },
    async getNft() {
      const app = this;
      if (app.walletAddress != "") {
        try {
          const response = await axios.get(
            app.api + "/nfts/creator-public-for-sale/" + app.walletAddress
          );
          const myNfts = response.data.nfts.map((el) => {
            return {
              ...el,
              encodedSlug: encodeURIComponent(el.slug),
            };
          });
          console.log("=============>>>>>>>>>", myNfts);
          app.myNfts = myNfts;
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
  computed: {
    ...mapStores(useUserStore),
  },
};
</script>

<style></style>
