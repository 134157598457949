<template>
  <div class="mint-wrapper">
    <div v-if="userStore.isConnected">
      <div
        class="d-flex flex-column justify-content-between"
        v-if="this.myReferral != ''"
      >
        <!-- <h4>
          <a href="https://twitter.com/Niftyz_io" target="_blank">
            <img class="pb-1 ms-1" src="../assets/social/twitter.png" alt=""
          /></a>
        </h4> -->
        <h5>Share your referral link with your friends and earn:</h5>
        <div class="d-flex justify-content-center align-items-center">
          <h6 class="mb-0">Referral:</h6>
          <a
            class="ms-1"
            target="_black"
            :href="`${this.domain}#/mint/${this.myReferral}`"
            >{{ `${this.domain}#/mint/${this.myReferral}` }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WalletConnectProvider from "@walletconnect/web3-provider";
import { mapStores } from "pinia";
import abi from "../libs/membership_abi.json";
import { useUserStore } from "../stores/userStore";
import { useWeb3Store } from "../stores/web3Store";
export default {
  name: "ReferralButton",
  data() {
    return {
      ABI: abi,
      contract: process.env.VUE_APP_CONTRACT_ADDRESS_MEMBERSHIP,
      network: process.env.VUE_APP_NETWORK,
      domain: process.env.VUE_APP_LANDING_DOMAIN,
      myReferral: "",
      // web3: "",
      infuraId: process.env.VUE_APP_INFURA_ID,
    };
  },
  async mounted() {
    const app = this;
    await app.getReferral();
  },
  methods: {
    async getReferral() {
      // console.log("start");
      const app = this;
      let providerOptions = {};
      if (app.infuraId !== undefined) {
        console.log("Using infuraid:", app.infuraId);
        providerOptions = {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              infuraId: app.infuraId,
            },
          },
        };
      }
      console.log("prov", providerOptions);
      // const web3Modal = new Web3Modal({
      //   cacheProvider: true,

      //   providerOptions: providerOptions,
      // });
      // const provider = await web3Modal.connect();
      // app.web3 = await new Web3(provider);
      const nftContract = new app.web3Store.web3.eth.Contract(
        app.ABI,
        app.contract
      );
      console.log("contr", nftContract);
      // app.balance = await nftContract.methods.balanceOf(app.account).call();
      // console.log(app.balance);
      const tokens = await nftContract.methods
        .tokensOfOwner(app.userStore.address)
        .call();
      console.log("tokens", tokens);
      app.myReferral = Buffer.from(tokens[0]).toString("base64");
      // const wei = await nftContract.methods.vault(app.account).call();
      // app.vault = app.web3.utils.fromWei(wei, "ether");
      console.log(app.myReferral);
    },
  },
  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useWeb3Store),
  },
  // mounted() {
  //   if (this.$route.params["referral"] !== undefined) {
  //     console.log(
  //       "decoded",
  //       Buffer.from(this.referralToken, "base64").toString("uft-8")
  //     );
  //     this.referralToken = Buffer.from(
  //       this.$route.params["referral"],
  //       "base64"
  //     ).toString();
  //   }
  //   console.log("Referral found:", this.referralToken);
  // },
};
</script>

<style lang="scss" scoped>
.btn {
  display: inline-block;

  line-height: 1.5;
  width: 200px;
  color: white;
  border: none;
  padding: 0.4rem;
  background: transparent linear-gradient(208deg, #6600cc 0%, #0099ff 100%) 0%
    0% no-repeat padding-box;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:hover {
  transition: all 0.45s;
  color: white;
  @keyframes bob {
    0% {
      background: transparent linear-gradient(208deg, #6600cc 0%, #0099ff 90%)
        0% 0% no-repeat padding-box;
    }
    20% {
      background: transparent linear-gradient(208deg, #6600cc 5%, #0099ff 100%)
        0% 0% no-repeat padding-box;
    }
    40% {
      background: transparent linear-gradient(208deg, #6600cc 10%, #0099ff 110%)
        0% 0% no-repeat padding-box;
    }
    60% {
      background: transparent linear-gradient(208deg, #6600cc 15%, #0099ff 120%)
        0% 0% no-repeat padding-box;
    }
    80% {
      background: transparent linear-gradient(208deg, #6600cc 20%, #0099ff 130%)
        0% 0% no-repeat padding-box;
    }
    100% {
      background: transparent linear-gradient(208deg, #6600cc 25%, #0099ff 140%)
        0% 0% no-repeat padding-box;
    }
  }
  animation: bob 0.5s ease-in-out alternate;
  background: transparent linear-gradient(208deg, #6600cc 30%, #0099ff 150%) 0%
    0% no-repeat padding-box;
}
.mint-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mint-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mint-wrapper .fadeIn {
  padding: 0px;
  margin-top: 2rem;
}
.mint-wrapper h3 {
  color: white;
  font-size: 1.5rem;
}
.mint-wrapper p {
  color: #c6c4c4;
}
.fadeIn {
  -webkit-animation: fadeIn 2.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fadeIn 2.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
input {
  font-size: 1.5rem;
  margin: 5px 0;
}
input:focus {
  outline: rgba(0, 0, 0, 0.5) solid 2px;
  -moz-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.8);
}
.m-top-1 {
  margin-top: 3rem;
}
.mint-wrapper {
  text-align: center;
  height: 100%;
  width: 100%;
}
p {
  margin: 0;
}
p.small {
  font-size: 0.9rem;
  font-style: italic;
  color: #7d7d7d;
}
@media screen and (max-width: 767px) {
  .btn {
    width: 170px;
  }
  .mint-wrapper {
    h3 {
      font-size: 0.9rem;
      margin: 0.5rem auto;
    }
  }
  input {
    font-size: 1.2rem;
  }
}
</style>
