import abi from "@/libs/membership_abi.json";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { defineStore } from "pinia";
import Web3 from "web3";
import Web3Modal from "web3modal";
export const useWeb3Store = defineStore("web3", {
  state: () => ({
    web3: null,
    balance: 0,
    account: null,
    connectStatus: 'not_sent',
    networks: {
      ethereum: 1,
      rinkeby: 4,
      goerli: 5,
      polygon: 137,
      mumbai: 80001,
      ganache: 5777,
    },
  }),
  actions: {
    async connect() {
      try{
        this.connectStatus == 'pending'
        const network = process.env.VUE_APP_NETWORK;
        const infuraId = process.env.VUE_APP_INFURA_ID;
        let providerOptions = {};
        if (infuraId !== undefined) {
          providerOptions = {
            walletconnect: {
              package: WalletConnectProvider,
              options: {
                infuraId,
              },
            },
          };
        } else if (!window.ethereum) {
          alert("Please install MetaMask to proceed");
          this.connectStatus == 'failure'
          return;
        }
        // Instantiating Web3Modal
        const web3Modal = new Web3Modal({
          cacheProvider: true,
          providerOptions: providerOptions,
        });
        const provider = await web3Modal.connect();
        const web3 = await new Web3(provider);
  
        // Checking if networkId matches
        const netId = await web3.eth.net.getId();
        if (parseInt(netId) !== this.networks[network]) {
          alert("Switch to " + network + " network!");
          this.connectStatus == 'failure'

        } else {
          this.web3 = web3;
          const accounts = await this.web3.eth.getAccounts();
          if (accounts.length > 0) {
            this.account = accounts[0];
          }                
          await this.getBalance();  
          this.connectStatus == 'success'     
        }
      }catch(err){
        this.connectStatus == 'failure'
        console.log(err.messagge??err);
      }
    },
    async getBalance() {
      const app = this;
      const accounts = await app.web3.eth.getAccounts();
      if (accounts.length > 0) {
        const account = accounts[0];
        const contract = process.env.VUE_APP_CONTRACT_ADDRESS_MEMBERSHIP;
        const membershipContractInstance = new app.web3.eth.Contract(
          abi,
          contract
        );
        const balance = await membershipContractInstance.methods
          .balanceOf(account)
          .call();
        console.log("balance: ", balance);
        app.balance = balance;
      }
    },
  },
});
