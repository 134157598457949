<template>
  <div>
    <Verify v-if="!this.userStore.isConnected" />
    <div v-if="this.userStore.isConnected" class="my-niftyz mt-mob-10">
      <Header />
      <div v-if="this.userStore.isMember">
        <Jumbotron />
      </div>
      <div class="sub-nav">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <ul class="p-0">
                <li
                  @click="active = 'profile'"
                  :class="{ 'li-active': active === 'profile' }"
                >
                  <span :class="isMobile ? 'd-none' : null">My </span>Niftyz
                  Badge
                </li>
                <li
                  @click="active = 'details'"
                  :class="{ 'li-active': active === 'details' }"
                >
                  <span :class="isMobile ? 'd-none' : null">My </span>Details
                </li>

                <li
                  @click="active = 'nft'"
                  :class="{ 'li-active': active === 'nft' }"
                >
                  <span :class="isMobile ? 'd-none' : null">My </span>Pass
                  Tokens
                </li>
                <li
                  @click="active = 'stats'"
                  :class="{ 'li-active': active === 'stats' }"
                >
                  <span :class="isMobile ? 'd-none' : null">My </span>Stats
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div v-if="active == 'profile'" class="container">
        <div class="row mt-5">
          <div
            :class="{ 'ps-0 mb-2': !isMobile }"
            class="col-12 col-md-6 col-lg-7"
          >
            <div class="box-nft">
              <div class="box-image">
                <div class="box-share">
                  <p>share</p>
                  <ul>
                    <li>
                      <ShareNetwork
                        network="twitter"
                        :url="shareLink"
                        title="I've just minted my NFT Business Identity with Niftyz.io, the leading NFT platform for businesses! Turn your documents, connections, know-how and more into tradable assets in three easy steps - Upload, Mint and Earn with Niftyz."
                      >
                        <i class="fa-brands fa-twitter"></i>
                      </ShareNetwork>
                    </li>
                    <li>
                      <ShareNetwork
                        network="linkedin"
                        :url="shareLink"
                        title="I've just minted my NFT Business Identity with Niftyz.io, the leading NFT platform for businesses! Turn your documents, connections, know-how and more into tradable assets in three easy steps - Upload, Mint and Earn with Niftyz."
                      >
                        <i class="fa-brands fa-linkedin-in"></i>
                      </ShareNetwork>
                    </li>
                    <div
                      v-if="this.userStore"
                      class="d-flex align-items-center"
                    >
                      <li class="mb-0" @click="copy()">
                        <i class="fa-sharp fa-solid fa-share share"></i>
                      </li>
                      <span v-if="this.showCopy" class="mb-0 ms-3"
                        >Copied
                        <i style="color: green" class="fa-solid fa-check"></i
                      ></span>
                    </div>
                  </ul>
                </div>
                <div class="img-badge">
                  <img
                    v-if="userStore.user && userStore.user.profileImage != null"
                    :src="
                      'https://ipfs.niftyz.io/ipfs/' +
                      userStore.user.badgeImage.substring(7)
                    "
                    alt=""
                  />
                </div>
              </div>
              <h5 class="sub-title">Your Niftyz badge</h5>
            </div>
          </div>
          <div
            :class="{ 'pe-0 mb-2': !isMobile }"
            class="
              col-12 col-md-6 col-lg-5
              d-flex
              flex-column
              justify-content-between
            "
          >
            <div class="box-collection">
              <h5 class="sub-title">Create NFT Pass Tokens</h5>
              <p>
                Start monetizing your business assets now! Turn pitch decks,
                proposals, events, memberships, and more into tradable NFTs.
              </p>
              <a class="btn-connect" href="/#/create-nft">Start NFT</a>
            </div>
            <div class="box-collection">
              <h5 class="sub-title">Get in touch with us</h5>
              <p>
                If you have an interesting idea you would like to share or
                discuss, post it on our ‘Business Case’ discord channel.
              </p>
              <a
                class="btn-my-niftyz mb-2"
                href="https://discord.com/invite/ZcCxqjwaWZ"
                target="_blank"
                >JOIN DISCORD</a
              >
              <a
                class="btn-my-niftyz"
                href="https://api.niftyz.io/oauth/discord"
                >GET DISCORD ROLE</a
              >
            </div>
          </div>
        </div>
        <div class="row mt-5 box-information">
          <div class="col-12 col-md-7">
            <h3>Refer and Earn</h3>
            <div class="referral-programme">
              <img src="../assets/perks_icon_1.0.png" alt="" />
              <div class="description-referral">
                <h6>Become a Certified Niftyz Partner</h6>
                <p>
                  Refer and Earn on new Premium Account referrals by sharing
                  your referral link with friends! To maximize your potential,
                  become a Certified Niftyz Partner by completing our NFT
                  Academy available on our Discord Channel and earning an NFT
                  Certification Badge.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5">
            <referral-button />
          </div>
        </div>
      </div>
      <div v-if="active == 'details'" class="container">
        <Form :isRegistered="true" />
      </div>
      <div v-if="active == 'nft'" class="container">
        <div class="row justify-content-between align-items-center mt-5 mb-4">
          <div class="col-12 col-md-8">
            <h2 class="mb-3">My Pass Tokens</h2>
            <p>
              Create and manage your unique Pass Tokens and share them with
              other users.
            </p>
          </div>
          <div
            :class="isMobile ? 'text-star mt-2 mb-2' : 'text-end'"
            class="col-12 col-md-4"
          >
            <a class="btn-connect" href="/#/create-nft">Create Pass Token</a>
          </div>
        </div>
        <div class="row container-nft m-auto">
          <div class="col-12 col-md-4 pt-3 pe-0">
            <h4>My Pass Tokens</h4>
          </div>
          <div
            :class="isMobile ? 'ps-0 pe-0 text-center' : 'pt-3 ps-0'"
            class="col-12 col-md-8"
          >
            <div
              :class="
                isMobile ? 'justify-content-around flex-column' : 'justify-content-end'
              "
              class="d-flex"
            >
              <div
                :class="showNfts == 'created' ? 'active' : null"
                class="box-filter"
                @click="onClickShowButton('created')"
              >
                Created Pass Tokens
              </div>
              <div
                :class="showNfts == 'sold' ? 'active' : null"
                class="box-filter"
                @click="onClickShowButton('sold')"
              >
                Sold Pass Tokens
              </div>
              <!-- da inserire nel resp.scss -->
              <!-- :class="isMobile ? 'mb-3 text-center' : null" -->
              <div
                :class="showNfts == 'purchased' ? 'active' : null"
                class="box-filter"
                @click="onClickShowButton('purchased')"
              >
                Bought Pass Tokens
              </div>
             
            </div>
          </div>
          <h5 class="text-center" v-if="myNft.length == 0">
            You don't have any Pass Token yet!
          </h5>
          <div
            v-for="(nft, index) in nftsToShow"
            :key="index"
            :class="isMobile ? 'mb-0 text-center' : null"
            class="col-10 col-sm-6 col-md-3 offset-1 offset-sm-0 offset-md-0"
          >
            <a
              :href="
                !nft.minted
                  ? '/#/draft-nft/' + nft.blockchain + '/' + nft.creationId
                  : '/#/single-nft/' +
                    nft.blockchain +
                    '/' +
                    nft.smartContract +
                    '/' +
                    nft.slug
              "
              class="link-preview"
            >
              <div class="single-nft">
                <!-- AGGIUNGERE CONDIZIONE PER DRAFT -->
                <div v-if="!nft.minted" class="over-draft">
                  <p>DRAFT</p>
                </div>
                <img
                  :src="
                    'https://ipfs.niftyz.io/ipfs/' +
                    nft.metadata.image.substring(7)
                  "
                  alt=""
                />
                <h6>{{ nft.metadata.name }}</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div v-if="active == 'stats'" class="container myUser">
        <div
          :class="isMobile ? 'm-0 ps-0 pe-0' : 'm-5 ps-1 pe-1'"
          class="row pt-1 pb-5"
        >
          <div class="col-12 mb-5">
            <h3>My Stats</h3>
          </div>
          <div class="col-6 col-sm-6 col-md-3">
            <div style="background: #33ba64" class="detail-user">
              <h4 class="text-center">NFTs Created</h4>
              <p>{{ myStats.nftsCreated }}</p>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-3">
            <div style="background: #651bcc" class="detail-user">
              <h4 class="text-center">NFTs Draft</h4>
              <p>{{ myStats.nftsDraft }}</p>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-3">
            <div style="background: #f18819" class="detail-user">
              <h4 class="text-center">Buyers Count</h4>
              <p>{{ myStats.buyersCount }}</p>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-3">
            <div style="background: #ee2737" class="detail-user">
              <h4 class="text-center">Total Revenue</h4>
              <p>{{ totalRevenue }} ETH</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapStores } from "pinia";
import Web3 from "web3";
import Footer from "../components/Footer.vue";
import Form from "../components/Form.vue";
import Header from "../components/Header.vue";
import Jumbotron from "../components/Jumbotron.vue";
import ReferralButton from "../components/ReferralButton.vue";
import Verify from "../components/Verify.vue";
import { useUserStore } from "../stores/userStore";
export default {
  name: "myNiftyz",
  data() {
    return {
      active: "profile",
      infuraId: process.env.VUE_APP_INFURA_ID,
      isMobile: false,
      supply: null,
      isRegister: true,
      api: process.env.VUE_APP_API_ENDPOINT,
      testingCode: "1234",
      myNft: [],
      showCopy: false,
      nftFetchStatus: "not_fetched",
      domain: process.env.VUE_APP_LANDING_DOMAIN,
      currentDomain: process.env.VUE_APP_PLATFORM_DOMAIN,
      shareLink: "",
      showNfts: "all" /* all/purchased/created */,
      nftsToShow: [],
      myStats: [],
      totalRevenue: "",
    };
  },
  components: {
    Header,
    Jumbotron,
    Verify,
    Footer,
    Form,
    ReferralButton,
  },

  async mounted() {
    const app = this;
    await this.getUser();
    const routerTab = this.$route.params["tab"];
    if (
      routerTab == "nft" ||
      routerTab == "stats" ||
      routerTab == "profile" ||
      routerTab == "details"
    ) {
      app.active = routerTab;
    }
    if (this.userStore.isConnected) {
      this.shareLink =
        this.currentDomain + "#/author/" + this.userStore.user._id;
      await this.getNft();
    } else {
      this.userStore.$onAction(({ name, store, after }) => {
        after(async () => {
          if (name === "login") {
            this.shareLink =
              this.currentDomain + "#/author/" + this.userStore.user._id;
            await this.getNft();
          }
        });
      });
    }

    if (window.innedrWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
    console.log("ZZZZZZZ", window.location.href);
  },

  methods: {
    onClickShowButton(payload) {
      if (this.showNfts == payload) {
        this.showNfts = "all";
      } else {
        this.showNfts = payload;
      }
      this.getNftsToShow();
    },
    getNftsToShow() {
      if (this.showNfts == "purchased") {
        this.nftsToShow = this.myNft.filter(
          (el) => el.creator != this.userStore.address
        );
        console.log('alcuni',this.myNft);
      } else if (this.showNfts == "created") {
        this.nftsToShow = this.myNft.filter(
          (el) => el.creator == this.userStore.address
        );
        console.log("eccoli tutti", this.nftsToShow);
      }
       else if (this.showNfts == "sold") {
        this.nftsToShow = this.myNft.filter(
          (el) => el.creator == this.userStore.address && el?.owners && el?.owners?.length > 0
           
        );
        
        console.log("eccoli tutti", this.nftsToShow );
      }
       else {
        this.nftsToShow = this.myNft;
      }
    },
    copy() {
      if (this.userStore) {
        try {
          navigator.clipboard.writeText(this.shareLink);
          this.showCopy = true;
          setTimeout(() => {
            this.showCopy = false;
          }, 2000);
        } catch (e) {
          console.log(e);
        }
      }
    },
    async getNft() {
      const app = this;

      try {
        app.nftFetchStatus = "pending";
        const response = await axios.get(
          app.api + "/nfts/owned-and-created/" + this.userStore.address
        );
        const myNfts = response.data.nfts;

        myNfts.forEach((nft) => {
          const supply = nft.metadata.privateAttributes.find(
            (el) => el.trait_type === "Number"
          ).value;
          app.supply = supply;
          this.myNft.push(nft);
        });
        app.getNftsToShow();
        app.nftFetchStatus = "success";
      } catch (err) {
        app.nftFetchStatus = "failure";
        console.log(err);
      }
    },
    async getUser() {
      const app = this;

      try {
        app.nftFetchStatus = "pending";
        const buf = Buffer.from(this.userStore.address);
        const b64 = buf.toString("base64");
        const response = await axios.get(app.api + "/user/stats/" + b64);
        const myUser = response.data.stats;
        console.log("eccoli stats", myUser);
        app.myStats = myUser;
        (this.totalRevenue = Web3.utils.fromWei(
          app.myStats.totalRevenue.toString(),
          "ether"
        )),
          (app.nftFetchStatus = "success");
      } catch (err) {
        app.nftFetchStatus = "failure";
        console.log(err);
      }
    },
  },
  computed: {
    ...mapStores(useUserStore),
  },
};
</script>

<style></style>
