var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{attrs:{"id":"head"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4 order-0 order-md-1",class:{ 'justify-content-between flex-column': _vm.isMobile }},[_c('div',{staticClass:"text-center",class:{ 'd-flex justify-content-between w-100': _vm.isMobile }},[_vm._m(0),(_vm.isMobile)?_c('div',{staticClass:"navbar-toggle d-flex align-items-center"},[_c('div',{on:{"click":function($event){return _vm.collapseMenu()}}},[(!_vm.showMenuMobile)?_c('i',{staticClass:"fa-solid fa-bars main-color"}):_vm._e(),(_vm.showMenuMobile)?_c('i',{staticClass:"fa-solid fa-xmark"}):_vm._e()])]):_vm._e()])]),_c('div',{staticClass:"col-0 col-md-4 order-1 order-md-0",class:_vm.isMobile ? 'p-0' : null},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMenuContent || _vm.showMenuMobile),expression:"showMenuContent || showMenuMobile"}],staticClass:"nav-mob box-navigation"},[_c('ul',{class:{ 'flex-column': _vm.isMobile }},[(
                  _vm.userStore.isConnected &&
                  _vm.userStore.user?.city != null &&
                  _vm.userStore.user?.city != undefined
                )?_c('li',[_c('a',{attrs:{"href":"/#/my-niftyz"}},[_vm._v("My Niftyz")])]):_vm._e(),_vm._m(1),(_vm.isMobile)?_c('li',[_c('div',{staticClass:"box-profile"},[_c('div',{staticClass:"img-profile"},[(
                        !_vm.userStore.user || _vm.userStore.user.profileImage == null
                      )?_c('img',{attrs:{"src":require("../assets/icon_colour.svg"),"alt":""}}):_vm._e(),(
                        _vm.userStore.user && _vm.userStore.user.profileImage != null
                      )?_c('img',{attrs:{"src":'https://ipfs.niftyz.io/ipfs/' +
                        _vm.userStore.user.profileImage.substring(7),"alt":""}}):_vm._e()]),_c('div',{staticClass:"date-profile ms-4 mt-1"},[_c('p',[_vm._v("CONNECTED")]),_c('p',{staticClass:"address"},[_vm._v(_vm._s(_vm.subStringAddress))])])])]):_vm._e()])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMenuContent || _vm.showMenuMobile),expression:"showMenuContent || showMenuMobile"}],staticClass:"col-0 col-md-4 order-2"},[(_vm.userStore.isConnected)?_c('div',{staticClass:"box-profile"},[(_vm.userStore.user)?_c('a',{attrs:{"href":'/#/author/' + _vm.userStore.user._id}},[_c('div',{staticClass:"img-profile"},[(_vm.userStore.user.profileImage == null)?_c('img',{attrs:{"src":require("../assets/icon_colour.svg"),"alt":""}}):_vm._e(),(_vm.userStore.user.profileImage != null)?_c('img',{attrs:{"src":'https://ipfs.niftyz.io/ipfs/' +
                    _vm.userStore.user.profileImage.substring(7),"alt":""}}):_vm._e()])]):_c('div',{staticClass:"img-profile"},[_c('img',{attrs:{"src":require("../assets/icon_colour.svg"),"alt":""}})]),_c('div',{staticClass:"date-profile"},[_c('p',[_vm._v("CONNECTED")]),_c('p',{staticClass:"address"},[_vm._v(_vm._s(_vm.subStringAddress))])])]):_c('div',{staticClass:"box-profile"},[_c('connect-button',{ref:"connectButton"})],1)])])])]),(_vm.isTestnet)?_c('div',{staticClass:"container-fluid temporary-banner"},[_vm._m(2)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"box-logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("../assets/icon_colour.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/#/gallery"}},[_vm._v("Gallery")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('p',[_c('i',{staticClass:"fa-solid fa-triangle-exclamation me-1"}),_vm._v(" This is test version of the platform, go to "),_c('a',{attrs:{"href":"https://niftyz.io/"}},[_vm._v("https://niftyz.io/")]),_vm._v(" to see real data. ")])])])
}]

export { render, staticRenderFns }