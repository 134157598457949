<template>
  <div>
    <header id="head">
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-12 col-md-4 order-0 order-md-1"
            :class="{ 'justify-content-between flex-column': isMobile }"
          >
            <div
              class="text-center"
              :class="{ 'd-flex justify-content-between w-100': isMobile }"
            >
              <a class="box-logo" href="/">
                <img src="../assets/icon_colour.svg" alt="" />
              </a>
              <div
                v-if="isMobile"
                class="navbar-toggle d-flex align-items-center"
              >
                <div @click="collapseMenu()">
                  <i
                    v-if="!showMenuMobile"
                    class="fa-solid fa-bars main-color"
                  ></i>
                  <i v-if="showMenuMobile" class="fa-solid fa-xmark"></i>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-0 col-md-4 order-1 order-md-0"
            :class="isMobile ? 'p-0' : null"
          >
            <div
              class="nav-mob box-navigation"
              v-show="showMenuContent || showMenuMobile"
            >
              <ul :class="{ 'flex-column': isMobile }">
                <li
                  v-if="
                    userStore.isConnected &&
                    userStore.user?.city != null &&
                    userStore.user?.city != undefined
                  "
                >
                  <a href="/#/my-niftyz">My Niftyz</a>
                </li>
                <li>
                  <a href="/#/gallery">Gallery</a>
                </li>
                <!-- <li>
                  <a href="">Resources</a>
                </li> -->
                <li v-if="isMobile">
                  <div class="box-profile">
                    <div class="img-profile">
                      <img
                        v-if="
                          !userStore.user || userStore.user.profileImage == null
                        "
                        src="../assets/icon_colour.svg"
                        alt=""
                      />
                      <img
                        v-if="
                          userStore.user && userStore.user.profileImage != null
                        "
                        :src="
                          'https://ipfs.niftyz.io/ipfs/' +
                          userStore.user.profileImage.substring(7)
                        "
                        alt=""
                      />
                    </div>
                    <div class="date-profile ms-4 mt-1">
                      <p>CONNECTED</p>
                      <p class="address">{{ subStringAddress }}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div
            v-show="showMenuContent || showMenuMobile"
            class="col-0 col-md-4 order-2"
          >
            <div v-if="userStore.isConnected" class="box-profile">
              <a
                v-if="userStore.user"
                :href="'/#/author/' + userStore.user._id"
              >
                <div class="img-profile">
                  <img
                    v-if="userStore.user.profileImage == null"
                    src="../assets/icon_colour.svg"
                    alt=""
                  />
                  <img
                    v-if="userStore.user.profileImage != null"
                    :src="
                      'https://ipfs.niftyz.io/ipfs/' +
                      userStore.user.profileImage.substring(7)
                    "
                    alt=""
                  />
                </div>
              </a>

              <div v-else class="img-profile">
                <img src="../assets/icon_colour.svg" alt="" />
              </div>
              <div class="date-profile">
                <p>CONNECTED</p>
                <p class="address">{{ subStringAddress }}</p>
              </div>
            </div>
            <div v-else class="box-profile">
              <connect-button ref="connectButton" />
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="d-flex justify-content-between w-100" v-if="!isMobile">
        <div class="box-navigation">
          <ul>
            <li>
              <a href="">My Niftyz</a>
            </li>
            <li>
              <a href="">Markertplace</a>
            </li>
            <li>
              <a href="">Resources</a>
            </li>
          </ul>
        </div>
        <div class="box-logo">
          <img src="../assets/logo.svg" alt="" />
        </div>
        <div class="box-profile">
          <div class="img-profile">
            <img width="35px" src="../assets/logo.svg" alt="" />
          </div>
          <div class="date-profile">

            <p>CONNECTED</p>
            <p class="address">{{ subStringAddress }}</p>
          </div>
        </div>
      </div> -->
      <!-- <div v-if="isMobile">
        <div class="img-profile">
          <img width="45px" src="../assets/logo.svg" alt="" />
        </div>
        <Slide right>
          <a href="#">
            <span>My Niftyz</span>
          </a>
          <a href="#">
            <span>Markertplace</span>
          </a>
          <a href="#">
            <span>Resources</span>
          </a>
          <div class="date-profile d-flex flex-column">
            <p>CONNECTED</p>
            <p class="address">{{ subStringAddress }}</p>
          </div>
        </Slide>
      </div> -->
    </header>
    <div v-if="isTestnet" class="container-fluid temporary-banner">
      <div class="row">
        <div class="col-12">
          <p>
            <i class="fa-solid fa-triangle-exclamation me-1"></i> This is test
            version of the platform, go to
            <a href="https://niftyz.io/">https://niftyz.io/</a> to see real
            data.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* import { Slide } from "vue-burger-menu"; */
import { mapStores } from "pinia";
import { useUserStore } from "../stores/userStore";
import ConnectButton from "./ConnectButton.vue";
/* import ConnectButton from './ConnectButton.vue'; */

export default {
  name: "Header",
  data() {
    return {
      isMobile: false,
      showBtn: true,
      showMenuContent: true,
      showMenuMobile: false,
      isTestnet: process.env.VUE_APP_ENVIRONMENT == "development",
    };
  },
  components: {
    ConnectButton,
  },
  computed: {
    ...mapStores(useUserStore),
    subStringAddress() {
      const firstAddress = this.userStore?.address?.substring(0, 6) ?? "";
      const lastAddress =
        this.userStore?.address?.substring(
          this.userStore.address.length - 4,
          this.userStore.address.length
        ) ?? "";
      return `${firstAddress}...${lastAddress}`;
    },
  },
  mounted() {
    const app = this;
    if (window.innerWidth < 767) {
      app.showMenuContent = false;
      app.showMenuMobile = false;
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 768) {
        app.showBtn = true;
        app.showMenuContent = true;
        app.showMenuMobile = false;
        app.isMobile = false;
      } else {
        app.showBtn = false;
        app.showMenuContent = false;
        app.showMenuMobile = false;
        app.isMobile = true;
      }
    });
  },
  methods: {
    collapseMenu() {
      const app = this;
      app.showBtn = !app.showBtn;
      app.showMenuMobile = !app.showMenuMobile;
      console.log("clicj");
    },
  },
};
</script>

<style></style>
