import "@/themes/style.scss";
import "@/themes/resp.scss";
import { createPinia, PiniaVuePlugin } from "pinia";
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import VModal from "vue-js-modal";
import { routes } from "./router";

import VueSocialSharing from "vue-social-sharing";

Vue.use(VueSocialSharing);
Vue.use(VModal);
Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(VueRouter);
const router = new VueRouter({
  routes,
});

new Vue({
  pinia,
  router,
  render: (h) => h(App),
}).$mount("#app");

// const userStore = useUserStore();
// userStore.$subscribe((mutation, state) => {
//   console.log("userStore", state);
// });
