<template>
  <footer>
    <div class="container">
      <div class="row align-items-center top-footer">
        <div class="col-12 col-md-6">
          <div
            :class="isMobile ? 'justify-content-center me-0 mb-2' : 'me-3'"
            class="d-flex align-items-center"
          >
            <img
              style="width: 140px"
              class="me-2"
              src="../assets/logo-text.svg"
              alt=""
            />
          </div>
        </div>
       <!--  <div class="col-12 col-md-4 text-center router-page">
          <a href="#/about">Partners Programme</a>
          <a
            href="https://www.notion.so/niftyz/Niftyz-io-White-Paper-e1910ba268824ab585777c8b9612169f"
            target="_blank"
            >About</a
          >
        </div> -->
        <div class="col-12 col-md-6">
          <ul
            :class="
              isMobile
                ? 'justify-content-center mt-3 mb-2 p-0'
                : 'justify-content-end'
            "
            class="d-flex m-0 social-footer"
          >
            <li class="me-4">
              <a href="https://twitter.com/niftyz_io" target="_blank"
                ><img src="../assets/social/twitter.png" alt=""
              /></a>
            </li>
            <li class="me-4">
              <a href=" https://www.linkedin.com/company/niftyz/" target="_blank">
                <img src="../assets/social/linkedin.png" alt="" /></a>
            </li>
            <li class="me-4">
              <a href="https://discord.gg/ZcCxqjwaWZ"  target="_blank"><img src="../assets/social/discord.png" alt="" /></a>
            </li>
            <!-- <li class="">
              <a href=""><img src="../assets/social/medium.png" alt="" /></a>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="row bottom-footer">
        <div class="col-8 col-md-6">
          <p>© 2022 NIFTYZ. All right reserved.</p>
        </div>
        <div class="col-4 col-md-6 text-end link-footer">
          <a href="Terms_of_Service.pdf" target="_blank">Terms of Service</a>
          <a href="https://niftyz.io/Privacy_Policy.pdf" target="_blank">Privacy Policy</a>
        </div>
      </div>
    </div>
    <div @click="scrollUp()" class="arrow-up">
      <i class="fa-solid fa-arrow-up"></i>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    scrollUp() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    const app = this;
    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
};
</script>

<style></style>
