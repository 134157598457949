<template>
  <div class="register single-step">
    <div class="row mt-4 mb-4">
      <div v-if="!isRegistered" class="col-12">
        <h4>
          <i
            class="fa-solid"
            :class="isUserComplete ? 'fa-circle-check' : 'fa-circle-xmark'"
          ></i>
          Step 3 - Enter Details
        </h4>
      </div>
    </div>
    <div class="row test-form">
      <div class="col-12 col-md-6">
        <div class="custom-select">
          <h5>Select Country</h5>
          <div class="">
            <div
              id="country"
              @click="selectCountry = !selectCountry"
              class="focus-input"
            >
              <p
                :class="selectedCountry == '' ? 'opacity-75' : 'text-white'"
                v-if="!isUserComplete"
              >
                {{ selectedCountry == "" ? "Select Country" : selectedCountry }}
              </p>
              <p
                :class="selectedCountry == '' ? null : 'text-white'"
                v-if="isUserComplete"
              >
                {{ selectedCountry == "" ? userDate.country : selectedCountry }}
              </p>
              <i
                class="fa-solid"
                :class="selectCountry ? 'fa-chevron-up' : 'fa-chevron-down'"
              ></i>
            </div>
            <ul v-if="selectCountry">
              <li>
                <input
                  class="searchBox"
                  type="text"
                  name="search"
                  placeholder="Search your Country"
                  v-model="searchCountry"
                />
              </li>
              <li
                v-for="(country, index) in filteredCountry"
                :key="index"
                :value="country.value"
                @click="onCountryChange(country)"
              >
                {{ country.value }}
              </li>
            </ul>
          </div>
        </div>
        <div class="custom-select">
          <h5>Select City</h5>
          <div class="">
            <div
              id="city"
              @click="selectCity = !selectCity"
              class="focus-input"
            >
              <p
                :class="selectedCity == '' ? 'opacity-75' : 'text-white'"
                v-if="!isUserComplete"
              >
                {{ selectedCity == "" ? "Select City" : selectedCity }}
              </p>
              <p
                :class="selectedCity == '' ? null : 'text-white'"
                v-if="isUserComplete"
              >
                {{ selectedCity == "" ? userDate.city : selectedCity }}
              </p>
              <i
                class="fa-solid"
                :class="selectCity ? 'fa-chevron-up' : 'fa-chevron-down'"
              ></i>
            </div>
            <p
              class="er-city"
              v-if="!isRegistered && selectCity && cities.length < 1"
            >
              <i class="fa-solid fa-circle-exclamation"></i> Select a country
            </p>
            <ul v-if="selectCity && cities.length > 0">
              <li>
                <input
                  class="searchBox"
                  type="text"
                  name="search"
                  placeholder="Search your Country"
                  v-model="searchCity"
                />
              </li>
              <li
                v-for="(city, index) in filteredCity"
                :key="index"
                :value="city.value"
                @click="onCityChange(city)"
              >
                {{ city.value }}
              </li>
            </ul>
          </div>
        </div>
        <div class="custom-select">
          <h5>Your Industry</h5>
          <div class="">
            <div
              id="industry"
              @click="selectIndustry = !selectIndustry"
              class="focus-input"
            >
              <p
                :class="selectedIndustry == '' ? 'opacity-75' : 'text-white'"
                v-if="!isUserComplete"
              >
                {{
                  selectedIndustry == "" ? "Select Industry" : selectedIndustry
                }}
              </p>
              <p
                :class="selectedIndustry == '' ? 'opacity-75' : 'text-white'"
                v-if="isUserComplete"
              >
                {{
                  selectedIndustry == "" ? userDate.industry : selectedIndustry
                }}
              </p>
              <i
                class="fa-solid"
                :class="selectIndustry ? 'fa-chevron-up' : 'fa-chevron-down'"
              ></i>
            </div>
            <ul v-if="selectIndustry">
              <li
                v-for="(industry, index) in industries"
                :key="index"
                @click="onIndustryChange(industry)"
              >
                {{ industry }}
              </li>
            </ul>
          </div>
        </div>
        <div class="custom-select">
          <h5>Job Title</h5>
          <div class="">
            <div id="title" class="focus-input">
              <input
                @change="onTitleChange"
                type="text"
                :placeholder="
                  userDate.jobTitle ? userDate.jobTitle : 'Enter Your Job Title'
                "
                :class="userDate.jobTitle == '' ? 'opacity-75' : 'text-white'"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="custom-select">
          <h5>Name</h5>
          <div class="name-social">
            <p v-if="this.userStore.twitter">@{{ this.userStore?.twitter }}</p>
            <p v-if="this.userStore.linkedin && !this.userStore.twitter">
              @{{ this.userStore?.linkedin }}
            </p>
            <i class="fa-solid fa-lock"></i>
          </div>
        </div>
        <div class="custom-select">
          <h5 style="position: relative">
            Email
            <i class="fa-solid fa-circle-info ms-1"> </i>
            <span class="ms-3 number-characters"
              >Receive an email notification when someone purchases your NFTs.
              We will never send you promotional material</span
            >
          </h5>
          <div class="">
            <div id="email" class="focus-input">
              <input
                @change="onEmailChange"
                type="email"
                v-model="email"
                :placeholder="
                  userDate.email ? userDate.email : 'Enter your email'
                "
              />
            </div>
            <span v-show="wrongEmail" style="color: red"
              >Incorrect email address</span
            >
          </div>
        </div>
        <div class="custom-select">
          <h5>
            Bio
            <i
              style="position: relative; top: -5px"
              class="fa-solid fa-circle-info"
            >
            </i>
            <span class="ms-3 number-characters">Max 220 characters</span>
          </h5>
          <div class="">
            <div id="bio" class="focus-input focus-textarea">
              <textarea
                @change="onBioChange"
                type="textarea"
                rows="2"
                maxlength="220"
                :placeholder="userDate.bio ? userDate.bio : 'Enter your Bio'"
              />
            </div>
          </div>
        </div>
        <div class="custom-select">
          <h5>
            Company Logo
            <i class="fa-solid fa-circle-info ms-1"> </i>
            <span class="ms-0 number-characters">Size limit: 2MB </span>
          </h5>

          <div id="file-image" class="upload-image">
            <input
              @change="onCompanyLogoChange"
              type="file"
              name="img"
              accept="image/*"
            />
            <img
              v-if="!url && !userDate.profileImage"
              class="img-upload"
              src="../assets/upload.svg"
              alt="submit"
            />
            <img
              class="img-up"
              v-else-if="url && selectedCompanyLogo.size <= 2100000"
              :src="url"
            />
            <div
              class="
                img-up
                text-center
                d-flex
                justify-content-center
                align-items-center
                position-absolute
              "
              v-else-if="url && selectedCompanyLogo.size > 2100000"
            >
              <p style="font-size: 0.8rem" class="text-white">
                Error. <br />
                Size limit: 2MB
              </p>
            </div>
            <img
              v-else-if="userDate.profileImage"
              class="img-up"
              :src="
                'https://ipfs.niftyz.io/ipfs/' +
                userDate.profileImage.substring(7)
              "
            />
          </div>
        </div>
        <div class="custom-select submit-form">
          <div v-if="!isRegistered" class="d-flex justify-content-end">
            <div id="terms" class="d-flex">
              <input
                @click="onChangeValueTerms"
                type="checkbox"
                name="vehicle1"
                value="terms"
              />
              <p class="mb-0 ms-2">
                I agree to the
                <a
                  class="text-decoration-underline"
                  href="Terms_of_Service.pdf"
                  target="_blank"
                  >terms & conditions</a
                >
              </p>
            </div>
          </div>
          <button
            v-if="sending == 'not_sent'"
            @click="submitForm()"
            :class="styleBtn() ? null : 'btn-disabled'"
            type="submit"
          >
            Complete Profile
          </button>
          <div v-if="sending == 'pending'" style="color: #185173">
            Badge is being minted...
          </div>
          <div v-if="sending == 'success'">Success!</div>
          <div v-if="sending == 'failure'">Failure!</div>
          <!-- <button @click="submitForm()" type="submit">
            Complete Profile
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { City, Country } from "country-state-city";
import { mapStores } from "pinia";
import { useUserStore } from "../stores/userStore";

import axios from "axios";
import { useWeb3Store } from "../stores/web3Store";
export default {
  data() {
    return {
      checkTerms: false,
      countries: [],
      cities: [],
      api: process.env.VUE_APP_API_ENDPOINT,
      selectedCountry: "",
      selectedCity: "",
      selectedIndustry: "",
      selectIndustry: false,
      selectedTitle: "",
      selectCountry: false,
      selectCity: false,
      selectTitle: false,
      selectedCompanyLogo: null,
      url: null,
      searchCountry: "",
      selectedEmail: "",
      selectedBio: "",
      email: "",
      wrongEmail: false,
      searchCity: "",
      userDate: "",
      sending: "not_sent",
      coverSize: false,
      industries: [
        "Accounting",
        "Advertising, Arts & Media",
        "Blockchain & Web3",
        "Consulting & Strategy",
        "Creative Freelancers",
        "Design, Architecture & Construction",
        "Education & Training",
        "Events, Marketing and PR",
        "Finance and Investment",
        "Fintech",
        "Hospitality & Tourism & Members Clubs",
        "Human Resources & Recruitment",
        "Legal",
        "Medical & Medtech",
        "Membership Organisations & Associations",
        "NGO & Charity",
        "Real Estate & Property",
        "Retail & Consumer Products",
        "Software Development",
        "Sport & Recreation",
        "Other",
      ],
    };
  },
  props: ["isRegistered"],
  computed: {
    filteredCountry() {
      return this.countries.filter((member) =>
        member.value.toLowerCase().startsWith(this.searchCountry.toLowerCase())
      );
    },
    filteredCity() {
      return this.cities.filter((member) =>
        member.value.toLowerCase().startsWith(this.searchCity.toLowerCase())
      );
    },
    isUserComplete() {
      return !!this.userDate && !!this.userDate?.industry;
    },
    ...mapStores(useUserStore),
    ...mapStores(useWeb3Store),
    checked() {
      return this.userStore?.twitter;
    },
  },
  async mounted() {
    const app = this;
    await app.getUser();

    const savedRole = localStorage.getItem("niftyzRole");
    if (savedRole) {
      this.role = savedRole;
    }

    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
    app.countries = Country.getAllCountries().map((el) => {
      return {
        id: el.isoCode,
        value: el.name,
      };
    });
    if (app.isRegistered && app.userDate.country) {
      const countryIsoCode = app.countries.find(
        (el) => el.value == this.userDate.country
      ).id;
      this.cities = City.getCitiesOfCountry(countryIsoCode).map((el) => {
        return {
          id: el.stateCode,
          value: el.name,
        };
      });
    }
  },
  methods: {
    async getUser() {
      const app = this;
      try {
        const buf = Buffer.from(app.userStore.address);
        const b64 = buf.toString("base64");
        console.log(b64);
        const user = await axios.get(app.api + "/user/" + b64);
        app.userDate = user.data.user;
      } catch (err) {
        console.log(err);
      }
    },
    async submitForm() {
      const app = this;
      if (app.isRegistered) {
        await app.sendUpdate();
      } else {
        await app.sendRegister();
      }
    },
    async sendRegister() {
      const app = this;
      if (
        app.selectedCountry != "" &&
        app.selectedCity != "" &&
        app.selectedIndustry != "" &&
        app.selectedTitle != "" &&
        app.email != "" &&
        app.selectedBio != "" &&
        app.selectedCompanyLogo &&
        app.selectedCompanyLogo.size <= 2100000 &&
        app.checkTerms
      ) {
        if (app.validateRegister() && app.sending == "not_sent") {
          app.sending = "pending";
          try {
            const bodyFormData = new FormData();
            const metadataBody = {
              accountType: app.role,
              walletAddress: app.userStore.address,
              country: app.selectedCountry,
              city: app.selectedCity,
              industry: app.selectedIndustry,
              jobTitle: app.selectedTitle,
              email: app.selectedEmail,
              bio: app.selectedBio,
            };

            // const providerOptions = {
            //   walletconnect: {
            //     package: WalletConnectProvider,
            //     options: {
            //       infuraId: process.env.VUE_APP_INFURA_ID,
            //     },
            //   },
            // };
            // Instantiating Web3Modal
            // const web3Modal = new Web3Modal({
            //   cacheProvider: true,
            //   providerOptions: providerOptions,
            // });

            // const provider = await web3Modal.connect();
            // const web3 = await new Web3(provider);
            const toSign =
              "Signup request for user no. " + this.userStore.address;
            const signature = await this.web3Store.web3.eth.personal.sign(
              toSign,
              this.userStore.address
            );

            bodyFormData.append("metadata", JSON.stringify(metadataBody));
            bodyFormData.append("message", toSign);
            bodyFormData.append("signature", signature);
            bodyFormData.append("address", this.userStore.address);
            bodyFormData.append("companyLogo", app.selectedCompanyLogo);

            const response = await axios.post(
              app.api + "/register",
              bodyFormData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );
            console.log(response);
            app.sending = "success";
            setTimeout(() => {
              window.location.replace("/#/my-niftyz");
              setTimeout(() => {
                window.location.reload("/#/my-niftyz");
              }, 1000);
            }, 3000);
          } catch (err) {
            app.sending = "not_sent";
            alert(err.message ?? err);
            console.log("err", err.message ?? err);
          }
        }
      }
    },

    async sendUpdate() {
      const app = this;
      if (!app.selectedCompanyLogo || app.selectedCompanyLogo.size <= 2100000) {
        if (app.validateUpdate() && app.sending == "not_sent") {
          app.sending = "pending";
          try {
            const bodyFormData = new FormData();
            const metadataBody = {};
            if (app.selectedCountry != "") {
              metadataBody["country"] = app.selectedCountry;
            }
            if (app.selectedCity != "") {
              metadataBody["city"] = app.selectedCity;
            }
            if (app.selectedIndustry != "") {
              metadataBody["industry"] = app.selectedIndustry;
            }
            if (app.selectedTitle != "") {
              metadataBody["jobTitle"] = app.selectedTitle;
            }
            if (app.selectedEmail != "") {
              metadataBody["email"] = app.selectedEmail;
            }
            if (app.selectedBio != "") {
              metadataBody["bio"] = app.selectedBio;
            }

            // const providerOptions = {
            //   walletconnect: {
            //     package: WalletConnectProvider,
            //     options: {
            //       infuraId: process.env.VUE_APP_INFURA_ID,
            //     },
            //   },
            // };
            // // Instantiating Web3Modal
            // const web3Modal = new Web3Modal({
            //   cacheProvider: true,
            //   providerOptions: providerOptions,
            // });

            // const provider = await web3Modal.connect();
            // const web3 = await new Web3(provider);
            const toSign =
              "Update request for user no. " + this.userStore.address;
            const signature = await this.web3Store.web3.eth.personal.sign(
              toSign,
              this.userStore.address
            );

            bodyFormData.append("metadata", JSON.stringify(metadataBody));
            bodyFormData.append("message", toSign);
            bodyFormData.append("signature", signature);
            bodyFormData.append("address", this.userStore.address);
            if (app.selectedCompanyLogo != null) {
              bodyFormData.append("companyLogo", app.selectedCompanyLogo);
            }

            const response = await axios.post(
              app.api + "/update",
              bodyFormData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );
            console.log(response);
            app.sending = "success";
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } catch (err) {
            app.sending = "not_sent";
            alert(err.message ?? err);
            console.log("err", err.message ?? err);
          }
        }
      }
    },
    styleBtn() {
      const app = this;
      if (app.isRegistered) {
        return (
          (app.selectedCountry != "" && app.selectedCity != "") ||
          app.selectedCity != "" ||
          app.selectedIndustry != "" ||
          app.selectedTitle != "" ||
          app.email != "" ||
          app.selectedBio != "" ||
          (app.selectedCompanyLogo && app.selectedCompanyLogo.size <= 2100000)
        );
      } else {
        return (
          app.selectedCountry != "" &&
          app.selectedCity != "" &&
          app.selectedIndustry != "" &&
          app.selectedTitle != "" &&
          app.email != "" &&
          app.selectedBio != "" &&
          app.selectedCompanyLogo &&
          app.selectedCompanyLogo.size <= 2100000 &&
          app.checkTerms
        );
      }
    },
    validateRegister() {
      const app = this;
      let isValid = true;
      if (app.role == "") {
        alert("inserisci role");
        if (isValid) {
          isValid = false;
        }
      }
      if (app.selectedCountry == "") {
        document.getElementById("country").classList.add("alert");

        if (isValid) {
          isValid = false;
        }
      }
      if (app.selectedCity == "") {
        document.getElementById("city").classList.add("alert");

        if (isValid) {
          isValid = false;
        }
      }
      if (app.selectedIndustry == "") {
        document.getElementById("industry").classList.add("alert");

        if (isValid) {
          isValid = false;
        }
      }
      if (app.selectedTitle == "") {
        document.getElementById("title").classList.add("alert");

        if (isValid) {
          isValid = false;
        }
      }
      if (app.selectedCompanyLogo == null) {
        document.getElementById("file-image").classList.add("alert");
        if (isValid) {
          isValid = false;
        }
      }
      if (app.selectedEmail == "") {
        document.getElementById("email").classList.add("alert");
        if (isValid) {
          isValid = false;
        }
      } else {
        const emailRegEx = /^[\w-.]+@([\w-]+.)+[\w-]{2,}$/g;
        const isEmailValid = emailRegEx.test(app.selectedEmail);
        if (!isEmailValid) {
          document.getElementById("email").classList.add("alert");
          if (isValid) {
            isValid = false;
          }
        }
      }
      if (app.selectedBio == "") {
        document.getElementById("bio").classList.add("alert");
        if (isValid) {
          isValid = false;
        }
      }
      if (!this.isRegistered) {
        if (!app.checkTerms) {
          document.getElementById("terms").classList.add("alert");

          if (isValid) {
            isValid = false;
          }
        }
      }

      return isValid;
    },
    validateUpdate() {
      const app = this;
      let isValid = false;
      if (app.selectedCountry != "" && app.searchCity != "") {
        if (!isValid) {
          isValid = true;
        }
      } else if (app.selectedCity != "") {
        if (!isValid) {
          isValid = true;
        }
      }
      if (app.selectedIndustry != "") {
        if (!isValid) {
          isValid = true;
        }
      }
      if (app.selectedTitle != "") {
        if (!isValid) {
          isValid = true;
        }
      }
      if (app.selectedCompanyLogo && app.selectedCompanyLogo.size <= 2100000) {
        if (!isValid) {
          isValid = true;
        }
      }
      if (app.selectedEmail != "") {
        const emailRegEx = /^[\w-.]+@([\w-]+.)+[\w-]{2,}$/g;
        const isEmailValid = emailRegEx.test(app.selectedEmail);
        if (isEmailValid) {
          if (!isValid) {
            isValid = true;
          }
        }
      }
      if (app.selectedBio != "") {
        if (!isValid) {
          isValid = true;
        }
      }

      return isValid;
    },
    onlyEmailValid() {
      const emailRegEx = /^[\w-.]+@([\w-]+.)+[\w-]{2,}$/g;
      emailRegEx.test(this.selectedEmail);
    },
    onChangeValueTerms(e) {
      document.getElementById("terms").classList.remove("alert");

      console.log(e);
      this.checkTerms = e.target.checked;
    },
    onCompanyLogoChange(e) {
      document.getElementById("file-image").classList.remove("alert");
      this.selectedCompanyLogo = e.target.files[0];
      this.url = URL.createObjectURL(this.selectedCompanyLogo);
      if (this.selectedCompanyLogo.size > 2100000) {
        this.coverSize = true;
      } else {
        this.coverSize = false;
      }
    },
    onCountryChange(country) {
      document.getElementById("country").classList.remove("alert");
      this.cities = City.getCitiesOfCountry(country.id).map((el) => {
        return {
          id: el.stateCode,
          value: el.name,
        };
      });
      this.selectedCountry = country.value;
      this.selectCountry = false;
      this.selectedCity = "";
      console.log("e", this.cities);
    },
    onCityChange(city) {
      document.getElementById("city").classList.remove("alert");
      this.selectedCity = city.value;
      this.selectCity = false;
    },
    onIndustryChange(industry) {
      document.getElementById("industry").classList.remove("alert");
      this.selectedIndustry = industry;
      this.selectIndustry = false;
    },
    onTitleChange(e) {
      document.getElementById("title").classList.remove("alert");
      this.selectedTitle = e.target.value;
    },
    onEmailChange(e) {
      const emailRe =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRe.test(this.email)) {
        this.wrongEmail = false;
        console.log("ciao");
      } else {
        this.wrongEmail = true;
        console.log("mamma");
        console.log("email", this.email);
      }
      document.getElementById("email").classList.remove("alert");
      this.selectedEmail = e.target.value;
    },
    onBioChange(e) {
      document.getElementById("bio").classList.remove("alert");
      this.selectedBio = e.target.value;
    },
  },
};
</script>

<style></style>
