<template>
  <div>
    <!-- <Verify v-if="!this.userStore.isConnected" /> -->
    <div class="my-niftyz mt-mob-10">
      <Header />
      <div
        :class="isMobile ? 'pt-5' : null"
        class="container-fluid top-singleNft"
      >
        <div v-if="this.userStore.isMember" class="row">
          <div class="col-12">
            <a href="/#/gallery"
              ><i class="fa-solid fa-arrow-left-long"></i> Back to gallery
            </a>
          </div>
        </div>

        <div v-if="singleNft.metadata" class="row">
          <div class="col-12 col-md-12 box-single-nft">
            <div class="text-center mb-4">
              <img
                v-if="!isMobile"
                class="img-singleNft"
                :src="
                  'https://ipfs.niftyz.io/ipfs/' +
                  singleNft.metadata.image.substring(7)
                "
                alt=""
              />
              <a href="#buy">
                <img
                  v-if="isMobile"
                  class="img-singleNft"
                  :src="
                    'https://ipfs.niftyz.io/ipfs/' +
                    singleNft.metadata.image.substring(7)
                  "
                  alt=""
                />
              </a>
              <div class="box-share">
                <ul>
                  <li>
                    <ShareNetwork
                      network="twitter"
                      :url="
                        currentDomain +
                        '#/single-nft/' +
                        singleNft.blockchain +
                        '/' +
                        singleNft.smartContract +
                        '/' +
                        singleNft.slug
                      "
                      title="I've just minted my NFT with Niftyz.io, the leading NFT platform for businesses! Turn your documents, connections, know-how and more into tradable assets in three easy steps - Upload, Mint and Earn with Niftyz."
                    >
                      <i class="fa-brands fa-twitter"></i>
                    </ShareNetwork>
                  </li>
                  <li>
                    <ShareNetwork
                      network="linkedin"
                      :url="
                        currentDomain +
                        '#/single-nft/' +
                        singleNft.blockchain +
                        '/' +
                        singleNft.smartContract +
                        '/' +
                        singleNft.slug
                      "
                      title="I've just minted my NFT with Niftyz.io, the leading NFT platform for businesses! Turn your documents, connections, know-how and more into tradable assets in three easy steps - Upload, Mint and Earn with Niftyz."
                    >
                      <i class="fa-brands fa-linkedin-in"></i>
                    </ShareNetwork>
                  </li>
                  <div v-if="this.userStore" class="d-flex align-items-center">
                    <li class="mb-0" @click="copy()">
                      <i class="fa-sharp fa-solid fa-share share"></i>
                    </li>
                    <span v-if="this.showCopy" class="mb-0 ms-3"
                      >Copied
                      <i style="color: green" class="fa-solid fa-check"></i
                    ></span>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="isMobile ? 'mt-0' : 'mt-5'"
        class="container bottom-singleNft"
      >
        <div class="row">
          <div class="col-12 col-md-6 border-grey">
            <div v-if="singleNft.metadata" class="mb-5">
              <h5 class="mb-1">Description:</h5>
              <p class="mt-0 ms-2 description-nft">
                {{ singleNft.metadata.description }}
              </p>
            </div>
            <div class="" v-if="singleNft.owners">
              <div v-if="singleNft.owners.length > 0">
                <div class="d-flex justify-content-between align-items-center">
                  <h5>Pass Token stats</h5>
                  <h6>Sales</h6>
                </div>
                <ul class="list-account">
                  <li class="d-flex justify-content-between" v-for="(owner, index) in owners" :key="index">
                    <span>{{ owner.address }}</span>
                    <span v-if="owner.formattedTimeComplete">{{ owner.formattedTimeComplete }}</span>
                    
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="box-collection" style="background: transparent">
              <a
                v-if="this.creator"
                class="text-start"
                :href="'#/author/' + this.creator._id"
              >
                <h3 class="mb-4 pb-2" v-if="this.creator.twitter">
                  {{ this.creator?.twitter }}
                </h3>
                <h3
                  class="mb-4 pb-2"
                  v-if="this.creator.linkedin && !this.creator.twitter"
                >
                  {{ this.creator?.linkedin }}
                </h3>
              </a>
              <div v-if="duration && duration != ''">
                <h6 v-if="duration != 'infinite'" class="mb-4 pb-2 deadline">
                  <i class="fa-regular fa-clock me-2 text-white"></i>
                  Sale ends
                  {{ duration }}
                </h6>
                <h6 v-if="duration == 'infinite'" class="mb-4 pb-2">
                  <i class="fa-regular fa-clock me-1"></i>
                  Infinite sale
                </h6>
              </div>
              <div
                v-if="dur * 1000 > now"
                class="d-flex justify-content-start mb-3"
              >
                <div class="me-5">
                  <h4 class="mb-0">{{ leftDays }}</h4>
                  <p class="mt-0">Days</p>
                </div>
                <div class="me-5">
                  <h4 class="mb-0">{{ leftHours }}</h4>
                  <p class="mt-0">Hours</p>
                </div>
                <div class="me-5">
                  <h4 class="mb-0">{{ leftMinutes }}</h4>
                  <p class="mt-0">Minutes</p>
                </div>
                <div class="me-5">
                  <h4 class="mb-0">{{ leftSeconds }}</h4>
                  <p class="mt-0">Seconds</p>
                </div>
              </div>
              <h4 v-if="singleNft.metadata" class="mb-4 pb-2">
                {{ singleNft.metadata.name }}
              </h4>
              <div
                class="mb-4 pb-2"
                v-if="
                  singleNft.creator == userStore.address ||
                  (singleNft.owners &&
                    singleNft.owners.length > 0 &&
                    singleNft.owners.findIndex(
                      (el) => el.address === userStore.address
                    ) != -1)
                "
              >
                <h6 class="mb-3">Files:</h6>
                <button
                  v-for="(file, index) in media"
                  :key="index"
                  @click="
                    openAttachment(file.id, file.renderMethod ?? 'static')
                  "
                  class="link-preview"
                >
                  {{ file.name }}
                </button>
              </div>
              <div class="box-more border-grey">
                <h5 class="mb-3">ETH {{ priceNft }}</h5>
                <div class="d-flex">
                  <button
                    v-if="
                      singleNft.minted &&
                      userStore.isConnected &&
                      singleNft.creator != userStore.address &&
                      singleNft.owners &&
                      singleNft.owners.length < supply &&
                      (dur * 1000 >= now || duration == 'infinite') &&
                      singleNft.owners.findIndex(
                        (el) => el.address === userStore.address
                      ) == -1 &&
                      buyStatus == 'not_sent'
                    "
                    @click="buyNFT"
                    class="btn-connect"
                    id="buy"
                  >
                    Buy Now
                  </button>

                  <div
                    v-else-if="fetchStatus == 'success'"
                    class="d-flex justify-content-end"
                  >
                    <button
                      disabled
                      v-if="
                        singleNft.owners && singleNft.owners.length >= supply
                      "
                      class="btn-sold me-3"
                    >
                      Sold-Out
                    </button>
                    <button
                      disabled
                      v-if="dur * 1000 < now"
                      class="btn-sold me-1"
                      style="opacity: 0.8"
                    >
                      Sale Ended
                    </button>
                  </div>
                  <modal name="buy-progress" class="description-nft text-black">
                    <div class="d-flex justify-content-end">
                      <i
                        @click="closeModalBuyProgress()"
                        class="fa-solid fa-circle-xmark p-3"
                      ></i>
                    </div>

                    <div
                      v-if="buyStatus == 'pending'"
                      class="d-flex flex-column align-items-center justify-content-center mt-1"
                    >
                      <h3 class="text-buy">Buy in progress...</h3>
                      <div class="lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                    <div
                      class="d-flex flex-column align-items-center justify-content-center mt-4"
                      v-if="buyStatus == 'success'"
                    >
                      <h3>You successfully purchased your NFT!</h3>
                    </div>
                  </modal>
                  <div
                    v-if="
                      !singleNft.minted &&
                      singleNft.creator == userStore.address &&
                      mintStatus == 'not_sent'
                    "
                    class="d-flex align-items-center"
                  ></div>
                  <div class="mt-0 mb-2" v-if="mintStatus == 'pending'">
                    <div class="lds-default">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <div
                  v-if="dur * 1000 > now || duration == 'infinite'"
                  class="mb-4"
                >
                  <h6 class="mb-1">Remaining Pass Tokens :</h6>
                  <p class="me-2 ms-2">
                    {{ singleNft.available }} out of {{ supply }}
                  </p>
                </div>
                <div class="mb-4">
                  <h6 class="mb-1">Personal Tags:</h6>
                  <p
                    v-for="(tag, index) in personalTags"
                    :key="index"
                    class="me-2 ms-2 multi-tag"
                  >
                    #{{ tag }}
                  </p>
                </div>
                <div class="mb-4">
                  <h6 class="mb-1">Target Audience:</h6>
                  <p
                    v-for="(target, index) in targets"
                    :key="index"
                    class="me-2 ms-2 d-inline"
                  >
                    {{ target }}
                  </p>
                </div>
                <!-- <div class="mb-4">
                  <h6 class="mb-0">Category Tag:</h6>
                  <p class="ms-2">
                    {{ tagCategory }}
                  </p>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <div
        :style="{ display: frameOpen ? 'flex' : 'none' }"
        class="attachment-container"
      >
        <div class="d-flex flex-column section-iframe">
          <div
            v-if="this.iframeLoadingStatus != 'pending'"
            class="d-flex justify-content-end w-100"
          >
            <i @click="closeFrame()" class="fa-solid fa-circle-xmark p-3"></i>
          </div>
          <div v-if="this.iframeLoadingStatus == 'pending'" class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <iframe
            v-show="this.iframeLoadingStatus == 'success'"
            @load="onLoader()"
            id="attachment-frame"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapStores } from "pinia";
import Web3 from "web3";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
/* import Verify from "../components/Verify.vue"; */
import tokenAbi from "../libs/token_abi.json";
import { useUserStore } from "../stores/userStore";
import { useWeb3Store } from "../stores/web3Store";

export default {
  name: "singleNft",
  data() {
    return {
      tokenABI: tokenAbi,
      showCopy: false,
      isMobile: false,
      api: process.env.VUE_APP_API_ENDPOINT,
      slugNft: this.$route.params.slug,
      mintingContract: process.env.VUE_APP_CONTRACT_ADDRESS_MINTER,
      tokenContract: process.env.VUE_APP_CONTRACT_ADDRESS_PREMIUM_PT,
      tokenContractInstance: null,
      network: process.env.VUE_APP_NETWORK,
      infuraId: process.env.VUE_APP_INFURA_ID,
      currentDomain: process.env.VUE_APP_PLATFORM_DOMAIN,
      web3: null,
      nftSynced: false,
      singleNft: [],
      creator: [],
      media: [],
      personalTags: "",
      targets: "",
      supply: "",
      duration: "",
      dur: "",
      tagCategory: "",
      priceNft: "",
      owners: [],
      buyStatus: "not_sent",
      mintStatus: "not_sent",
      fetchStatus: "not_sent",
      leftDays: "",
      leftHours: "",
      leftMinutes: "",
      leftSeconds: "",
      now: Date.now(),
      bugConsole: true,
      frameOpen: false,
      iframeLoadingStatus: "not_sent",
    };
  },
  components: {
    Header,
    /* Verify, */
    Footer,
  },
  async mounted() {
    const app = this;
    if (app.bugConsole) {
      console.log("orario", app.now);
    }

    await app.getSingleNft();

    if (app.userStore.address != null) {
      if (
        app.singleNft &&
        app.singleNft?.tokenId !== null &&
        app.singleNft?.tokenId !== undefined
      ) {
        if (!app.nftSynced) {
          app.nftSynced = true;
          console.log("calling sync on mounted");
          await app.checkSyncNFT();
        }
      }
    } else if (!app.nftSynced) {
      const unsubscribe = app.userStore.$onAction(({ name, after }) => {
        after(async () => {
          if (name === "connect") {
            unsubscribe();

            if (
              app.singleNft &&
              app.singleNft?.tokenId !== null &&
              app.singleNft?.tokenId !== undefined
            ) {
              if (!app.nftSynced) {
                app.nftSynced = true;
                console.log("calling sync after mounted");
                await app.checkSyncNFT();
              }
            }
          }
        });
      });
    }

    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
    this.owners.sort((a, b) => parseFloat(b.formattedTimeComplete) - parseFloat(a.formattedTimeComplete))
  },
  watch: {
    // whenever question changes, this function will run
    // async userStore(newState) {
    //   if (
    //     newState?.address &&
    //     this.singleNft &&
    //     this.singleNft?.tokenId !== null &&
    //     this.singleNft?.tokenId !== undefined &&
    //     !this.nftSynced
    //   ) {
    //     this.nftSynced = true;
    //     console.log("calling sync on watch user");
    //     await this.checkSyncNFT();
    //   }
    // },
    async singleNft(newValue) {
      if (
        this.userStore?.address &&
        newValue?.tokenId !== null &&
        newValue?.tokenId !== undefined &&
        !this.nftSynced
      ) {
        this.nftSynced = true;
        console.log("calling sync on watch nft");
        await this.checkSyncNFT();
      }
    },
  },
  methods: {
    // async connect() {
    //   const app = this;
    //   let providerOptions = {};
    //   if (app.infuraId !== undefined) {
    //     if (this.bugConsole) {
    //       console.log("Using infuraid:", app.infuraId);
    //     }
    //     providerOptions = {
    //       walletconnect: {
    //         package: WalletConnectProvider,
    //         options: {
    //           infuraId: app.infuraId,
    //         },
    //       },
    //     };
    //   } else if (!window.ethereum) {
    //     alert("Please install MetaMask to proceed");
    //     return;
    //   }
    //   // Instantiating Web3Modal
    //   const web3Modal = new Web3Modal({
    //     cacheProvider: true,
    //     providerOptions: providerOptions,
    //   });
    //   const provider = await web3Modal.connect();
    //   const web3 = await new Web3(provider);

    //   // Checking if networkId matches
    //   const netId = await app.web3.eth.net.getId();
    //   if (parseInt(netId) !== app.networks[app.network]) {
    //     alert("Switch to " + app.network + " network!");
    //   } else {
    //     app.web3 = web3;
    //   }
    // },

    closeFrame() {
      const frame = document.getElementById("attachment-frame");
      // console.log("ciao", res.data);
      frame.src = "about:blank";
      this.frameOpen = false;
    },

    async openAttachment(id, renderMethod) {
      /* address utente, ipfs.hash, numero attachements, signature */
      const app = this;
      const tokenContract = new app.web3Store.web3.eth.Contract(
        app.tokenABI,
        app.tokenContract
      );

      const hash = app.singleNft.ipfs.substring(7);
      const tokenId = app.singleNft.tokenId;
      const prefix = await tokenContract.methods.getPrefix(hash).call();
      console.log("hash", hash);
      console.log("prefix", prefix);
      const signature = await app.web3Store.web3.eth.personal.sign(
        prefix,
        this.userStore.address
      );

      if (renderMethod == "framed") {
        this.iframeLoadingStatus = "pending";
        this.frameOpen = true;
        const frame = document.getElementById("attachment-frame");
        // console.log("ciao", res.data);
        frame.src =
          app.api +
          "/nfts/content/" +
          app.userStore.address +
          "/" +
          tokenId +
          "/" +
          id +
          "/" +
          signature;
      } else {
        window.open(
          app.api +
            "/nfts/content/" +
            app.userStore.address +
            "/" +
            tokenId +
            "/" +
            id +
            "/" +
            signature,
          "_blank"
        );
      }
    },
    copy() {
      if (this.singleNft) {
        try {
          navigator.clipboard.writeText(
            this.currentDomain +
              "#/single-nft/" +
              this.singleNft.blockchain +
              "/" +
              this.singleNft.smartContract +
              "/" +
              this.singleNft.slug
          );
          this.showCopy = true;
          setTimeout(() => {
            this.showCopy = false;
          }, 2000);
        } catch (e) {
          console.log(e);
          alert(
            "Could not copy the NFT link to the clipboard. Please, try again or get it from your browser searchbar"
          );
        }
      } else {
        alert(
          "Could not copy the NFT link to the clipboard. Please, try again or get it from your browser searchbar"
        );
      }
    },
    async buyNFT() {
      const app = this;
      app.buyStatus = "pending";
      app.showModalBuyProgress();
      try {
        const nftContract = new app.web3Store.web3.eth.Contract(
          app.tokenABI,
          app.tokenContract
        );

        const price = app.singleNft.price.toString();

        // const message = app.singleNft.ipfs;
        // const signature = await app.web3.eth.personal.sign(
        //   message,
        //   app.userStore.address
        // );

        const estimated = await nftContract.methods
          .buy(app.singleNft.ipfs.substring(7), app.userStore.address)
          .estimateGas({
            from: app.userStore.address,
            value: price,
          });
        const gasLimit = parseInt(estimated * 1.4).toString();
        const buyRes = await nftContract.methods
          .buy(app.singleNft.ipfs.substring(7), app.userStore.address)
          .send({
            from: app.userStore.address,
            value: price,
            gas: gasLimit,
          });

        const buyServerRes = await axios.post(app.api + "/nfts/buy", {
          id: this.singleNft.tokenId,
          buyTx: buyRes.transactionHash,
          // message,
          // signature,
          address: app.userStore.address,
        });

        if (buyServerRes.data.error) {
          console.error(buyServerRes.data.message);
          alert(buyServerRes.data.message);
          app.buyStatus = "not_sent";
          app.closeModalBuyProgress();
        } else {
          app.buyStatus = "success";
          /* setTimeout(() => {
            window.location.href("/#/my-niftyz");
          }, 3000);
          app.closeModalBuyProgress(); */
          console.log("Succcessfully requested to the server");

          await this.getSingleNft();
        }
      } catch (err) {
        app.buyStatus = "not_sent";
        app.closeModalBuyProgress();
        alert(err.message ?? err);
        console.log(err.message ?? err);
      }
    },
    async getSingleNft() {
      const app = this;
      this.fetchStatus = "pending";
      try {
        const response = await axios.get(
          app.api + "/nfts/single-by-slug/" + encodeURIComponent(app.slugNft)
          // app.api + "/nfts/single/" + app.slugNft
        );
        app.singleNft = response.data.nft;

        const supply = app.singleNft.metadata.privateAttributes.find(
          (el) => el.trait_type === "Number"
        ).value;
        const owners =
          app.singleNft?.owners && app.singleNft?.owners?.length
            ? app.singleNft?.owners?.length
            : 0;
        app.supply = Number(supply);
        app.priceNft = Web3.utils.fromWei(
          app.singleNft.price.toString(),
          "ether"
        );
        app.creator = app.singleNft.creatorFull[0];
        if (app.singleNft.owners && app.singleNft.owners.length > 0) {
          app.owners = [];
          app.singleNft.owners.forEach((owner) => {
            if(owner.buyTimestamp){
              let date = new Date(owner.buyTimestamp);
              const month = ("0" + (date.getMonth() + 1)).slice(-2);
              const day = ("0" + date.getDate()).slice(-2);
              let formattedTimeComplete = day + "/" + month + "/" + ("0" + (date.getFullYear())).slice(-2);
              let formattedBuyTime =  month + "/" + ("0" + (date.getFullYear())).slice(-2);
              

              app.owners.push({...owner,formattedTimeComplete});
             
         
  
              /* app.owners.push(owner.buyTimestamp); */
              console.log("owners", app.owners);
            }else{
              app.owners.push({...owner});
            }

          });
          if (this.bugConsole) {
            console.log("owners", app.owners);
          }
        }

        this.dur = app.singleNft.metadata.privateAttributes.find(
          (el) => el.trait_type === "Duration"
        ).value;
        if (this.bugConsole) {
          console.log("dur", this.dur);
        }
        if (this.dur == "infinite") {
          app.duration = app.dur;
        } else {
          const tmpDuration = new Date(app.dur * 1000);
          const day = ("0" + tmpDuration.getDate()).slice(-2);
          const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          const monthName = monthNames[tmpDuration.getMonth()];
          app.duration =
            monthName +
            " " +
            day +
            ", " +
            tmpDuration.getFullYear() +
            " at " +
            ("0" + tmpDuration.getUTCHours()).slice(-2) +
            ":" +
            ("0" + tmpDuration.getUTCMinutes()).slice(-2) +
            " GMT";
        }

        const file = app.singleNft.metadata.media;
        app.media = file;
        if (this.bugConsole) {
          console.log("media", this.media);
          console.log("creator", app.creator);
        }
        app.personalTags = app.singleNft.metadata.privateAttributes.find(
          (el) => el.trait_type === "PersonalTags"
        )?.value;
        app.targets = app.singleNft.metadata.privateAttributes.find(
          (el) => el.trait_type === "TargetGroups"
        )?.value;
        app.tagCategory = app.singleNft.metadata.attributes.find(
          (el) => el.trait_type === "Utility"
        )?.value;
        console.log("app.tagCategory", app.tagCategory);
        this.fetchStatus = "success";
      } catch (err) {
        this.fetchStatus = "failure";
        console.log(err.message ?? err);
      }
      if (this.bugConsole) {
        console.log("<<<<<<<========== singleNft", app.singleNft);
      }
      this.initCountDown();
    },
    showModalBuyProgress() {
      this.$modal.show("buy-progress");
      console.log("modaleeee");
    },
    closeModalBuyProgress() {
      this.$modal.hide("buy-progress");
    },
    async checkSyncNFT() {
      console.log("Syncing NFT");
      const app = this;
      try {
        console.log("app.web3Store.web3", app.web3Store.web3);
        const tokenContractInstance = new app.web3Store.web3.eth.Contract(
          app.tokenABI,
          app.tokenContract
        );

        console.log(app.singleNft.tokenId);
        console.log(app.userStore.address);
        const tokenBalance = await tokenContractInstance.methods
          .balanceOf(app.userStore.address, Number(app.singleNft.tokenId))
          .call();
        const tokenStats = await tokenContractInstance.methods
          .passTokens(app.singleNft.ipfs.replace("ipfs://", ""))
          .call();

        const available = Number(tokenStats.supply) - Number(tokenStats.minted);

        const ownerIndex = app.singleNft.owners?.findIndex(
          (el) =>
            el.address.toLowerCase() == app.userStore.address.toLowerCase()
        );

        if (
          (tokenBalance > 0 && ownerIndex == -1) ||
          (tokenBalance == 0 && ownerIndex != -1) ||
          available != app.singleNft.available
        ) {
          const syncRes = await axios.post(app.api + "/nfts/sync", {
            nftId: app.singleNft.tokenId,
            address: app.userStore.address,
          });

          if (syncRes.data.error) {
            console.error(syncRes.data.message);
          } else {
            alert("NFT synced. Reloading...");
            window.location.reload();
          }
        } else {
          console.log("NFT is already synced");
        }
      } catch (err) {
        app.mintStatus = "not_sent";
        console.log(err.message);
        alert(err.message);
      }
    },
    onLoader() {
      this.iframeLoadingStatus = "success";
    },
    initCountDown() {
      const app = this;
      //COUNTDOWN TIMER START
      const eventTime = app.singleNft.metadata.privateAttributes.find(
        (el) => el.trait_type === "Duration"
      ).value;

      const currentTime = Math.floor(Date.now() / 1000);

      const diffTime = eventTime - currentTime;

      let durationCont = moment.duration(diffTime, "seconds");
      const interval = 1000;

      if (this.bugConsole) {
        console.log("eventTime", eventTime);
        console.log("currentTime", currentTime);
        console.log("diffTime", diffTime);
        console.log("duration", durationCont);
      }
      setInterval(() => {
        durationCont = moment.duration(
          durationCont.asMilliseconds() - interval
        );
        app.leftDays = Math.floor(durationCont.asDays());
        app.leftHours = durationCont.hours();
        app.leftMinutes = durationCont.minutes();
        app.leftSeconds = durationCont.seconds();
      }, interval);
      //COUNTDOWN TIMER END
    },
  },
  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useWeb3Store),
  },
};
</script>

<style lang="scss" scoped>
.prova {
  display: flex;
  flex-direction: column;
}
</style>
>
