<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import abi from "@/libs/membership_abi.json";
import { routesSpecs } from "@/router/index";
import { useUserStore } from "@/stores/userStore";
import axios from "axios";
import { mapStores } from "pinia";
import { useWeb3Store } from "./stores/web3Store";
export default {
  name: "App",
  data() {
    return {
      ABI: abi,
      api: process.env.VUE_APP_API_ENDPOINT,
      contract: process.env.VUE_APP_CONTRACT_ADDRESS_MEMBERSHIP,
      network: process.env.VUE_APP_NETWORK,
      infuraId: process.env.VUE_APP_INFURA_ID,
      landingUrl: process.env.VUE_APP_LANDING_DOMAIN,
      // web3: "",
      networks: {
        ethereum: 1,
        rinkeby: 4,
        goerli: 5,
        polygon: 137,
        mumbai: 80001,
        ganache: 5777,
      },
    };
  },
  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useWeb3Store),
  },
  methods: {
    //   async connect() {
    //     const app = this;
    //     let providerOptions = {};
    //     if (app.infuraId !== undefined) {
    //       console.log("Using infuraid:", app.infuraId);
    //       providerOptions = {
    //         walletconnect: {
    //           package: WalletConnectProvider,
    //           options: {
    //             infuraId: app.infuraId,
    //           },
    //         },
    //       };
    //     } else if (!window.ethereum) {
    //       alert("Please install MetaMask to proceed");
    //       return;
    //     }
    //     // Instantiating Web3Modal
    //     const web3Modal = new Web3Modal({
    //       cacheProvider: true,
    //       providerOptions: providerOptions,
    //     });
    //     const provider = await web3Modal.connect();
    //     app.web3 = await new Web3(provider);
    //     // Checking if networkId matches
    //     const netId = await app.web3.eth.net.getId();
    //     if (parseInt(netId) !== app.networks[app.network]) {
    //       alert("Switch to " + app.network + " network!");
    //     } else {
    //       const accounts = await app.web3.eth.getAccounts();
    //       if (accounts.length > 0) {
    //         const account = accounts[0];
    //         const nftContract = new app.web3.eth.Contract(app.ABI, app.contract);
    //         const balance = await nftContract.methods.balanceOf(account).call();
    //         console.log(balance);
    //         if (parseInt(balance) === 0) {
    //           this.userStore.connect({ address: account, isMember: false });
    //           // if (this.$route.name !== 'Gallery' || this.$route.name !== 'SingleNft' || this.$route.name !== 'Author')
    //           // {
    //           //   alert("You don't have any NFTs. Redirecting...");
    //           //   window.location.hash = "#/gallery"
    //           // }
    //           // window.location.href = app.landingUrl + "#/#mint-buy";
    //         } else {
    //           localStorage.setItem("niftyz-connected", true);
    //           this.userStore.connect({ address: account, isMember: true });
    //           //if there is a proof, it is validated elsewhere
    //           if (!this.$route.params?.["proof"]) {
    //             const buf = Buffer.from(account);
    //             const b64 = buf.toString("base64");
    //             const res = await axios.get(app.api + "/user/" + b64);
    //             if (!res.data.error) {
    //               const userRecord = res.data.user;
    //               if (userRecord?.twitter)
    //                 this.userStore.setTwitter(userRecord.twitter);
    //               if (userRecord?.linkedin)
    //                 this.userStore.setLinkedIn(userRecord.linkedin);
    //             }
    //           }
    //         }
    //       }
    //     }
    //   },
  },

  async mounted() {
    console.log("params: ", this.$route);
    this.userStore.$onAction(({ name, store, after }) => {
      after(async () => {
        if (name === "login") {
          console.log("Login -> CALLBACK");
          if (this.$route.params?.["proof"]) {
            console.log("Login -> PROOF");
            const proof = this.$route.params["proof"];
            try {
              console.log("proof", proof);
              const response = await axios.post(
                process.env.VUE_APP_API_ENDPOINT + "/oauth/validate",
                {
                  address: this.userStore.address,
                  proof,
                }
              );
              console.log(response.data);
              const user = response.data.user;
              if (user.twitter) {
                this.userStore.setTwitter(user.twitter);
              }
              if (user.linkedin) {
                this.userStore.setLinkedIn(user.linkedin);
              }
              if (user.discord) {
                this.userStore.setDiscord(user.discord);
              }
            } catch (err) {
              console.log(err);
              alert("Invalid proof");
            }
          }

          if (this.userStore.isMember) {
            if (
              this.userStore.user?.city != null &&
              this.userStore.user?.city != undefined
            ) {
              console.log(window.location);
              if (
                window.location.hash == "#/" ||
                this.$route.params?.["proof"]
              ) {
                // console.warn('jerryyyyyyyy')
                window.location.hash = "#/my-niftyz";
              }
            } else if (
              window.location.hash != "#/" &&
              !this.$route.params?.["proof"]
            ) {
              window.location.hash = "#/";
            }
          } else if (window.location.hash == "#/") {
            window.location.hash = "#/gallery";
          }
        }
      });
    });
    this.web3Store.$onAction(({ name, store, after }) => {
      after(async () => {
        if (name === "getBalance") {
          if (parseInt(store.balance) === 0) {
            this.userStore.connect({
              address: this.web3Store.account,
              isMember: false,
            });
          } else {
            this.userStore.connect({
              address: this.web3Store.account,
              isMember: true,
            });
            //if there is a proof, it is validated elsewhere
            if (!this.$route.params?.["proof"]) {
              const buf = Buffer.from(this.web3Store.account);
              const b64 = buf.toString("base64");
              const res = await axios.get(this.api + "/user/" + b64);
              if (!res.data.error) {
                const userRecord = res.data.user;
                if (userRecord?.twitter)
                  this.userStore.setTwitter(userRecord.twitter);
                if (userRecord?.linkedin)
                  this.userStore.setLinkedIn(userRecord.linkedin);
              }
            }
          }
        }
      });
    });

    if (routesSpecs[this.$route.name].autoConnect) {
      await this.web3Store.connect();
      // await this.web3Store.getBalance();

      // await this.connect();
    }
  },
};
</script>
