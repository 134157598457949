<template>
  <div class="custom-select">
    <h6 class="text-uppercase">{{ label }}</h6>
    <div @click="dropdownOpen = !dropdownOpen" class="focus-input border-grey">
      <p :class="selectedEntry == '' ? 'opacity-75' : 'text-white'">
        {{ selectedEntry == "" ? "Select" : selectedEntry.label }}
      </p>
      <i
        class="fa-solid"
        :class="dropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'"
      ></i>
    </div>
    <ul
      class="border-grey"
      v-if="dropdownOpen"
    >
      <li
        v-for="value in entries"
        :key="value.key"
        :value="value.value"
        @click="onEntryClick(value)"
      >
        {{ value.label }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "inputSelect",
  data() {
    return {
      selectTag: false,
      dropdownOpen: false,
      selectedEntry: "",
    };
  },
  props: ["entries", "label"],
  methods: {
    onEntryClick(entry) {
      this.selectedEntry = entry;
      this.$emit("entryClick", entry.value);
      this.dropdownOpen = false;
    },
  },
};
</script>

<style></style>
