import { useUserStore } from "@/stores/userStore";
import Author from "@/views/author.vue";
import AuthorNew from "@/views/authorNew.vue";
import AuthorNotActive from "@/views/authorNotActive.vue";
import CreateNft from "@/views/createNft.vue";
import DraftNft from "@/views/draftNft.vue";
import Gallery from "@/views/Gallery.vue";
import Home from "@/views/Home.vue";
import MyNiftyz from "@/views/myNiftyz.vue";
import NftPreview from "@/views/nftPreview.vue";
import SingleNft from "@/views/singleNft.vue";

function canAccessFullUser(to, from, next) {
  const userStore = useUserStore();
  if (!userStore.isConnected || !userStore.isMember || !userStore.user?.city) {
    next("");
  } else {
    next();
  }
}
function canAccessRegister(to, from, next) {
  const userStore = useUserStore();
  if (
    userStore.isConnected &&
    userStore.isMember &&
    userStore.user?.city != null &&
    userStore.user?.city != undefined
  ) {
    next("/my-niftyz");
  } else if (userStore.isConnected && !userStore.isMember) {
    next("/gallery");
  } else next();
}

export const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      return canAccessRegister(to, from, next);
    },
  },
  {
    path: "/my-niftyz",
    name: "MyNiftyz",
    component: MyNiftyz,
    beforeEnter: (to, from, next) => {
      return canAccessFullUser(to, from, next);
    },
  },
  {
    path: "/my-niftyz/:tab",
    name: "MyNiftyz",
    component: MyNiftyz,
    beforeEnter: (to, from, next) => {
      return canAccessFullUser(to, from, next);
    },
  },
  {
    path: "/author/:id",
    name: "Author",
    component: Author,
  },
  {
    path: "/author-new/:slug",
    name: "AuthorNew",
    component: AuthorNew,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Gallery,
    // beforeEnter: (to, from, next) => {
    //   return canAccessFullUser(to, from, next);
    // },
  },
  {
    path: "/author-not-active",
    name: "AuthorNotActive",
    component: AuthorNotActive,
  },
  {
    path: "/create-nft",
    name: "CreateNft",
    component: CreateNft,
    beforeEnter: (to, from, next) => {
      return canAccessFullUser(to, from, next);
    },
  },
  {
    path: "/single-nft/:slug",
    name: "SingleNft",
    component: SingleNft,
  },
  {
    path: "/single-nft/:blockchain/:contract/:slug",
    name: "SingleNft",
    component: SingleNft,
  },
  {
    path: "/draft-nft/:blockchain/:id",
    name: "DraftNft",
    component: DraftNft,
  },
  // {
  //   path: "/draft-nft/:id",
  //   name: "DraftNft",
  //   component: DraftNft,
  // },
  {
    path: "/nft-preview/:id",
    name: "NftPreview",
    component: NftPreview,
    beforeEnter: (to, from, next) => {
      return canAccessFullUser(to, from, next);
    },
  },
  {
    path: "/nft-preview/:blockchain/:id",
    name: "NftPreview",
    component: NftPreview,
    beforeEnter: (to, from, next) => {
      return canAccessFullUser(to, from, next);
    },
  },
  {
    path: "/:proof",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      return canAccessRegister(to, from, next);
    },
  },
];

export const routesSpecs = {
  Home: {
    autoConnect: true,
  },
  MyNiftyz: {
    autoConnect: true,
  },
  CreateNft: {
    autoConnect: true,
  },
  NftPreview: {
    autoConnect: true,
  },
  DraftNft: {
    autoConnect: true,
  },
  SingleNft: {
    autoConnect: false,
  },
  Gallery: {
    autoConnect: false,
  },
  Author: {
    autoConnect: false,
  },
  AuthorNew: {
    autoConnect: false,
  },
  AuthorNotActive: {
    autoConnect: false,
  },
};
