<template>
  <div>
    <Verify v-if="!this.userStore.isConnected" />
    <div class="mt-mob-10" v-if="this.userStore.isConnected">
      <Header />
      <div v-if="this.userStore.isMember">
        <Jumbotron />
      </div>
      <Register />
      <Footer />
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import Jumbotron from "../components/Jumbotron.vue";
import Register from "../components/Register.vue";
import Verify from "../components/Verify.vue";
import { useUserStore } from '../stores/userStore';
export default {
  name: "home",
  data(){
    return{
    }
  },

  components: {
    Header,
    Verify,
    Jumbotron,
    Register,
    Footer,
  },
  computed: {
    ...mapStores(useUserStore),
  },
};
</script>

<style></style>
