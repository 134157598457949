<template>
  <div class="register">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <h3 class="mb-3">Complete your profile</h3>
          <p>Complete the information below to create your Niftyz profile.</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="d-flex box-steps">
            <div class="d-flex flex-column step">
              <i class="fa-solid fa-circle circle-step active"></i>
              <p>Step one</p>
            </div>
            <div class="d-flex flex-column step">
              <i
                :class="this.role.length > 0 ? 'active' : null"
                class="fa-solid fa-circle circle-step"
              ></i>
              <p>Step two</p>
            </div>
            <div class="d-flex flex-column step">
              <i
                :class="
                  (this.role.length > 0 && this.userStore.twitter) ||
                  this.userStore.linkedin
                    ? 'active'
                    : null
                "
                class="fa-solid fa-circle"
              ></i>
              <p>Step three</p>
            </div>
          </div>
        </div>
      </div>
      <!-- STEP 1 -->
      <div class="row mt-4 mb-4 single-step">
        <h4>
          <i
            class="fa-solid"
            :class="
              this.role.length > 0 ? 'fa-circle-check' : 'fa-circle-xmark'
            "
          ></i>
          Step 1 - Select user group
        </h4>
        <div class="account-type">
          <div
            @click="selectRole('investor')"
            :class="this.role == 'investor' ? 'active-box' : null"
            class="box-account"
            style="background: #1b8ee5"
          >
            <i
              :class="
                this.role == 'investor' ? 'd-block check-account' : 'd-none'
              "
              class="fa-solid fa-circle-check"
            ></i>
            <!-- <i @click="info1 = !info1" class="fa-solid fa-circle-info"></i>
            <div :class="!info1 ? 'd-none' : 'd-block'" class="info">
              <p @click="info1 = !info1">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos
                tempore sed animi ipsum facere, vel accusantium velit adipisci
                non? Non, reprehenderit. Quis enim eaque quibusdam. Iure amet
                fugit quos nesciunt!
              </p>
            </div> -->
            <h6 class="mb-0">Investor<br />or<br />Accelerator</h6>
          </div>
          <div
            @click="selectRole('members')"
            :class="this.role == 'members' ? 'active-box' : null"
            class="box-account"
            style="background: #33ba64"
          >
            <i
              :class="
                this.role == 'members' ? 'd-block check-account' : 'd-none'
              "
              class="fa-solid fa-circle-check"
            ></i>
            <!-- <i @click="info2 = !info2" class="fa-solid fa-circle-info"></i>
            <div :class="!info2 ? 'd-none' : 'd-block'" class="info">
              <p @click="info2 = !info2">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos
                tempore sed animi ipsum facere, vel accusantium velit adipisci
                non? Non, reprehenderit. Quis enim eaque quibusdam. Iure amet
                fugit quos nesciunt!
              </p>
            </div> -->
            <h6 class="mb-0">Members Clubs<br />and<br />Associations</h6>
          </div>
          <div
            @click="selectRole('organisers')"
            :class="this.role == 'organisers' ? 'active-box' : null"
            class="box-account"
            style="background: #651bcc"
          >
            <i
              :class="
                this.role == 'organisers' ? 'd-block check-account' : 'd-none'
              "
              class="fa-solid fa-circle-check"
            ></i>
            <h6 class="mb-0">Event Organisers</h6>
          </div>
          <div
            @click="selectRole('agencies')"
            :class="this.role == 'agencies' ? 'active-box' : null"
            class="box-account"
            style="background: #f18819"
          >
            <i
              :class="
                this.role == 'agencies' ? 'd-block check-account' : 'd-none'
              "
              class="fa-solid fa-circle-check"
            ></i>
            <h6 class="mb-0">
              Agencies <br />
              & <br />
              Consultancy Services
            </h6>
          </div>
          <div
            @click="selectRole('startup')"
            :class="this.role == 'startup' ? 'active-box' : null"
            class="box-account"
            style="background: #ee2737"
          >
            <i
              :class="
                this.role == 'startup' ? 'd-block check-account' : 'd-none'
              "
              class="fa-solid fa-circle-check"
            ></i>
            <h6 class="mb-0">
              Start-ups <br />
              or <br />
              Projects
            </h6>
          </div>
        </div>
      </div>
      <!-- /STEP 1 -->
      <!-- <div class="text-end next-btn" v-if="isMobile">
        <button>Next <i class="fa-solid fa-angle-right"></i></button>
      </div> -->
      <div v-if="this.role.length > 0" class="row mt-4 mb-4 single-step">
        <h4>
          <i
            class="fa-solid"
            :class="
              this.userStore.twitter || this.userStore.linkedin
                ? 'fa-circle-check'
                : 'fa-circle-xmark'
            "
          ></i>
          Step 2 - Connect your Twitter or LinkedIn account
        </h4>
        <div
          :class="isMobile ? 'flex-column justify-content-between' : null"
          class="d-flex"
        >
          <div class="connect-account">
            <a
              v-if="!this.userStore?.twitter"
              :href="`${this.api}/oauth/twitter`"
            >
              <i class="fa-brands fa-twitter"></i> Connect Twitter Account
            </a>
            <div v-if="this.userStore?.twitter" class="box-account">
              <p>@{{ this.userStore.twitter }}</p>
              <button @click="disconnectTwitter()" class="me-0 disconnect-btn">
                Disconnect
              </button>
            </div>
          </div>
          <div class="connect-account">
            <a
              v-if="!this.userStore?.linkedin"
              :href="`${this.api}/oauth/linkedin`"
            >
              <i class="fa-brands fa-linkedin"></i> Connect Linkedin Account
            </a>
            <div v-if="this.userStore?.linkedin" class="box-account">
              <p>@{{ this.userStore.linkedin }}</p>
              <button @click="disconnectLinkedin()" class="me-0 disconnect-btn">
                Disconnect
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          (this.role.length > 0 && this.userStore.twitter) ||
          this.userStore.linkedin
        "
      >
        <Form :isRegister="isRegister" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapStores } from "pinia";
import Form from "../components/Form.vue";
import { useUserStore } from "../stores/userStore";
import { useWeb3Store } from "../stores/web3Store";
export default {
  name: "Register",
  data() {
    return {
      isRegister: false,
      isMobile: false,
      role: "",
      info1: false,
      info2: false,
      api: process.env.VUE_APP_API_ENDPOINT,
    };
  },
  components: {
    Form,
  },
  methods: {
    selectRole(newRole) {
      localStorage.setItem("niftyzRole", newRole);
      this.role = newRole;
    },
    async disconnectTwitter() {
      // console.log('hey')
      // const providerOptions = {
      //   walletconnect: {
      //     package: WalletConnectProvider,
      //     options: {
      //       infuraId: process.env.VUE_APP_INFURA_ID,
      //     },
      //   },
      // };
      // Instantiating Web3Modal
      // const web3Modal = new Web3Modal({
      //   cacheProvider: true,
      //   providerOptions: providerOptions,
      // });

      // const provider = await web3Modal.connect();
      // const web3 = await new Web3(provider);
      const toSign = "Twitter disconnect";
      const signature = await this.web3Store.web3.eth.personal.sign(
        toSign,
        this.userStore.address
      );

      const response = await axios.post(
        process.env.VUE_APP_API_ENDPOINT + "/disconnect/twitter",
        {
          address: this.userStore.address,
          message: toSign,
          signature,
        }
      );
      this.userStore.setTwitter(null);
    },
    async disconnectLinkedin() {
      // console.log('hey tere')
      // const providerOptions = {
      //   walletconnect: {
      //     package: WalletConnectProvider,
      //     options: {
      //       infuraId: process.env.VUE_APP_INFURA_ID,
      //     },
      //   },
      // };
      // Instantiating Web3Modal
      // const web3Modal = new Web3Modal({
      //   cacheProvider: true,
      //   providerOptions: providerOptions,
      // });

      // const provider = await web3Modal.connect();
      // const web3 = await new Web3(provider);
      const toSign = "Linkedin disconnect";
      const signature = await this.web3Store.web3.eth.personal.sign(
        toSign,
        this.userStore.address
      );

      const response = await axios.post(
        process.env.VUE_APP_API_ENDPOINT + "/disconnect/linkedin",
        {
          address: this.userStore.address,
          message: toSign,
          signature,
        }
      );
      this.userStore.setLinkedIn(null);
    },
  },
  mounted() {
    const app = this;
    const savedRole = localStorage.getItem("niftyzRole");
    if (savedRole) {
      this.role = savedRole;
    }

    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useWeb3Store),
    checked() {
      return this.userStore?.twitter;
    },
  },
};
</script>

<style></style>
