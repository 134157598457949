<template>
  <div>
    <Header />
    <div class="container myUser">
      <div v-if="myUser" class="row mt-5">
        <div v-if="myUser.profileImage" class="col-12 text-center">
          <div
            class="d-flex align-items-center justify-content-center flex-column"
          >
            <img
              class="img-profile"
              :class="checked ? 'flip-vertical-left-badge' : null"
              v-if="myUser && myUser.profileImage != null && checked"
              :src="'https://ipfs.niftyz.io/ipfs/' + imageToShow"
              alt=""
            />
            <img
              v-if="!checked"
              class="img-profile"
              :class="!checked ? 'flip-vertical-left' : null"
              :src="'https://ipfs.niftyz.io/ipfs/' + imageToShow"
              alt=""
            />
            <i @click="onSwipeClick()" class="fas fa-sync-alt swipe"></i>
            <!-- <i @click="checked = !checked" class="fa-solid fa-arrows-rotate"></i> -->
          </div>
          <h3 v-if="myUser.twitter" class="mt-4 mb-1">{{ myUser.twitter }}</h3>
          <h3 v-if="!myUser.twitter" class="mt-4 mb-1">
            {{ myUser.linkedin }}
          </h3>
        </div>
      </div>
      <div v-if="myUser" class="row">
        <div class="col-10 col-md-8 offset-1 offset-md-2">
          <p class="text-center">{{ myUser.bio }}</p>
        </div>
      </div>
      <div :class="isMobile ? 'm-0' : 'm-5'" class="row">
        <div class="col-6 col-md-3">
          <div style="background: #33ba64" class="detail-user">
            <h4>Account Type</h4>
            <p>{{ myUser.accountType }}</p>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div style="background: #651bcc" class="detail-user">
            <h4>Country</h4>
            <p>{{ myUser.country }}</p>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div style="background: #f18819" class="detail-user">
            <h4>Industry</h4>
            <p>{{ myUser.industry }}</p>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div style="background: #ee2737" class="detail-user">
            <h4>Job Title</h4>
            <p>{{ myUser.jobTitle }}</p>
          </div>
        </div>
      </div>
      <div class="container-nft">
        <div class="row">
          <div class="col-12 pt-3">
            <h4>NFTs</h4>
          </div>
        </div>
        <div v-if="myNfts.length >= 1" class="row">
          <div
            v-for="(nft, index) in myNfts"
            :key="index"
            class="col-6 col-md-3"
          >
            <a
              v-if="nft"
              :href="
                '/#/single-nft/' +
                nft.blockchain +
                '/' +
                nft.smartContract +
                '/' +
                nft.slug
              "
              class="single-nft"
            >
              <div class="single-nft">
                <img
                  :src="
                    'https://ipfs.niftyz.io/ipfs/' +
                    nft.metadata.image.substring(7)
                  "
                  alt=""
                />
                <h6>{{ nft.metadata.name }}</h6>
              </div>
            </a>
          </div>
        </div>
        <div v-if="myNfts.length == 0">
          <div
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              mt-4
            "
          >
            <h3>No Tokens</h3>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
export default {
  name: "author",
  data() {
    return {
      api: process.env.VUE_APP_API_ENDPOINT,
      isMobile: false,
      myUser: [],
      idNft: this.$route.params.id,
      walletAddress: "",
      myNfts: [],
      checked: false,
      imageToShow: "",
    };
  },
  components: {
    Header,
    Footer,
  },
  async mounted() {
    const app = this;
    await app.getAuthor();
    await app.getNft();
    if (window.innedrWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
  methods: {
    async getAuthor() {
      const app = this;
      try {
        const buf = Buffer.from(app.idNft);
        const b64 = buf.toString("base64");
        const response = await axios.get(app.api + "/user-by-id/" + b64);
        const myUsers = response.data.user;
        app.myUser = myUsers;
        app.imageToShow = app.myUser.profileImage.substring(7);
        app.walletAddress = myUsers.walletAddress;
        console.log("test", app.walletAddress);

        /* myUsers.forEach((nft) => {
        }); */
        /*  console.log("single", this.myUser); */
      } catch (err) {
        console.log(err);
      }
    },
    async getNft() {
      const app = this;
      if (app.walletAddress != "") {
        console.log("start");
        try {
          const response = await axios.get(
            app.api + "/nfts/creator-public-for-sale/" + app.walletAddress
          );
          const myNfts = response.data.nfts;
          console.log("=============>>>>>>>>>", myNfts);
          app.myNfts = myNfts;
        } catch (err) {
          console.log(err);
        }
      }
    },
    onSwipeClick() {
      const app = this;
      app.checked = !app.checked;
      setTimeout(() => {
        app.imageToShow = app.checked
          ? app.myUser.badgeImage.substring(7)
          : app.myUser.profileImage.substring(7);
      }, 250);
    },
  },
};
</script>

<style lang="scss" scoped>
/* .fa-arrows-rotate {
  display: inline-block;
  margin: 1rem auto;
  font-size: 1.7rem;
  opacity: 0.6;
  &:hover{
    cursor: pointer;
  opacity: 0.9;
    -webkit-animation: spin 1.5s linear  alternate infinite;
    -moz-animation: spin 1.5s linear  alternate infinite;
    animation: spin 1.5s linear  alternate infinite;
    @-moz-keyframes spin {
      50% {
        -moz-transform: rotate(180deg);
      }
      100% {
        -moz-transform: rotate(0deg);
      }
    }
    @-webkit-keyframes spin {
      50% {
        -webkit-transform: rotate(180deg);
      }
      100% {
        -webkit-transform: rotate(0deg);
      }
    }
    @keyframes spin {
      50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
      100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
    }

  }
} */
</style>
