var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center top-footer"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"d-flex align-items-center",class:_vm.isMobile ? 'justify-content-center me-0 mb-2' : 'me-3'},[_c('img',{staticClass:"me-2",staticStyle:{"width":"140px"},attrs:{"src":require("../assets/logo-text.svg"),"alt":""}})])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('ul',{staticClass:"d-flex m-0 social-footer",class:_vm.isMobile
              ? 'justify-content-center mt-3 mb-2 p-0'
              : 'justify-content-end'},[_vm._m(0),_vm._m(1),_vm._m(2)])])]),_vm._m(3)]),_c('div',{staticClass:"arrow-up",on:{"click":function($event){return _vm.scrollUp()}}},[_c('i',{staticClass:"fa-solid fa-arrow-up"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"me-4"},[_c('a',{attrs:{"href":"https://twitter.com/niftyz_io","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/social/twitter.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"me-4"},[_c('a',{attrs:{"href":" https://www.linkedin.com/company/niftyz/","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/social/linkedin.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"me-4"},[_c('a',{attrs:{"href":"https://discord.gg/ZcCxqjwaWZ","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/social/discord.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row bottom-footer"},[_c('div',{staticClass:"col-8 col-md-6"},[_c('p',[_vm._v("© 2022 NIFTYZ. All right reserved.")])]),_c('div',{staticClass:"col-4 col-md-6 text-end link-footer"},[_c('a',{attrs:{"href":"Terms_of_Service.pdf","target":"_blank"}},[_vm._v("Terms of Service")]),_c('a',{attrs:{"href":"https://niftyz.io/Privacy_Policy.pdf","target":"_blank"}},[_vm._v("Privacy Policy")])])])
}]

export { render, staticRenderFns }