var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('div',{staticClass:"container myUser"},[(_vm.myUser)?_c('div',{staticClass:"row mt-5"},[(_vm.myUser.profileImage)?_c('div',{staticClass:"col-12 text-center"},[_c('img',{staticClass:"img-profile",attrs:{"src":'https://ipfs.niftyz.io/ipfs/' + _vm.myUser.profileImage.substring(7),"alt":""}}),_c('h3',{staticClass:"mt-4 mb-1"},[_vm._v(_vm._s(_vm.myUser.twitter))])]):_vm._e()]):_vm._e(),(_vm.myUser)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 col-md-8 offset-1 offset-md-2"},[_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.myUser.bio))])])]):_vm._e(),_c('div',{staticClass:"row m-5"},[_c('div',{staticClass:"col-6 col-md-3"},[_c('div',{staticClass:"detail-user",staticStyle:{"background":"#33ba64"}},[_c('h4',[_vm._v("Account Type")]),_c('p',[_vm._v(_vm._s(_vm.myUser.accountType))])])]),_c('div',{staticClass:"col-6 col-md-3"},[_c('div',{staticClass:"detail-user",staticStyle:{"background":"#651bcc"}},[_c('h4',[_vm._v("Country")]),_c('p',[_vm._v(_vm._s(_vm.myUser.country))])])]),_c('div',{staticClass:"col-6 col-md-3"},[_c('div',{staticClass:"detail-user",staticStyle:{"background":"#f18819"}},[_c('h4',[_vm._v("Industry")]),_c('p',[_vm._v(_vm._s(_vm.myUser.industry))])])]),_c('div',{staticClass:"col-6 col-md-3"},[_c('div',{staticClass:"detail-user",staticStyle:{"background":"#ee2737"}},[_c('h4',[_vm._v("Job Title")]),_c('p',[_vm._v(_vm._s(_vm.myUser.jobTitle))])])])]),_c('div',{staticClass:"container-nft"},[_vm._m(0),(_vm.myNfts.length >= 1)?_c('div',{staticClass:"row"},_vm._l((_vm.myNfts),function(nft,index){return _c('div',{key:index,staticClass:"col-6 col-md-3"},[(nft)?_c('a',{staticClass:"single-nft",attrs:{"href":'/#/single-nft/' +
              nft.blockchain +
              '/' +
              nft.smartContract +
              '/' +
              nft.encodedSlug}},[_c('div',{staticClass:"single-nft"},[_c('img',{attrs:{"src":'https://ipfs.niftyz.io/ipfs/' +
                  nft.metadata.image.substring(7),"alt":""}}),_c('h6',[_vm._v(_vm._s(nft.metadata.name))])])]):_vm._e()])}),0):_vm._e(),(_vm.myNfts.length == 0)?_c('div',[_vm._m(1)]):_vm._e()])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-3"},[_c('h4',[_vm._v("NFTs")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center mt-4"},[_c('h3',[_vm._v("No Tokens")])])
}]

export { render, staticRenderFns }