<template>
  <div class="box-down">
      <div class="bk-jb"></div>
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h2>Get inspired and create your NFT!</h2>
          </div>
          <div class="col-12 mt-4 text-center">
            <a style="position:relative; z-index:999" class="btn-connect" href="/#/create-nft">Create your NFT</a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name:"BoxDown"
}
</script>

<style>

</style>